import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Store } from "@ngxs/store";
import { TranslocoService } from "@ngneat/transloco";
import { NotificationState } from "../../_stores/notification/_state/notification.state";

@Component({
  selector: 'ui-notification',
  templateUrl: './ui-notification.component.html',
  styleUrls: ['./ui-notification.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiNotificationComponent implements OnInit {
  interface: {
    status: boolean,
    type: string,
  } = {
    status: false,
    type: undefined,
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private transloco: TranslocoService) {}

  ngOnInit(): void {
    this.initNotificationStatus();
  }

  initNotificationStatus(): void {
    this.store.select(NotificationState.selectStatus).subscribe((res: any) => {
      if(!res) {
        return;
      }
      this.interface.status = res.status;
      this.interface.type = res.type;
      if (this.interface.status) {
        //document.body.classList.add('ui-modal-open');
      } else {
        //document.body.classList.remove('ui-modal-open');
      }
      this.cdr.detectChanges();
    });
  }

}
