import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from "@ngxs/store";
import {TranslocoService} from "@ngneat/transloco";
import {ConnectWebSocket} from "@ngxs/websocket-plugin";
import {AuthState} from "./_stores/auth/_state/auth.state";
import { NavigationEnd, Router } from "@angular/router";



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {

  constructor(private cdr: ChangeDetectorRef, private store: Store, private router: Router, private transloco: TranslocoService) {}

  ngOnInit(): void {
    this.initWebSocket();
    this.watchAuth();
    this.scrollBehavior();
  }

  initWebSocket(): void {
    this.store.dispatch(new ConnectWebSocket());
  }

  watchAuth(): void {
    this.store.select(AuthState.selectAuth).subscribe((_auth) => {
      if (_auth.status) {
        //this.router.navigate(['/']).then();
      }
      if (!_auth.status) {
        //this.router.navigate(['/auth']).then();
      }
    });
  }


  scrollBehavior(): void {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        document.body.scrollTop = 0;
      }
    });
  }
}
