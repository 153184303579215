import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from "@ngxs/store";
import {LoadVideos} from "../../_stores/practice/_actions/practice.actions";
import {RouterState} from "@ngxs/router-plugin";
import {PageVideosModel} from "../../_models/page-video.model";
import {PracticeState} from "../../_stores/practice/_state/practice.state";

@Component({
  selector: 'ui-page-video',
  templateUrl: './ui-page-video.component.html',
  styleUrls: ['./ui-page-video.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiPageVideoComponent implements OnInit {

  interface: PageVideosModel = {
    videos: [],
    _cache: {
      active_video: 0,
      player: null,
    }
  }


  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
   // this.loadCategoryVideos();
    this.initVideos();
  }

  // loadCategoryVideos(): void {
  //   this.store.select(RouterState.state).subscribe((_state) => {
  //     // @ts-ignore
  //     this.store.dispatch(new LoadVideos(_state.params.id));
  //   }).unsubscribe();
  // }

  initVideos(): void {
    this.store.select(PracticeState.selectVideos).subscribe((_videos) => {
      this.interface.videos = _videos;
      this.cdr.detectChanges();
    });
  }




}
