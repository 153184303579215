import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PageQuestionsModel} from '../../_models/page-questions.model';
import { Store } from '@ngxs/store';
import { RouterSelectors } from "../../_stores/routing/router.selectors";


@Component({
  selector: 'ui-page-questions',
  templateUrl: './ui-page-questions.component.html',
  styleUrls: ['./ui-page-questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiPageQuestionsComponent implements OnInit {
  interface: PageQuestionsModel = {
    _id: null
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initQuestionsId();
  }
  initQuestionsId(): void {
    this.store.select(RouterSelectors.params).subscribe((_params) => {
      this.interface._id = _params.id;
    });
  }
}
