import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {AuthStatus} from "../_stores/auth/_actions/auth.actions";
import {AuthState} from "../_stores/auth/_state/auth.state";

@Injectable({
  providedIn: 'root',
})
export class ActivatePrivateGuard implements CanActivate {

  constructor(private store: Store, private router: Router) {}


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.store.dispatch(new AuthStatus());
    const authed = this.store.selectSnapshot(AuthState.selectAuthStatus);
    return authed;
  }

}
