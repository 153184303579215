import { ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { ControlState } from '../../_stores/control/_state/control.state';
import { ModalOpenAction, ModalOpenActionWithID } from '../../_stores/modal/_actions/modal.actions';
import { LoadControlVideos } from '../../_stores/control/_actions/control.actions';

@Component({
  selector: 'ui-control-videos',
  templateUrl: './ui-control-videos.component.html',
  styleUrls: ['./ui-control-videos.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiControlVideosComponent implements OnInit, OnChanges {
  interface: any = {
    pages: [],
    currentPage: 0,
    data: [],
    perPage: 0,
    totalData: 0,
    totalPage: 0,
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {
    this.store.dispatch(new LoadControlVideos(1));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes && changes.currentPage.currentValue) || (changes.total && changes.total.currentValue)) {
      this.interface = {
        ...this.interface,
        pages: this.getPages(this.interface.currentPage, this.interface.totalPage),
      };
      this.initData();
    }
  }

  ngOnInit(): void {
    this.initData();
  }

  initData(): void {
    this.store.select(ControlState.selectVideos).subscribe((_data) => {
      this.interface = _data;
      this.interface = {
        ...this.interface,
        pages: this.getPages(this.interface.currentPage, this.interface.totalPage),
      };
      this.cdr.detectChanges();
    });
  }

  actionOpenAddDataModal(): void {
    this.store.dispatch(new ModalOpenAction(''));
  }

  actionOpenEditDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('', _id));
  }

  actionOpenDeleteDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('', _id));
  }

  actionSearchVideos(event) {
    if (event.target.value && event.target.value.length > 0) {
      this.interface.data = this.interface.data.filter((item) => {
        return item.title.toLowerCase().includes(event.target.value.toLowerCase());
      });
    } else {
      this.initData();
    }
  }
  actionFirstPage(): void {
    this.store.dispatch(new LoadControlVideos(1));
  }
  actionPrevPage(): void {
    this.store.dispatch(new LoadControlVideos(this.interface.currentPage - 1));
  }
  actionLoadPage(page): void {
    this.store.dispatch(new LoadControlVideos(page));
  }
  actionNextPage(event): void {
    let element = document.querySelector('thead');
    element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    this.interface = {
      ...this.interface,
      currentPage: event,
    };
    this.interface.currentPage = event;
    this.store.dispatch(new LoadControlVideos(this.interface.currentPage));
    this.cdr.detectChanges();
  }
  actionLastPage(): void {
    this.store.dispatch(new LoadControlVideos(this.interface.totalPage));
  }
  private getPages(currentPage: number, totalPage: number) {
    if (currentPage <= 10) {
      if (totalPage < 10) {
        return [...Array(totalPage).keys()].map((x) => ++x);
      }
      return [...Array(10).keys()].map((x) => ++x);
    }
    if (currentPage > 10) {
      if (currentPage >= totalPage - 2) {
        return [totalPage - 3, totalPage - 2, totalPage - 1, totalPage];
      } else {
        return [currentPage - 1, currentPage, currentPage + 1];
      }
    }
  }
}
