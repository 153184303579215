import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from "@angular/common";
import { UiBookComponent } from "./ui-book.component";
import { UiTooltipModule } from "../ui-tooltip/ui-tooltip.module";
import { UiEditorModule } from "../ui-editor/ui-editor.module";
import { UiAppNoDataModule } from "../ui-app-no-data/ui-app-no-data.module";



@NgModule({
  declarations: [UiBookComponent],
  exports: [UiBookComponent],
  imports: [
    CommonModule,
    UiTooltipModule,
    UiEditorModule,
    UiAppNoDataModule
  ],
  providers: [DatePipe],
})
export class UiBookModule { }
