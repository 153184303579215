import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Store } from "@ngxs/store";
import { HttpClient } from "@angular/common/http";
import { API } from "../../_stores/api";
import { ModalCloseAction } from "../../_stores/modal/_actions/modal.actions";
import { LoadControlSchoolsLicense } from "../../_stores/control/_actions/control.actions";

@Component({
  selector: 'ui-control-license-add',
  templateUrl: './ui-control-license-add.component.html',
  styleUrls: ['./ui-control-license-add.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiControlLicenseAddComponent implements OnInit {
  interface: any = {
    loading: false,
    license: {
      active: false,
      maxCountUser: 0,
      school: null,
      whiteList: [],
      startDate: null,
      endDate: null
    },
    schools: [],
    _cache: {
      open_search_school: false,
      selected_school: '',
      error_fields: [],
    },
  }
  constructor(private store: Store, private cdr: ChangeDetectorRef, private http: HttpClient) { }

  ngOnInit(): void {}
  actionToggleSearchSchool(): void {
    this.interface._cache.open_search_school = !this.interface._cache.open_search_school;
  }
  actionSearchSchool(event?): void {
    const _search = event? event.target.value: '';
    this.http.get<any[]>(API.url('schools/search'),  { params: { search: _search } }).subscribe({
      next: (_next) => {
        this.interface.schools = _next;
        this.cdr.detectChanges();
      },
    });
  }

  actionSelectStartDate(event): void {
    this.interface.license.startDate = event;
  }
  actionSelectEndDate(event): void {
    this.interface.license.endDate = event;
  }
  actionSelectSchool(school): void {
    this.interface._cache.open_search_school = false;
    this.interface._cache.selected_school = school.schoolName;
    this.interface.license.school = school;
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'school');
    this.cdr.detectChanges();
  }
  actionToggleActive():void {
    this.interface.license.active = !this.interface.license.active;
  }
  actionEnterMaxUsers(event): void {
    this.interface.license.maxCountUser = event.target.value;
  }
  actionEnterWhiteList(event): void {
    this.interface.license.whiteList = event.target.value.split(/\n/);
  }


  actionConfirm(): void {
    this.interface.loading = true;
    const _body = this.interface.license;
    this.http.post(API.url('licenses'), _body).subscribe({
      next: (value) => {
        this.interface.loading = false;
        this.store.dispatch(new LoadControlSchoolsLicense());
        this.store.dispatch(new ModalCloseAction());
        this.cdr.detectChanges();
      },
      error: (err) => {
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
    });

  }
  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }

}
