import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { PageModel } from '../../_models/page.model';
import { Store } from '@ngxs/store';
import { RouterState } from '@ngxs/router-plugin';
import { AuthState } from '../../_stores/auth/_state/auth.state';
import { Router } from '@angular/router';
import {AuthLogout} from "../../_stores/auth/_actions/auth.actions";
import {ModalState} from "../../_stores/modal/_state/modal.state";
import {UserState} from "../../_stores/user/_state/user.state";

@Component({
  selector: 'ui-page',
  templateUrl: './ui-page.component.html',
  styleUrls: ['./ui-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiPageComponent implements OnInit {
  interface: PageModel = {
    error: false,
    loading: false,
    update: false,
    modal_no_close: false,
    user: undefined
  };
  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.store.select(RouterState.url).subscribe((_url) => {
      if (_url == '/') {
        this.interface.loading = true;
        this.initUser();
      }
    });
    this.initModalStatus();
  }

  initModalStatus(): void {
    this.store.select(ModalState.selectStatus).subscribe((res: any) => {
      this.interface.modal_no_close = !res.close;
      this.cdr.detectChanges();
    });
  }

  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.cdr.detectChanges();
    });
  }

  childContentLoad(event): void {
    this.interface.loading = false;
  }
  childContentUnLoad(event): void {
    this.interface.loading = true;
  }



}
