import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiHomeworkComponent } from "./ui-homework.component";
import { UiTooltipModule } from "../ui-tooltip/ui-tooltip.module";
import { PipesModule } from "../../app.pipes.module";



@NgModule({
  declarations: [UiHomeworkComponent],
  exports: [UiHomeworkComponent],
  imports: [
    CommonModule,
    UiTooltipModule,
    PipesModule
  ]
})
export class UiHomeworkModule { }
