import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiPagePracticesComponent} from "./ui-page-practices.component";
import {RouterModule} from "@angular/router";
import {PipesModule} from "../../app.pipes.module";
import {UiGradesModule} from "../ui-grades/ui-grades.module";


@NgModule({
  declarations: [UiPagePracticesComponent],
  exports: [UiPagePracticesComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: UiPagePracticesComponent,
      }
    ]),
    PipesModule,
    UiGradesModule,
  ]
})
export class UiPagePracticesModule {
}
