import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiPageSidebarComponent} from "./ui-page-sidebar.component";
import {AppTranslocoModule} from "../../app.transloco.module";
import {UiTooltipModule} from "../ui-tooltip/ui-tooltip.module";
import {RouterModule} from "@angular/router";
import {PipesModule} from "../../app.pipes.module";



@NgModule({
  declarations: [UiPageSidebarComponent],
  exports: [UiPageSidebarComponent],
    imports: [
        CommonModule,
        AppTranslocoModule,
        UiTooltipModule,
        RouterModule,
        PipesModule
    ]
})
export class UiPageSidebarModule { }
