import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiControlUserEditComponent} from "./ui-control-user-edit.component";
import { UiTooltipModule } from "../ui-tooltip/ui-tooltip.module";
import { PipesModule } from "../../app.pipes.module";
import { AppTranslocoModule } from "../../app.transloco.module";



@NgModule({
  declarations: [UiControlUserEditComponent],
  exports: [UiControlUserEditComponent],
  imports: [
    CommonModule,
    UiTooltipModule,
    PipesModule,
    AppTranslocoModule
  ]
})
export class UiControlUserEditModule { }
