import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiPageQuestionsResultComponent} from "./ui-page-questions-result.component";
import {RouterModule} from "@angular/router";
import {UiAppNoDataModule} from "../ui-app-no-data/ui-app-no-data.module";
import { UiTooltipModule } from "../ui-tooltip/ui-tooltip.module";
import { KatexModule } from "ng-katex";
import { PipesModule } from "../../app.pipes.module";
import { UiBooksModule } from "../ui-books/ui-books.module";
import { UiQuizResultModule } from "../ui-quiz-result/ui-quiz-result.module";



@NgModule({
  declarations: [UiPageQuestionsResultComponent],
  exports: [UiPageQuestionsResultComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: UiPageQuestionsResultComponent,
        children: []
      }
    ]),
    UiAppNoDataModule,
    UiTooltipModule,
    KatexModule,
    PipesModule,
    UiBooksModule,
    UiQuizResultModule
  ]
})
export class UiPageQuestionsResultModule { }
