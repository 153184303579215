import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { CreateControlRole, LoadControlRoles } from '../../_stores/control/_actions/control.actions';
import { ModalCloseAction } from '../../_stores/modal/_actions/modal.actions';

@Component({
  selector: 'ui-control-role-add',
  templateUrl: './ui-control-role-add.component.html',
  styleUrls: ['./ui-control-role-add.component.scss'],
})
export class UiControlRoleAddComponent implements OnInit {
  interface: any = {
    role: { name: '' },
    _cache: {
      error_fields: [],
    },
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {
    this.store.dispatch(new LoadControlRoles());
  }

  ngOnInit(): void {}

  actionEnterRoleName(event) {
    this.interface.role.name = event.target.value;
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'name');
  }

  actionAddRole(): void {
    this.interface.loading = true;
    this.interface._cache.error_fields = [];
    Object.entries(this.interface.role).forEach(([field, data]) => {
      if (!data) {
        this.interface._cache.error_fields.push(field);
      }
    });
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'name');
    if (this.interface._cache.error_fields.length > 0) {
      return;
    }
    const _body = {
      name: this.interface.role.name,
    };
    this.store.dispatch(new CreateControlRole({ data: _body })).subscribe({
      next: () => {
        this.interface.loading = false;
        window.location.reload();
        this.store.dispatch(new ModalCloseAction());
        this.cdr.detectChanges();
      },
      error: () => {
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
    });
  }

  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }
}
