import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiAssignmentComponent } from "./ui-assignment.component";
import { UiTooltipModule } from "../ui-tooltip/ui-tooltip.module";
import { PipesModule } from "../../app.pipes.module";
import { RouterModule } from "@angular/router";



@NgModule({
  declarations: [UiAssignmentComponent],
  exports: [UiAssignmentComponent],
  imports: [
    CommonModule,
    UiTooltipModule,
    PipesModule,
    RouterModule
  ]
})
export class UiAssignmentModule { }
