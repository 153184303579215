import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiPagePracticesTreeComponent} from "./ui-page-practices-tree.component";
import {RouterModule} from "@angular/router";
import {UiGradesModule} from "../ui-grades/ui-grades.module";
import {UiCategoriesTreeModule} from "../ui-categories-tree/ui-categories-tree.module";



@NgModule({
  declarations: [UiPagePracticesTreeComponent],
  exports: [UiPagePracticesTreeComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: UiPagePracticesTreeComponent,
        children: [],
      }
    ]),
    UiGradesModule,
    UiCategoriesTreeModule,
  ]
})
export class UiPagePracticesTreeModule { }
