import { ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { ControlState } from '../../_stores/control/_state/control.state';
import { ModalOpenAction, ModalOpenActionWithID } from '../../_stores/modal/_actions/modal.actions';
import { PracticeState } from '../../_stores/practice/_state/practice.state';
import { GradeModel } from '../../_models/grade.model';
import { WorksheetModel } from '../../_models/worksheet.model';
import { LoadControlWorksheets } from '../../_stores/control/_actions/control.actions';

export interface UiControlWorksheets {
  search: string;
  grade: string;
  pages: number[];
  currentPage: number;
  data: WorksheetModel[];
  perPage: number;
  totalData: number;
  totalPage: number;
  grades: GradeModel[];
  series: string[];
  worksheets: WorksheetModel[];
  _cache: {
    worksheets: WorksheetModel[];
    searched: boolean;
    open_choose_grade: boolean;
    open_choose_series: boolean;
    choose_grade: GradeModel;
    choose_series: string;
  };
}

@Component({
  selector: 'ui-control-worksheets',
  templateUrl: './ui-control-worksheets.component.html',
  styleUrls: ['./ui-control-worksheets.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiControlWorksheetsComponent implements OnInit, OnChanges {
  interface: UiControlWorksheets = {
    grade: '',
    search: '',
    pages: [],
    currentPage: 0,
    data: [],
    perPage: 0,
    totalData: 0,
    totalPage: 0,
    series: [],
    grades: [],
    worksheets: [],
    _cache: {
      worksheets: [],
      searched: false,
      open_choose_grade: false,
      open_choose_series: false,
      choose_grade: undefined,
      choose_series: undefined,
    },
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {
    this.store.dispatch(new LoadControlWorksheets(undefined, 1, undefined));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes && changes.currentPage.currentValue) || (changes.total && changes.total.currentValue)) {
      this.initData();
    }
  }

  ngOnInit(): void {
    this.initData();
  }

  initData(): void {
    this.store.select(ControlState.selectWorksheets).subscribe((_data) => {
      let filter = _data.data.filter((value) => value.product != undefined && value.product.grade.length > 0);
      this.interface.worksheets = filter;
      this.interface.perPage = _data.perPage;
      this.interface.currentPage = _data.currentPage;
      this.interface.totalPage = _data.totalPage;
      this.interface.totalData = _data.totalData;
      this.interface._cache.worksheets = filter;
      this.interface.pages = this.getPages(this.interface?.currentPage, this.interface.totalPage);
      this.initGrades();
      this.cdr.detectChanges();
    });
  }

  actionOpenAddDataModal(): void {
    this.store.dispatch(new ModalOpenAction(''));
  }

  actionOpenEditDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('', _id));
  }

  actionOpenDeleteDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('', _id));
  }

  actionSearchWorksheets(event) {
    if (event.target.value && event.target.value.length > 0) {
      this.interface = { ...this.interface, search: event.target.value };
      this.store.dispatch(
        new LoadControlWorksheets(this.interface?.search, 1, this.interface?._cache?.choose_grade?._id),
      );
      this.cdr.detectChanges();
    } else {
      this.interface = { ...this.interface, search: undefined };
      this.store.dispatch(
        new LoadControlWorksheets(this.interface?.search, 1, this.interface?._cache?.choose_grade?._id),
      );
      this.cdr.detectChanges();
    }
  }

  actionToggleChooseGrade(): void {
    this.interface._cache.open_choose_grade = !this.interface._cache.open_choose_grade;
  }

  actionSearchBooksByGrade(grade): void {
    this.interface._cache.open_choose_grade = false;
    this.interface._cache.choose_grade = grade;
    this.store.dispatch(
      new LoadControlWorksheets(this.interface?.search, 1, this.interface?._cache?.choose_grade?._id),
    );
  }

  actionClearChooseGrade(): void {
    this.interface._cache.choose_grade = undefined;
    this.interface.worksheets = this.interface._cache.worksheets;
    this.store.dispatch(new LoadControlWorksheets(this.interface?.search, this.interface?.currentPage, undefined));
  }

  actionFirstPage(): void {
    this.store.dispatch(
      new LoadControlWorksheets(this.interface?.search, 1, this.interface?._cache?.choose_grade?._id),
    );
  }

  actionPrevPage(): void {
    this.store.dispatch(
      new LoadControlWorksheets(
        this.interface?.search,
        this.interface?.currentPage - 1,
        this.interface?._cache?.choose_grade?._id,
      ),
    );
  }

  actionLoadPage(page): void {
    this.store.dispatch(
      new LoadControlWorksheets(this.interface?.search, Number(page), this.interface?._cache?.choose_grade?._id),
    );
    this.initData();
  }

  actionNextPage(event): void {
    let element = document.querySelector('thead');
    element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    this.interface = {
      ...this.interface,
      currentPage: event,
    };
    this.interface.currentPage = event;
    this.store.dispatch(
      new LoadControlWorksheets(
        this.interface?.search,
        this.interface.currentPage,
        this.interface?._cache?.choose_grade?._id,
      ),
    );
    this.cdr.detectChanges();
  }

  actionLastPage(): void {
    this.store.dispatch(
      new LoadControlWorksheets(
        this.interface?.search,
        this.interface?.totalPage,
        this.interface?._cache?.choose_grade?._id,
      ),
    );
  }

  private initGrades() {
    this.store.select(PracticeState.selectGrades).subscribe((_grades) => {
      this.interface.grades = _grades;
      this.cdr.detectChanges();
    });
  }

  private getPages(currentPage: number, totalPage: number) {
    if (currentPage <= 10) {
      if (totalPage < 10) {
        return [...Array(totalPage).keys()].map((x) => ++x);
      }
      return [...Array(10).keys()].map((x) => ++x);
    }
    if (currentPage > 10) {
      if (currentPage >= totalPage - 2) {
        return [totalPage - 3, totalPage - 2, totalPage - 1, totalPage];
      } else {
        return [currentPage - 1, currentPage, currentPage + 1];
      }
    }
  }

  getGrade(grade_name: string) {
    return this.interface.grades.filter((value) => grade_name.includes(value.title))[0].title
      ? this.interface.grades.filter((value) => grade_name.includes(value.title))[0].title
      : '';
  }
}
