import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiPageParentsComponent } from "./ui-page-parents.component";
import { RouterModule } from "@angular/router";
import { UiTooltipModule } from "../ui-tooltip/ui-tooltip.module";
import { UiAppNoDataModule } from "../ui-app-no-data/ui-app-no-data.module";
import { PipesModule } from "../../app.pipes.module";



@NgModule({
  declarations: [UiPageParentsComponent],
  exports: [UiPageParentsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: UiPageParentsComponent,
        children: [
          {
            path: "**",
            component: UiPageParentsComponent
          }
        ]
      }
    ]),
    UiTooltipModule,
    UiAppNoDataModule,
    PipesModule
  ]
})
export class UiPageParentsModule { }
