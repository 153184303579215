import {
  Action,
  createSelector, NgxsAfterBootstrap, NgxsOnChanges, NgxsOnInit, NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ClassesService } from '../_services/classes.service';
import {
  ClearClasses,
  CreateClass,
  DeleteClass,
  GetClass,
  GetClassList,
  SetClassList,
  UpdateClass
} from "../_actions/classes.actions";
import { ClassModel } from '../../../_models/class.model';
import { GradeModel } from '../../../_models/grade.model';
import { ClearAssignments, GetAssignmentList } from "../../asignments/_actions/asignments.actions";
import {PracticeStateModel} from "../../practice/_state/practice.state";
import { EventClassCreated } from "../../event_ws/_actions/events.actions";
import { RouterStateModel as RouterStateOuterModel } from "@ngxs/router-plugin/src/router.state";
import { RouterStateModel } from "../../../_models/router-state.model";
import { RouterState } from "@ngxs/router-plugin";
import { ModalState } from "../../modal/_state/modal.state";
import { ModalModel } from "../../../_models/modal.model";
import { UserState } from "../../user/_state/user.state";
import { GetTeachersStudents } from "../../user/_actions/user.actions";
import { first } from "rxjs";
import { AssignmentsStateModel } from "../../asignments/_state/assignments.state";

export interface ClassesStateModel {
  isLoading: boolean;
  lastCreatedClass: ClassModel | null;
  classList: ClassModel[];
  gradeList: GradeModel[];
  chosenGradePracticeList: any[];
}

export const clearClasses = {
  isLoading: true,
  lastCreatedClass: null,
  classList: [],
  gradeList: [],
  chosenGradePracticeList: [],
};

@State<ClassesStateModel>({
  name: 'LAP_CLASSES',
  defaults: clearClasses,
})
@Injectable()
export class ClassesState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {
  constructor(private store: Store, private classesService: ClassesService) {}

  ngxsAfterBootstrap(ctx?: StateContext<PracticeStateModel>): void {
    this.store.select(UserState.selectUser).pipe(
      first(user => !!user)
    ).subscribe(
      user => {
        if (user.role.name.toLowerCase() === 'teacher'){
           ctx.dispatch(new GetClassList());
           ctx.dispatch(new GetTeachersStudents());
        }
      }
    )
  }

  ngxsOnInit(ctx?: StateContext<PracticeStateModel>): void {}

  ngxsOnChanges(change: NgxsSimpleChange<PracticeStateModel>): void {}



  @Selector()
  public static selectClassesLoading(state: ClassesStateModel) {
    return state.isLoading;
  }

  @Selector()
  public static selectClassesState(state: ClassesStateModel) {
    return state;
  }

  @Selector()
  public static selectClassList(state: ClassesStateModel) {
    return state.classList;
  }

  @Selector([RouterState])
  public static selectClassByRouteId(state: ClassesStateModel, route: RouterStateOuterModel<RouterStateModel>) {
    return state.classList.find((_class)=> _class._id == route.state.params.class_id);
  }



  // public static selectClassListByTeacherId(teacherId: string) {
  //   return createSelector(
  //     [ClassesState.selectClassList],
  //     (allClasses: ClassModel[]) => {
  //       return allClasses
  //         ? allClasses.find((p) => p.teacher._id === teacherId)
  //         : [];
  //     },
  //   );
  // }
  @Selector()
  public static selectLastCreatedClass(state: ClassesStateModel) {
    return state.lastCreatedClass;
  }

  @Selector()
  public static selectGradeList(state: ClassesStateModel) {
    return state.gradeList;
  }

  @Selector()
  public static selectChosenGradePracticeList(state: ClassesStateModel) {
    return state.chosenGradePracticeList;
  }

  @Selector()
  static selectClassByID(state: ClassesStateModel) {
    return (classID: string) =>
      state.classList.filter((c) => c._id === classID);
  }

  @Selector([ModalState])
  static selectClassByModalID(state: ClassesStateModel, modal: ModalModel) {
    return state.classList.find((_class) => _class._id === modal._id);
  }

  public static selectClassById(id: string) {
    return createSelector(
      [ClassesState.selectClassList],
      (allClasses: ClassModel[]) => {
        return allClasses ? allClasses.find((p) => p._id === id) : null;
      },
    );
  }

  public static selectStudentsByClassId(id: string) {
    return createSelector(
      [ClassesState.selectClassList],
      (allClasses: ClassModel[]) => {
        const classModel = allClasses.find((p) => p._id === id);
        return classModel ? classModel.students : [];
      },
    );
  }

  @Action(SetClassList)
  setGradeList(
    ctx: StateContext<ClassesStateModel>,
    { payload }: SetClassList,
  ) {
    ctx.patchState({ classList: payload.classItems, isLoading: false });
  }

  @Action(GetClassList)
  getClassesList(ctx: StateContext<ClassesStateModel>) {
    return this.classesService.getAllClasses().subscribe({
      next: (res) => {
        ctx.dispatch(new SetClassList({ classItems: res }));
      },
      error: (err) => {

      },
      complete: () => {},
    });
  }

  @Action(GetClass)
  getClass(
    { getState, setState }: StateContext<ClassesStateModel>,
    { payload }: GetClass,
  ) {
    return this.classesService.getClass(payload.id).subscribe({
      next: () => {
        const state = getState();
        const filtered = state.classList.filter(
          (value) => value._id === payload.id,
        );
        setState({
          ...state,
          classList: filtered,
        });
      },
      error: (err) => {

      },
      complete: () => {},
    });
  }

  @Action(DeleteClass)
  deleteClass(ctx: StateContext<ClassesStateModel>, { payload }: DeleteClass) {
    return this.classesService.deleteClass(payload.deletedClassId).subscribe({
      next: (res) => {
        ctx.dispatch(new GetClassList());
      },
      error: (err) => {

      },
      complete: () => {},
    });
  }

  @Action(UpdateClass)
  updateClass(ctx: StateContext<ClassesStateModel>, { payload }: UpdateClass) {
    return this.classesService.updateClass(payload.classItem).subscribe({
      next: () => {
        ctx.dispatch(new GetClassList());
        ctx.dispatch(new GetAssignmentList());
      },
      error: (err) => {

      },
      complete: () => {},
    });
  }

  @Action(CreateClass)
  create(
    { getState, patchState }: StateContext<ClassesStateModel>,
    { payload }: CreateClass,
  ) {
    patchState({
      isLoading: true,
    });

    return this.classesService.createClass(payload.title).subscribe({
      next: (res) => {
        const state = getState();
        patchState({
          classList: [...state.classList, res],
          lastCreatedClass: res,
          isLoading: false,
        });
        this.store.dispatch(new EventClassCreated({classID: res._id}))
      },
      error: (err) => {

      },
      complete: () => {},
    });
  }

  @Action(ClearClasses)
  clearClasses(ctx: StateContext<ClassesStateModel>) {
    ctx.patchState({ classList: [] });
  }

}
