import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ModalCloseAction } from '../../_stores/modal/_actions/modal.actions';
import { DeleteAssignment, EditAssignment } from "../../_stores/asignments/_actions/asignments.actions";
import { ModalState } from '../../_stores/modal/_state/modal.state';
import { Router } from '@angular/router';
import { AssignmentModel } from "../../_models/assignment.model";
import { AssignmentsState } from "../../_stores/asignments/_state/assignments.state";

@Component({
  selector: 'ui-assignment-delete',
  templateUrl: './ui-assignment-delete.component.html',
  styleUrls: ['./ui-assignment-delete.component.scss'],
})
export class UiAssignmentDeleteComponent implements OnInit {
  interface: {
    _id: null,
    loading: boolean,
    assignment: AssignmentModel
  } = {
    _id: null,
    loading: false,
    assignment: undefined
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private router: Router) {}
  ngOnInit(): void {
    this.initAssignment();
  }

  initAssignment(): void {
    this.store.select(AssignmentsState.selectAssignmentsByModalId).subscribe((_assignment) => {
      this.interface.assignment = {..._assignment};
      this.interface.assignment.options = {...this.interface.assignment.options, isActive: false};
    });
  }

  actionConfirm(): void {
    this.interface.loading = true;
    this.store.dispatch(new DeleteAssignment({ assignmentID: this.interface.assignment._id })).subscribe((value) => {
      this.interface.loading = false;
      this.store.dispatch(new ModalCloseAction());
      this.cdr.detectChanges();
    });
  }

  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }

}
