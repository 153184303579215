import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiControlRoleDeleteComponent } from './ui-control-role-delete.component';

@NgModule({
  declarations: [UiControlRoleDeleteComponent],
  exports: [UiControlRoleDeleteComponent],
  imports: [CommonModule],
})
export class UiControlRoleDeleteModule {}
