import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UiProfileAddModel} from "../../_models/ui-profile-add.model";
import {UserModel} from "../../_models/user.model";
import {GradeModel} from "../../_models/grade.model";
import {RoleModel} from "../../_models/role.model";
import {SchoolModel} from "../../_models/school.model";
import {Store} from "@ngxs/store";
import {LoadControlRoles} from "../../_stores/control/_actions/control.actions";
import {ControlState} from "../../_stores/control/_state/control.state";
import {API} from "../../_stores/api";
import {HttpClient} from "@angular/common/http";
import {PracticeState} from "../../_stores/practice/_state/practice.state";
import { GetTeachersStudents } from "../../_stores/user/_actions/user.actions";
import { ModalCloseAction } from "../../_stores/modal/_actions/modal.actions";

@Component({
  selector: 'ui-control-user-add',
  templateUrl: './ui-control-user-add.component.html',
  styleUrls: ['./ui-control-user-add.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiControlUserAddComponent implements OnInit {
  interface: any = {
    user: new (class implements UserModel {
      _id: string;
      avatar: string;
      language: string;
      displayName: string;
      email: string;
      firstName: string;
      lastName: string;
      password: string;
      practice: string | GradeModel;
      role: RoleModel;
      school: SchoolModel;
      ain: number
    })(),
    roles: [],
    schools: [],
    grades: [],
    _cache: {
      open_choose_grade: false,
      open_search_school: false,
      selected_school: '',
      selected_grade: '',
      accepted: false,
      error_fields: [],
    },
    loading: false,
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private http: HttpClient) {
    this.store.dispatch(new LoadControlRoles());
  }

  ngOnInit(): void {
    this.initClearUser();
    this.initRoles();
    this.initGrades();
  }

  initClearUser(): void {
    this.interface.user = {
      _id: null,
      avatar: '',
      language: 'en',
      displayName: '',
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      practice: null,
      role: null,
      school: null,
      ain: null
    }
  }
  initGrades(): void {
    this.store.select(PracticeState.selectGrades).subscribe((_grades) => {
      this.interface.grades = _grades;
      this.cdr.detectChanges();
    });
  }
  initRoles(): void {
    this.store.select(ControlState.selectRoles).subscribe((_data) => {
      if(!_data) {
        return;
      }
      this.interface.roles = _data;
      this.interface.user.role = this.interface.roles[0];
      this.cdr.detectChanges();
    });
  }

  actionToggleSearchSchool(): void {
    this.interface._cache.open_search_school = !this.interface._cache.open_search_school;
  }
  actionSearchSchool(event?): void {
    const _search = event? event.target.value: '';
    this.http.get<any[]>(API.url('schools/search'),  { params: { search: _search } }).subscribe({
      next: (_next) => {
        this.interface.schools = _next;
        this.cdr.detectChanges();
      },
    });
  }
  actionToggleChooseGrade(): void {
    this.interface._cache.open_choose_grade = !this.interface._cache.open_choose_grade;
  }

  actionSelectSchool(school): void {
    this.interface._cache.open_search_school = false;
    this.interface._cache.selected_school = school.schoolName;
    this.interface.user.school = school._id;
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'school');
    this.cdr.detectChanges();
  }
  actionSelectGrade(grade): void {
    this.interface._cache.open_choose_grade = false;
    this.interface._cache.selected_grade = grade.title;
    this.interface.user.practice = grade;
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'practice');
    this.cdr.detectChanges();
  }
  actionSelectAvatar(index): void {
    this.interface.user.avatar = 'https://cdn.argoprep.com/avatars/avatar-' + index + '.svg';
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'avatar');
  }
  actionSelectRole(role): void {
    this.interface.user.role = role;
  }
  actionEnterFirstName(event) {
    this.interface.user.firstName = event.target.value;
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'firstName');
  }
  actionEnterLastName(event) {
    this.interface.user.lastName = event.target.value;
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'lastName');
  }
  actionEnterLogin(event) {
    this.interface.user.email = event.target.value;
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'email');
  }
  actionEnterPassword(event) {
    this.interface.user.password = event.target.value;
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'password');
  }
  actionGeneratePassword(): void {
    this.interface.user.password = Math.random().toString(36).slice(-6);
  }

  actionAddUser(): void {
    this.interface._cache.error_fields = [];
    Object.entries(this.interface.user).forEach(([field, data]) => {
      if (!data) {
        this.interface._cache.error_fields.push(field);
      }
    });
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== '_id');
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'ain');
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'displayName');


    if(this.interface._cache.error_fields.length > 0) {
      return;
    }


    this.interface.loading = true;
    const _body = {
      firstName: this.interface.user.firstName,
      lastName: this.interface.user.lastName,
      email: this.interface.user.email,
      password: this.interface.user.password,
      school: this.interface.user.school,
      role: this.interface.user.role,
      avatar: this.interface.user.avatar,
      practice: this.interface.user.practice,
    }
    this.http.put(API.url('auth/add'), _body).subscribe({
      next: (value) => {
        this.interface.loading = false;
        this.store.dispatch(new ModalCloseAction());
        this.cdr.detectChanges();
      },
      error: (err) => {
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
    });

  }

}
