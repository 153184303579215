import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {AuthState} from "../_stores/auth/_state/auth.state";
import {UserState} from "../_stores/user/_state/user.state";

@Injectable({
  providedIn: 'root',
})
export class ActivateProtectedChildGuard implements CanActivateChild {

  constructor(private store: Store, private router: Router) {}


  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.store.selectSnapshot(UserState.selectUser);
    if(user.role._id == '6301e162029cc9403c26de0c') {
      return true;
    } else {
      this.router.navigate(['/']).then();
      return false;
    }
  }

}
