import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { AssignmentModel } from "../../_models/assignment.model";
import { ModalOpenActionWithID } from "../../_stores/modal/_actions/modal.actions";
import { Store } from "@ngxs/store";
import { Router } from "@angular/router";

@Component({
  selector: 'ui-assignment',
  templateUrl: './ui-assignment.component.html',
  styleUrls: ['./ui-assignment.component.scss']
})
export class UiAssignmentComponent implements OnInit {
  @Input('assignment') set _assignment(_assignment) {
    if (_assignment) {
      this.interface.assignment = _assignment;
    }
  }
  interface: {
    assignment: AssignmentModel
  } = {
    assignment: undefined
  };
  constructor(private store: Store, private cdr: ChangeDetectorRef, private router: Router) { }

  ngOnInit(): void {
  }

  actionOpenAssignmentPage(): void {
    this.router.navigate(['assignments/'+ this.interface.assignment._id]).then();
  }
  actionOpenAssignmentMenu(event): void {
    event.currentTarget.parentElement.classList.toggle('ui-class-assignment-header-title-action-open');
  }
  actionAssignmentArchive(assignment, event): void {
    event.currentTarget.parentElement.parentElement.classList.toggle('ui-class-assignment-header-title-action-open');
    if(assignment.options.isActive) {
      this.store.dispatch(new ModalOpenActionWithID('assignment-archive', assignment._id));
    } else {
      this.store.dispatch(new ModalOpenActionWithID('assignment-unarchive', assignment._id));
    }
  }
  actionAssignmentDelete(assignment, event): void {
    event.currentTarget.parentElement.parentElement.classList.toggle('ui-class-assignment-header-title-action-open');
    this.store.dispatch(new ModalOpenActionWithID('assignment-delete', assignment._id));
  }

}
