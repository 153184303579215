<div class="ui-block ui-block-white">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Libraries License</h2>
      <p>Total number of libraries license: <strong>{{interface.data.length}}</strong></p>
    </div>
    <div class="ui-block-header-action">
      <div class="ui-block-header-filter">
        <div class="ui-form-input ui-form-input-xs min-w-[14rem]">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
          <input name="ain" type="text" [value]="" placeholder="Search libraries license" (keyup)="actionSearchLicense($event)">
        </div>
      </div>
      <button class="ui-btn ui-btn-sm" (click)="actionOpenAddDataModal()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
        </svg>
        <span>Add license</span>
      </button>
    </div>
  </div>
  <div class="ui-block-inner ui-block-inner-col my-4">
    <ng-container *ngIf="interface.data.length == 0">
      <ui-no-data text="Loading data..."></ui-no-data>
    </ng-container>
    <ng-container *ngIf="interface.data.length > 0">
      <ng-container [ngTemplateOutlet]="DATA_TABLE_ACTIONS"></ng-container>
      <ng-container [ngTemplateOutlet]="DATA_TABLE"></ng-container>
    </ng-container>
  </div>
  <div class="ui-block-footer">
  </div>
</div>

<ng-template #DATA_TABLE_ACTIONS></ng-template>
<ng-template #DATA_TABLE>
  <div class="flex w-full overflow-auto rounded-lg">
  <table class="ui-table">
    <caption></caption>
    <thead>
      <tr>
        <th scope="col"><div class="ui-form-checkbox"><input type="checkbox"></div></th>
        <th scope="col">Name</th>
        <th scope="col">Users</th>
        <th scope="col">Status</th>
        <th scope="col">Max users</th>
        <th scope="col">White list</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of interface.data | sort:'asc':'order'">
        <tr>
          <td>
            <div class="ui-form-checkbox"><input type="checkbox"></div>
          </td>
          <td>
            <div class="flex flex-col">
              <div class="font-medium text-slate-900 hover:text-pap-400" [routerLink]="'/control/libraries/'+item?.license?.school?._id+'/detail'">{{item?.license?.school?.schoolName || 'No school'}}</div>
              <div class="text-xs text-slate-400">{{item?.license?._id}}</div>
            </div>
          </td>
          <td>
            <div class="flex items-center">
              <div class="flex flex-col">
                <div class="flex w-full"><span class="text-xs text-slate-400 w-[2rem]">{{item.users && item.users.length > 0 ? getCountUsers(item.users).clever : 0}}</span><span class="text-xs"> Clever </span></div>
                <div class="flex w-full"><span class="text-xs text-slate-400 w-[2rem]">{{item.users && item.users.length > 0 ? getCountUsers(item.users).argoprep : 0}}</span><span class="text-xs"> ArgoPrep </span></div>
              </div>
            </div>
          </td>
          <td>
            <div class="flex items-center">
              <ng-container *ngIf="item?.license?.active"><span class="ui-badge ui-badge-succeed">Active</span></ng-container>
              <ng-container *ngIf="!item?.license?.active"><span class="ui-badge ui-badge-error">Deactivated</span></ng-container>
            </div>
          </td>
          <td>
            <div class="flex items-center">
              {{item?.license?.maxCountUser}}
            </div>
          </td>
          <td>
            <div class="flex items-center">
             ---
            </div>
          </td>

          <td>
            <button tooltip title="Edit" class="ui-btn ui-btn-xs ui-btn-icon" (click)="actionOpenEditDataItemModal(item.license._id)">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
              </svg>
            </button>
            <button tooltip title="Delete" class="ui-btn ui-btn-xs ui-btn-icon ui-btn-error" (click)="actionOpenDeleteDataItemModal(item.license._id)">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
              </svg>
            </button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  </div>
</ng-template>
