import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { PagePracticesModel } from '../../_models/page-practices.model';
import { Store } from '@ngxs/store';
import { PracticeState } from '../../_stores/practice/_state/practice.state';
import { UserState } from '../../_stores/user/_state/user.state';
import { SetPracticeToUser } from '../../_stores/user/_actions/user.actions';
import { GradeModel } from '../../_models/grade.model';

@Component({
  selector: 'ui-grades',
  templateUrl: './ui-grades.component.html',
  styleUrls: ['./ui-grades.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiGradesComponent implements OnInit {
  @Output() currentGrade = new EventEmitter<GradeModel>();
  interface: PagePracticesModel = {
    grades: [],
    categories: [],
    user: undefined,
    _cache: {
      open_choose_grade: false,
    },
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initUser();
    this.initGrades();
  }

  initGrades(): void {
    this.store.select(PracticeState.selectGrades).subscribe((_grades) => {
      this.interface.grades = _grades;
      this.cdr.detectChanges();
    });
  }

  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.cdr.detectChanges();
    });
  }

  getCurrentUserGrade(): string {
    const _grade = this.interface.grades?.find((grade) => grade._id === this.interface.user?.practice);
    return _grade ? _grade.title : '';
  }

  actionChooseGrade(grade): void {
    this.currentGrade.emit(grade);
    this.store.dispatch(new SetPracticeToUser(grade._id));
    this.actionToggleChooseGrade();
    this.cdr.detectChanges();
  }

  actionToggleChooseGrade(): void {
    this.interface._cache.open_choose_grade = !this.interface._cache.open_choose_grade;
  }
}
