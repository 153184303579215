import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { LoadControlGrades } from '../../_stores/control/_actions/control.actions';
import { ControlState } from '../../_stores/control/_state/control.state';
import { ModalOpenAction, ModalOpenActionWithID } from '../../_stores/modal/_actions/modal.actions';

@Component({
  selector: 'ui-control-grades',
  templateUrl: './ui-control-grades.component.html',
  styleUrls: ['./ui-control-grades.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiControlGradesComponent implements OnInit {
  interface: any = {
    data: [],
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {
    this.store.dispatch(new LoadControlGrades());
  }

  ngOnInit(): void {
    this.initData();
  }

  initData(): void {
    this.store.select(ControlState.selectGrades).subscribe((_data) => {
      this.interface.data = _data;
      this.cdr.detectChanges();
    });
  }

  actionOpenAddDataModal(): void {
    this.store.dispatch(new ModalOpenAction('control_grade_add'));
    this.cdr.detectChanges();
  }

  actionOpenEditDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('control_grade_edit', _id));
    this.cdr.detectChanges();
  }

  actionOpenDeleteDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('control_grade_delete', _id));
    this.cdr.detectChanges();
  }

  actionSearchGrade(event) {
    if (event.target.value && event.target.value.length > 0) {
      this.interface.data = this.interface.data.filter((item) => {
        return item.title.toLowerCase().includes(event.target.value.toLowerCase());
      });
    } else {
      this.initData();
    }
  }
}
