import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiTooltipComponent} from "./ui-tooltip.component";
import {ToolTipsDirective} from "../../_directives/tooltips.directive";
import {OverlayModule} from "@angular/cdk/overlay";



@NgModule({
  declarations: [UiTooltipComponent, ToolTipsDirective],
  exports: [UiTooltipComponent, ToolTipsDirective],
  imports: [
    CommonModule,
    OverlayModule
  ]
})
export class UiTooltipModule { }
