import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { EditControlGrade, LoadControlGrades } from '../../_stores/control/_actions/control.actions';
import { ModalCloseAction } from '../../_stores/modal/_actions/modal.actions';
import { ControlState } from '../../_stores/control/_state/control.state';
import { take } from 'rxjs';

@Component({
  selector: 'ui-control-grade-edit',
  templateUrl: './ui-control-grade-edit.component.html',
  styleUrls: ['./ui-control-grade-edit.component.scss'],
})
export class UiControlGradeEditComponent implements OnInit {
  interface: any = {
    grade: { title: '' },
    _cache: {
      error_fields: [],
    },
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {
    this.store.dispatch(new LoadControlGrades());
  }

  ngOnInit(): void {
    this.initGrade();
  }

  actionEnterGradeTitle(event) {
    this.interface._cache.title = event.target.value;
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'name');
  }

  actionEditGrade(): void {
    this.interface.loading = true;
    this.store
      .dispatch(
        new EditControlGrade({
          data: {
            _id: this.interface.grade._id,
            title: this.interface._cache.title,
          },
        }),
      )
      .subscribe({
        next: () => {
          this.interface.loading = false;
          this.store.dispatch(new ModalCloseAction());
          this.cdr.detectChanges();
        },
        error: () => {
          this.interface.loading = false;
          this.cdr.detectChanges();
        },
      });
  }

  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }

  private initGrade() {
    this.store
      .select(ControlState.selectGradeByModalId)
      .pipe(take(1))
      .subscribe((_grade) => {
        this.interface = { ...this.interface, grade: _grade };
        this.interface._cache = { ...this.interface._cache, title: _grade.title };
        this.cdr.detectChanges();
      });
  }
}
