import { ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { LoadControlSchools } from '../../_stores/control/_actions/control.actions';
import { ControlState } from '../../_stores/control/_state/control.state';
import { ModalOpenAction, ModalOpenActionWithID } from '../../_stores/modal/_actions/modal.actions';
import { SchoolModel } from '../../_models/school.model';
import { UserModel } from '../../_models/user.model';
import {EventInviteToSchoolLinkCopied} from "../../_stores/event_ws/_actions/events.actions";
import {NotificationOpen} from "../../_stores/notification/_actions/notification.actions";
import {Clipboard} from "@angular/cdk/clipboard";

interface UiControlSchoolsModel {
  search: string;
  sortedField: {
    schoolName: string;
    ncesId: string;
    schoolLevel: string;
    license: string;
  };
  sort: string;
  pages: any[];
  currentPage: number;
  data: { school: SchoolModel; users: UserModel[] }[];
  perPage: number;
  totalData: number;
  totalPage: number;
  _cache: {
    search: boolean;
    sortedByName: boolean;
    sortedByNcesId: boolean;
    sortedByLevel: boolean;
    sortedByLicense: boolean;
    copied: boolean
  };
  sortIndex: {
    asc: string;
    desc: string;
  };
}

@Component({
  selector: 'ui-control-schools',
  templateUrl: './ui-control-schools.component.html',
  styleUrls: ['./ui-control-schools.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiControlSchoolsComponent implements OnInit, OnChanges {
  interface: UiControlSchoolsModel = {
    sortedField: { license: '', ncesId: '', schoolLevel: '', schoolName: '' },
    sortIndex: { asc: '@asc', desc: '@desc' },
    _cache: { search: false, sortedByLevel: false, sortedByLicense: false, sortedByName: false, sortedByNcesId: false,   copied: false },
    currentPage: 0,
    data: [],
    pages: [],
    perPage: 0,
    search: '',
    sort: '',
    totalData: 0,
    totalPage: 0,
  };

  private readonly FIRST_PAGE = 1;

  constructor(private store: Store, private cdr: ChangeDetectorRef, private clipboard: Clipboard) {
    this.store.dispatch(new LoadControlSchools('', this.FIRST_PAGE, ''));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes && changes.currentPage.currentValue) || (changes.total && changes.total.currentValue)) {
      this.initData();
    }
  }

  ngOnInit(): void {
    this.initSortedFields();
    this.initData();
    this.cdr.detectChanges();
  }

  initData(): void {
    this.store.select(ControlState.selectSchools).subscribe((_data) => {
      this.interface = {
        ...this.interface,
        data: _data.data,
        currentPage: _data.currentPage,
        totalPage: _data.totalPage,
        totalData: _data.totalData,
        pages: this.getPages(this.interface.currentPage, this.interface.totalPage),
      };
      this.cdr.detectChanges();
    });
    this.cdr.detectChanges();
  }

  actionOpenAddDataModal(): void {
    this.store.dispatch(new ModalOpenAction('control_schools_add'));
  }

  actionOpenEditDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('control_schools_edit', _id));
  }

  actionOpenDeleteDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('control_schools_delete', _id));
  }

  actionNextPage(event): void {
    let element = document.querySelector('thead');
    element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    this.interface.pages = [];
    this.interface = {
      ...this.interface,
      currentPage: event,
    };
    this.interface.currentPage = event;
    this.store.dispatch(
      new LoadControlSchools(this.interface?.search, this.interface.currentPage, this.interface?.sort),
    );

    this.cdr.detectChanges();
  }

  actionSearchSchool(event) {
    if (event.target.value && event.target.value.length > 0) {
      this.interface = { ...this.interface, search: event.target.value };
      this.store.dispatch(new LoadControlSchools(this.interface?.search, this.FIRST_PAGE, this.interface?.sort));
      this.initData();
      this.cdr.detectChanges();
    } else {
      this.interface = { ...this.interface, search: undefined };
      this.store.dispatch(new LoadControlSchools(this.interface?.search, this.FIRST_PAGE, this.interface.sort));
      this.initData();
      this.cdr.detectChanges();
    }
  }

  actionSortByField(sortedField: string, sortIndex: string) {
    this.interface = { ...this.interface, sort: sortedField + sortIndex };
    this.store.dispatch(new LoadControlSchools(this.interface?.search, this.FIRST_PAGE, this.interface.sort));
    this.initData();
    this.cdr.detectChanges();
  }

  private getPages(currentPage: number, totalPage: number) {
    if (currentPage <= 10) {
      if (totalPage < 10) {
        return [...Array(totalPage).keys()].map((x) => ++x);
      }
      return [...Array(10).keys()].map((x) => ++x);
    }
    if (currentPage > 10) {
      if (currentPage >= totalPage - 2) {
        return [totalPage - 3, totalPage - 2, totalPage - 1, totalPage];
      } else {
        return [currentPage - 1, currentPage, currentPage + 1];
      }
    }
  }

  private initSortedFields() {
    this.interface.sortedField = {
      ...this.interface.sortedField,
      schoolName: 'schoolName',
      schoolLevel: 'schoolLevel',
      license: 'license',
      ncesId: 'ncesId',
    };
  }

  getCountUsers(users: UserModel[]) {
    let argoprep_count = 0;
    let clever_count = 0;
    for (const user of users) {
      if (user.providerRegister && user.providerRegister.length > 0) {
        if (user.providerRegister.find((value) => value.name === 'argoprep' && value.active === true)) {
          argoprep_count++;
        }
        if (user.providerRegister.find((value) => value.name === 'clever' && value.active === true)) {
          clever_count++;
        }
      }
    }
    return { clever: clever_count, argoprep: argoprep_count };
  }

  actionClipboardInviteLink(school): void {
    console.log(school);
    let _link = window.location.origin + '/invite/' + school.invitation.sin;
    this.clipboard.copy(_link);
    this.store.dispatch(new NotificationOpen('school_invite_link_copy'));
    this.interface._cache.copied = true;
    this.cdr.detectChanges();
  }
}
