import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiClassComponent } from "./ui-class.component";
import { UiTooltipModule } from "../ui-tooltip/ui-tooltip.module";
import { RouterModule } from "@angular/router";



@NgModule({
  declarations: [UiClassComponent],
  exports: [UiClassComponent],
  imports: [
    CommonModule,
    UiTooltipModule,
    RouterModule
  ]
})
export class UiClassModule { }
