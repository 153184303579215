<div class="ui-block ui-block-white">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Libraries</h2>
      <p>Total number of libraries: <strong>{{interface.totalData}}</strong></p>
    </div>
    <div class="ui-block-header-action">
      <div class="ui-schools-filters">
        <div class="ui-schools-filter-input-group">
          <div class="ui-schools-filter-input">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
            </svg>
            <input name="search" type="text" placeholder="Search" (keyup)="actionSearchSchool($event)">
          </div>
          <div class="ui-schools-filter-text-xs" *ngIf="interface?._cache?.search">Found
            <strong>{{interface.data.length}}</strong> schools
          </div>
        </div>
      </div>
      <button class="ui-btn add-school" (click)="actionOpenAddDataModal()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
        </svg>
        <span>Add new</span>
      </button>
    </div>
  </div>
  <div class="ui-block-inner ui-block-inner-col my-4">
      <ng-container [ngTemplateOutlet]="DATA_TABLE_ACTIONS"></ng-container>
      <ng-container [ngTemplateOutlet]="DATA_TABLE"></ng-container>
      <ng-container [ngTemplateOutlet]="DATA_TABLE_PAGINATION"></ng-container>
  </div>
  <div class="ui-block-footer">
  </div>
</div>

<ng-template #DATA_TABLE_ACTIONS></ng-template>
<ng-template #DATA_TABLE>
  <div class="flex w-full overflow-auto rounded-lg">
    <table class="ui-table">
      <caption></caption>
      <thead>
      <tr>
        <th scope="col">
          <div class="ui-form-checkbox"><input type="checkbox"></div>
        </th>
        <th>
          <div class="ui-table-header">
            <div>Name</div>
            <div class="ui-table-header-sort">
              <ng-container
                *ngTemplateOutlet="sortedField; context: {field:interface.sortedField.schoolName, sortedIndex: interface.sortIndex}"></ng-container>
            </div>
          </div>
        </th>
        <th>
          <div class="ui-table-header">
            <div>Users</div>
<!--            <div class="ui-table-header-sort">-->
<!--              <ng-container-->
<!--                *ngTemplateOutlet="sortedField; context: {field:interface.sortedField.ncesId, sortedIndex: interface.sortIndex}"></ng-container>-->
<!--            </div>-->
          </div>
        </th>
        <th>
          <div class="ui-table-header">
            <div>Level</div>
            <div class="ui-table-header-sort">
              <ng-container
                *ngTemplateOutlet="sortedField; context: {field:interface.sortedField.schoolLevel, sortedIndex: interface.sortIndex}"></ng-container>
            </div>
          </div>
        </th>
        <th>
          <div class="ui-table-header">
            <div>License</div>
            <div class="ui-table-header-sort">
              <ng-container
                *ngTemplateOutlet="sortedField; context: {field:interface.sortedField.license, sortedIndex: interface.sortIndex}"></ng-container>
            </div>
          </div>
        </th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let item of interface.data | sort:'asc':'order'">
        <tr>
          <td>
            <div class="ui-form-checkbox"><input type="checkbox"></div>
          </td>
          <td>
            <div class="flex flex-col">
              <div class="font-medium text-slate-900 hover:text-pap-400"
                   [routerLink]="item.school._id+'/detail'">{{item.school["schoolName"]}}</div>
              <div class="text-xs text-slate-400">{{item.school["schoolDistrict"]}}</div>
            </div>
          </td>
          <td>
            <div class="flex items-center">
              <div class="flex flex-col">
                <div class="flex w-full"><span class="text-xs text-slate-400 w-[2rem]">{{item.users && item.users.length > 0 ? getCountUsers(item.users).clever : 0}}</span><span class="text-xs"> Clever </span></div>
                <div class="flex w-full"><span class="text-xs text-slate-400 w-[2rem]">{{item.users && item.users.length > 0 ? getCountUsers(item.users).argoprep : 0}}</span><span class="text-xs"> ArgoPrep </span></div>
              </div>
            </div>
          </td>
          <td>
            <div class="flex items-center">{{item.school["schoolLevel"]}}</div>
          </td>
          <td>
            <div class="flex items-center">
              <ng-container *ngIf="item.school.license"><span class="ui-badge ui-badge-succeed">Licensed</span></ng-container>
              <ng-container *ngIf="!item.school.license"><span class="ui-badge ui-badge-error">Not licensed</span>
              </ng-container>
            </div>
          </td>
          <td>
            <button class="ui-btn ui-btn-xs ui-btn-icon" tooltip description="Copy invitation link" (click)="actionClipboardInviteLink(item.school)">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
              </svg>
            </button>
            <button tooltip title="Edit" class="ui-btn ui-btn-xs ui-btn-icon"
                    (click)="actionOpenEditDataItemModal(item.school._id)">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
              </svg>
            </button>
            <button tooltip title="Delete" class="ui-btn ui-btn-xs ui-btn-icon ui-btn-error"
                    (click)="actionOpenDeleteDataItemModal(item.school._id)">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
              </svg>
            </button>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
</ng-template>
<ng-template #DATA_TABLE_PAGINATION>
  <ui-pagination [pages]="interface.pages"
                 [currentPage]="interface.currentPage"
                 [totalPage]="interface.totalPage" (nextPage)="actionNextPage($event)">
  </ui-pagination>
</ng-template>


<ng-template #sortedField let-field="field" let-sortedIndex="sortedIndex">
  <ng-container *ngIf="!interface.sort.includes(field)">
    <svg (click)="actionSortByField(field,sortedIndex['asc'])"
         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
         stroke="currentColor" class="w-3 h-3">
      <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
    </svg>
    <svg (click)="actionSortByField(field,sortedIndex['desc'])"
         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
         stroke="currentColor" class="w-3 h-3">
      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  </ng-container>
  <ng-container *ngIf="interface.sort.includes(field)">
    <svg (click)="actionSortByField(field,sortedIndex['asc'])" [class.select]="interface.sort.includes(interface.sortIndex.asc) && interface.sort.includes(field)"
         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
         stroke="currentColor" class="w-3 h-3">
      <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
    </svg>
    <svg (click)="actionSortByField(field,sortedIndex['desc'])" [class.select]="interface.sort.includes(interface.sortIndex.desc) && interface.sort.includes(field)"
         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
         stroke="currentColor" class="w-3 h-3">
      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  </ng-container>
</ng-template>
