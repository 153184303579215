import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiControlGradeAddComponent } from './ui-control-grade-add.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [UiControlGradeAddComponent],
  exports: [UiControlGradeAddComponent],
  imports: [CommonModule, TranslocoModule],
})
export class UiControlGradeAddModule {}
