<div class="ui-block ui-block-white">
    <div class="ui-block-header">
        <div class="ui-block-header-title">
               <h2>Information</h2>
               <p>
                 <ng-container *ngIf="interface.assignment?.options.isActive">Active assignment</ng-container>
                 <ng-container *ngIf="!interface.assignment?.options.isActive">Archive assignment</ng-container>
               </p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile">
            <button class="ui-btn ui-btn-sm" (click)="actionEditAssignment()" disabled>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
              </svg>
              <span>Edit assignment</span>
            </button>
        </div>
    </div>
    <div class="ui-block-inner ui-block-inner-col my-4">
      <ui-no-data *ngIf="!interface.assignment" text="Loading assignment information..."></ui-no-data>
      <div *ngIf="interface.assignment" class="ui-task-info"  [class.ui-task-info-archive]="!interface.assignment.options.isActive">
        <div class="ui-task-info-header">
          <div class="ui-task-info-title">
            <div class="ui-task-info-title-icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z" />
              </svg>
            </div>
            <div class="ui-task-info-title-text">
              <h3>{{interface.assignment.title}}</h3>
              <p>You edited on {{interface.assignment.updatedAt | date}}</p>
            </div>
          </div>
          <div class="ui-task-info-data">
            <div class="ui-task-info-data-icon">
              <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6">
                <path d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              </svg>
            </div>
            <div class="ui-task-info-data-text">
              <span>Due</span>
              <p>{{interface?.assignment?.dates?.dueDate | date}}</p>
            </div>
          </div>
          <div class="ui-task-info-data">
            <div class="ui-task-info-data-icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
              </svg>
            </div>
            <div class="ui-task-info-data-text">
              <span>Scheduled</span>
              <p> {{interface.assignment.dates.scheduleDate | date}}</p>
            </div>
          </div>
          <div class="ui-task-info-data">
            <div class="ui-task-info-data-icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6" />
              </svg>
            </div>
            <div class="ui-task-info-data-text">
              <span>Total progress</span>
              <p>{{interface.assignment.countTotalProgress || 0}}%</p>
            </div>
          </div>
          <div class="ui-task-info-students">
            <ng-container *ngFor="let student of interface.assignment.students | slice: 0:3; let s=index">
              <div class="ui-task-info-student">
                <img  [src]="student.avatar" alt="{{student.displayName}}" tooltip description="{{student.displayName}}"/>
              </div>
            </ng-container>
            <div *ngIf="interface.assignment.students?.length > 4"
                 class="ui-task-info-student ui-task-info-student-label">
              +{{interface.assignment.students?.length - 3}}
            </div>
          </div>
        </div>
        <div class="ui-task-info-body">
          <div class="ui-task-info-instructions-title">Instructions:</div>
          <div class="ui-task-info-instructions-text">
            {{interface.assignment.description || 'There were no instructions to follow when adding'}}
          </div>
        </div>
      </div>
    </div>
    <div class="ui-block-footer">
        <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet">
          <button class="ui-btn ui-btn-sm" (click)="actionEditAssignment()" disabled>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
            </svg>
            <span>Edit assignment</span>
          </button>
        </div>
    </div>
</div>
<div *ngIf="!interface._cache.result.show" class="ui-block ui-block-white">
    <div class="ui-block-header">
        <div class="ui-block-header-title">
          <h2>Progress</h2>
          <p>Progress in completing the assigned task</p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
    </div>
    <div class="ui-block-inner ui-block-inner-col my-4">
      <ui-no-data *ngIf="!interface._cache.selected_material" type="loading" text="Loading progress..."></ui-no-data>
      <ng-container *ngIf="interface._cache.selected_material">
        <div class="ui-task-progress" [class.ui-task-progress-archive]="!interface.assignment.options.isActive">
          <div class="ui-task-progress-materials">
            <ng-container *ngFor="let material of interface.assignment.materials; let a=index">
                <div class="ui-task-progress-material" [class.ui-task-progress-material-active]="interface._cache.selected_material._id == material._id" (click)="actionSelectAssignmentMaterial(material)">
                  <div class="ui-task-progress-material-icon">
                    <svg *ngIf="material.type == 'Category'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
                    </svg>
                    <svg *ngIf="material.type == 'Worksheet'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                    </svg>
                    <svg *ngIf="material.type == 'Video'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                    </svg>
                    <svg *ngIf="material.type == 'Book'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                    </svg>
                  </div>
                  <div class="ui-task-progress-material-text">
                    <p>{{material.title}}</p>
                    <span>Done {{material.progress?.done}} / {{material.progress?.total}}</span>
                  </div>
                  <div class="ui-task-progress-material-action">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                  </div>
                </div>
              </ng-container>
          </div>
          <div class="ui-task-progress-status">
            <div class="ui-task-progress-status-title">{{interface._cache.selected_material.title}}</div>
            <div class="ui-task-progress-status-awards">
              <div class="ui-task-progress-status-awards-cup">
                <img src="assets/images/shapes/trophy.png" loading="lazy" alt="">
                <label>
                  <p>Done</p>
                  <span>{{interface._cache.selected_material.progress?.done}} / {{interface._cache.selected_material.progress?.total}}</span>
                </label>
              </div>
              <div class="ui-task-progress-status-awards-progress">
                <div class="ui-task-progress-status-awards-progress-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
                  </svg>
                </div>
                <div class="ui-task-progress-status-awards-progress-data">
                  <p>
                    <span>0%</span>
                    <span>50%</span>
                    <span>100%</span>
                  </p>
                  <progress [max]="interface?.assignment?.students?.length" [value]="interface._cache.selected_material.progress?.done"></progress>
                </div>
              </div>
            </div>
            <div class="ui-task-progress-status-title">Students:</div>
            <div class="ui-task-progress-status-students">
              <ng-container *ngFor="let student of interface.assignment.students; let s=index">
                <div class="ui-task-progress-status-student">
                  <img  [src]="student.avatar" alt="{{student.displayName}}" tooltip description="{{student.displayName}}"/>
                  <p>{{student.displayName}}</p>
                  <span *ngIf="checkNotStartedStatus(student)" class="ui-badge ui-badge-error" tooltip title="{{student.displayName}}" description="Not started assignment">Not started</span>
                  <span *ngIf="checkStartedStatus(student)" class="ui-badge ui-badge-info" tooltip title="{{student.displayName}}" description="Started this assignment but didn't finish it">Started</span>
                  <span *ngIf="checkDoneStatus(student)"  (click)="actionShowResult(student)" class="ui-badge ui-badge-succeed" tooltip description="See completed assignment">
                    <ng-container *ngIf="interface._cache.selected_material.type != 'Video'">View work</ng-container>
                    <ng-container *ngIf="interface._cache.selected_material.type == 'Video'">Watched</ng-container>
                  </span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="ui-block-footer"></div>
</div>
<div *ngIf="interface._cache.result.show" class="ui-block ui-block-white ui-task-progress-result-container">
    <div class="ui-block-header">
        <div class="ui-block-header-icon">
          <svg *ngIf="interface._cache.selected_material.type == 'Category'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
          </svg>
          <svg *ngIf="interface._cache.selected_material.type == 'Worksheet'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
          </svg>
          <svg *ngIf="interface._cache.selected_material.type == 'Video'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
          </svg>
          <svg *ngIf="interface._cache.selected_material.type == 'Book'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
          </svg>
        </div>
        <div class="ui-block-header-title">
               <h2>{{interface._cache.selected_material.title}}</h2>
               <p>Result from {{interface._cache.result.student.firstName}} {{interface._cache.result.student.lastName}}</p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile">
            <button class="ui-btn ui-btn-sm ui-btn-blue-outline" (click)="actionHideResult()">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
              <span>Close result</span>
            </button>
        </div>
    </div>
    <div class="ui-block-inner ui-block-inner-col my-4">
      <ng-container *ngIf="interface._cache.selected_material.type == 'Worksheet'">
        <div class="ui-worksheet-actions mb-4">
          <div class="mr-auto text-slate-500 line-clamp-1">{{getIndex() + 1}} of {{interface._cache.result.worksheet.materialData.length}} worksheet pages</div>
          <button *ngIf="interface._cache.result.worksheet.materialData.length > 1" (click)="actionPrevImage()" class="ui-worksheet-action" tooltip title="Prev page" position="above" [disabled]="getIndex() + 1 == 1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
          </button>
          <button *ngIf="interface._cache.result.worksheet.materialData.length > 1" (click)="actionNextImage()" class="ui-worksheet-action" tooltip title="Next page" position="above">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          </button>
        </div>
        <div class="ui-task-progress-result-worksheet" [innerHTML]="interface._cache.result.worksheet.currentPage | resultViewer">
        </div>
      </ng-container>
      <ng-container *ngIf="interface._cache.selected_material.type == 'Book'">
        <div class="ui-worksheet-actions mb-4">
          <div class="mr-auto text-slate-500 line-clamp-1">{{getIndex() + 1}} of {{interface._cache.result.book.materialData.length}} book pages</div>
          <button *ngIf="interface._cache.result.book.materialData.length > 1" (click)="actionPrevImage()" class="ui-worksheet-action" tooltip title="Prev page" position="above">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
          </button>
          <button *ngIf="interface._cache.result.book.materialData.length > 1" (click)="actionNextImage()" class="ui-worksheet-action" tooltip title="Next page" position="above">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          </button>
        </div>
        <div class="ui-task-progress-result-book" [innerHTML]="interface._cache.result.book.currentPage | resultViewer">
        </div>
      </ng-container>
      <ng-container *ngIf="interface._cache.selected_material.type == 'Category'">
        <div class="ui-task-progress-result-quiz">
          <div class="ui-task-progress-result-quiz-tree" [class.ui-task-progress-result-quiz-tree-loading]="interface._cache.result.quiz.questions.length == 0">
            <ui-no-data *ngIf="interface._cache.result.quiz.questions.length == 0" type="loading"></ui-no-data>
            <ui-quiz-result [questions]="interface._cache.result.quiz.questions" [result]="interface._cache.result.quiz.progress"></ui-quiz-result>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="ui-block-footer">
        <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet">
          <button class="ui-btn ui-btn-sm ui-btn-blue-outline" (click)="actionHideResult()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
            <span>Close result</span>
          </button>
        </div>
    </div>
</div>
