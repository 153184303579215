import {
  Action, NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange, Selector,
  State,
  StateContext,
  Store
} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {AppModel} from "../../../_models/app.model";
import {ToggleSidebar} from "../_actions/app.actions";





export const _ModalDefault: AppModel = {
  sidebar_show: true,
};

@State<AppModel>({
  name: 'LAP',
  defaults: _ModalDefault,
})
@Injectable()
export class AppState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {

  constructor(private store: Store) {}

  ngxsAfterBootstrap(ctx?: StateContext<AppModel>): void {}

  ngxsOnChanges(change: NgxsSimpleChange<AppModel>): void {}

  ngxsOnInit(ctx?: StateContext<AppModel>): any {}

  @Selector()
  static selectState(state: AppModel) {
    return state;
  }


  @Action(ToggleSidebar)
  toggleSidebar(ctx: StateContext<AppModel>) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      sidebar_show: !state.sidebar_show,
    });
  }

}
