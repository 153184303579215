import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiPageInviteComponent } from "./ui-page-invite.component";
import { RouterModule } from "@angular/router";
import { TranslocoModule } from "@ngneat/transloco";
import { UiTooltipModule } from "../ui-tooltip/ui-tooltip.module";
import { UiModalModule } from "../ui-modal/ui-modal.module";
import { NgxsModule } from "@ngxs/store";
import { ModalState } from "../../_stores/modal/_state/modal.state";


@NgModule({
  declarations: [UiPageInviteComponent],
  exports: [UiPageInviteComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([ModalState]),
    RouterModule.forChild([
      {
        path: ":invite_id",
        component: UiPageInviteComponent
      },
      {
        path: "**",
        redirectTo: "/auth"
      }
    ]),
    TranslocoModule,
    UiTooltipModule,
    UiModalModule
  ]
})
export class UiPageInviteModule { }
