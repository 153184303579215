import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiControlRoleAddComponent } from './ui-control-role-add.component';
import { TranslocoModule } from '@ngneat/transloco';
import { UiTooltipModule } from '../ui-tooltip/ui-tooltip.module';

@NgModule({
  declarations: [UiControlRoleAddComponent],
  exports: [UiControlRoleAddComponent],
  imports: [CommonModule, TranslocoModule, UiTooltipModule],
})
export class UiControlRoleAddModule {}
