import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ModalState } from "../../_stores/modal/_state/modal.state";
import { Store } from "@ngxs/store";
import { HttpClient } from "@angular/common/http";
import { ModalCloseAction } from "../../_stores/modal/_actions/modal.actions";
import { API } from "../../_stores/api";
import { UserModel } from "../../_models/user.model";
import { CurrentUsers } from "../../_stores/user/_actions/user.actions";
import { UserState } from "../../_stores/user/_state/user.state";

@Component({
  selector: 'ui-profile-edit',
  templateUrl: './ui-profile-edit.component.html',
  styleUrls: ['./ui-profile-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiProfileEditComponent implements OnInit {
  interface:  {
    _id: null | string,
    loading: boolean,
    user: UserModel,
    auth: {
      login: string,
      password?: string
    },
    profile: {
      avatar: string,
      firstName: string,
      lastName: string,
      email: string
    }
  } = {
    _id: null,
    loading: false,
    user: undefined,
    auth: {
      login: ""
    },
    profile: {
      avatar: "",
      firstName: "",
      lastName: "",
      email: ""
    }
  }

  constructor(private store: Store, private cdr: ChangeDetectorRef, private http: HttpClient) { }

  ngOnInit(): void {
    this.initUser();
    this.initDataID();
  }

  initDataID() {
    this.store.select(ModalState.selectOpenedID).subscribe((_openedID) => {
      this.interface._id = _openedID;
      this.initProfile();
      this.cdr.detectChanges();
    });
  }
  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.cdr.detectChanges();
    });
  }
  initProfile(): void {
    this.http.get<any[]>(API.url('users/'+this.interface._id+'/auth')).subscribe({
      next: (_user_data: any) => {
        this.interface.auth.login = _user_data.auth.login;
        this.interface.profile.avatar = _user_data.profile.avatar;
        this.interface.profile.firstName = _user_data.profile.firstName;
        this.interface.profile.lastName = _user_data.profile.lastName;
        this.interface.profile.email = _user_data.profile.email;
        this.cdr.detectChanges();
      },
    });
  }

  actionEnterLogin(event): void {
    this.interface.auth.login = event.target.value;
  }
  actionEnterPassword(event): void {
    this.interface.auth.password = event.target.value;
  }
  actionSelectAvatar(index): void {
    this.interface.profile.avatar = 'https://cdn.argoprep.com/avatars/avatar-' + index + '.svg';
  }
  actionEnterFirstName(event): void {
    this.interface.profile.firstName = event.target.value;
  }
  actionEnterLastName(event): void {
    this.interface.profile.lastName = event.target.value;
  }
  actionEnterEmail(event): void {
    this.interface.profile.email = event.target.value;
  }
  actionGeneratePassword(): void {
    this.interface.auth.password = Math.random().toString(36).slice(-6);
  }
  actionDisconnectAccount(kid): void {
    let _body;
    _body = {
      id: kid._id,
    }
    this.http.put(API.url('auth/unbind/user'), _body).subscribe({
      next: (value) => {
        this.interface.loading = false;
        this.store.dispatch(new CurrentUsers());
        this.store.dispatch(new ModalCloseAction());
      },
      error: (err) => {
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
    });
  }


  actionConfirm(): void {
    this.interface.loading = true;
    let _body = {
      auth: this.interface.auth,
      profile: this.interface.profile
    };

    this.http.put<UserModel>(API.url('users/'+this.interface._id+'/auth'), _body).subscribe({
      next: (res) => {
        this.store.dispatch(new CurrentUsers());
        this.store.dispatch(new ModalCloseAction());
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
      error: (err) => {
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
    });
  }
  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }
}
