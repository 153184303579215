<div class="ui-school-license-add-container">
  <div class="ui-form">
    <div class="ui-form-input-group">
      <div class="ui-form-text ui-form-text-xs">Start</div>
      <ui-date-picker [future]="true" (selectedDate)="actionSelectStartDate($event)"></ui-date-picker>
    </div>
    <div class="ui-form-input-group">
      <div class="ui-form-text ui-form-text-xs">End</div>
      <ui-date-picker [future]="true" (selectedDate)="actionSelectEndDate($event)"></ui-date-picker>
    </div>
    <div class="ui-form-input-group">
      <div class="ui-form-text ui-form-text-xs">School</div>
      <div class='ui-form-select' [class.ui-form-select-error]="interface._cache.error_fields.includes('school')">
        <svg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='1.5'>
          <path d='M12 14l9-5-9-5-9 5 9 5z' />
          <path d='M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z' />
          <path stroke-linecap='round' stroke-linejoin='round' d='M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222' />
        </svg>
        <input id='school' name='school' type='text' readonly [value]="interface._cache.selected_school" placeholder="Select library" (click)="actionToggleSearchSchool()">
        <div class="ui-form-select-list" [class.ui-form-select-list-open]="interface._cache.open_search_school">
          <div class='ui-form-input'>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
            <input id='school_search' name='school_search' type='text' required (keyup)='actionSearchSchool($event)' placeholder="{{'inputs.school_search' | transloco}}" >
          </div>
          <div class="ui-form-select-list-items ui-select-school-list">
            <ng-container *ngIf="interface.schools.length == 0">
              <div class="ui-form-select-list-item">
                <p>Not found</p>
                <span>No schools from your request</span>
              </div>
            </ng-container>
            <ng-container *ngFor='let school of interface.schools'>
              <div class="ui-form-select-list-item" (click)="actionSelectSchool(school)">
                <p>{{school.schoolName}}</p>
                <span>{{school.address}}, {{school.city}}</span>
              </div>
            </ng-container>
          </div>

        </div>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" (click)="actionToggleSearchSchool()">
          <path *ngIf="!interface._cache.open_search_school" stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
          <path *ngIf="interface._cache.open_search_school" stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
        </svg>
      </div>
    </div>
    <div class="ui-form-input-group ui-form-input-group-row justify-between">
      <div class="ui-form-checkbox ui-form-checkbox-small">
        <input id="license_active" name="license_active" type="checkbox" (click)="actionToggleActive()" [checked]="interface.license.active">
        <label for="license_active">Active</label>
      </div>
      <div class="ui-form-input ui-form-input-small">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
        <input id="max_users" name="max_users" type="number"  placeholder="Maximum users" (keyup)="actionEnterMaxUsers($event)">
      </div>
    </div>
    <div class="ui-form-textarea">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
      </svg>
      <textarea rows="4" placeholder="White emails list" (keyup)="actionEnterWhiteList($event)"></textarea>
    </div>
  </div>
  <div class="ui-school-license-add-actions">
    <button class="ui-school-license-action-no" (click)="actionClose()">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
      </svg>
      <span>Cancel</span>
    </button>
    <button class="ui-school-license-action-yes" (click)="actionConfirm()" [disabled]="interface.loading">
      <svg *ngIf="!interface.loading" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
      </svg>
      <svg *ngIf="interface.loading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      <span>{{'buttons.confirm' | transloco}}</span>
    </button>
  </div>
</div>
