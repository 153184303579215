import {Pipe, PipeTransform} from '@angular/core';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({
  name: 'resultViewer'
})
export class ResultViewerPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) {}

  transform(value: string): string | SafeHtml{
    let _result = value;

    if(RegExp('\\b.pdf\\b').test(_result)) {
      _result = '<embed type="application/pdf" src="'+value+'#toolbar=0&navpanes=0&transparent=0"></embed>';
    } else {
      _result = '<img src="'+value+'" alt="" loading="lazy">';
    }
    return this.sanitizer.bypassSecurityTrustHtml(_result);
  }
}
