import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ClassModel } from '../../_models/class.model';
import { Store } from '@ngxs/store';
import { ClassesState } from '../../_stores/classes/_state/classes.state';
import { ModalOpenAction } from '../../_stores/modal/_actions/modal.actions';

export interface CLASSESUI {
  loading: boolean;
  currentClass: ClassModel;
  isLinkToInvite: boolean;
  load: boolean;
  modal: {
    add_class: boolean;
  };
  tab: string;
  classes: {
    current: ClassModel[];
    active: ClassModel[];
    archive: ClassModel[];
    data: ClassModel[];
  };
}

@Component({
  selector: 'ui-page-classes',
  templateUrl: './ui-page-classes.component.html',
  styleUrls: ['./ui-page-classes.component.scss'],
})
export class UiPageClassesComponent implements OnInit {
  @Input() dashboard: boolean;

  interface: CLASSESUI = {
    classes: { active: [], archive: [], current: [], data: [] },
    loading: true,
    currentClass: undefined,
    isLinkToInvite: false,
    load: false,
    modal: {
      add_class: false,
    },
    tab: 'active',
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.store
      .select(ClassesState.selectClassList)
      .pipe()
      .subscribe((classes) => {
        this.interface.classes.data = classes;
        this.initCurrentClasses();
        this.cdr.detectChanges();
      });
  }

  initCurrentClasses(): void {
    if (this.interface.tab === 'active') {
      this.interface.classes.current = this.interface.classes.data.filter((_class) => !_class?.options?.isArchive);
    }
    if (this.interface.tab === 'archive') {
      this.interface.classes.current = this.interface.classes.data.filter((_class) => _class?.options?.isArchive);
    }
  }

  actionOpenAddClassModal(): void {
    this.store.dispatch(new ModalOpenAction('class-add'));
  }
  actionChangeActiveTab(tab): void {
    this.interface.tab = tab;
    this.initCurrentClasses();
  }
  actionSearchClass(event): void {
    if (event.target.value.length === 0) {
      this.initCurrentClasses();
    } else {
      this.interface.classes.current = this.interface.classes.current.filter((item) => {
        return item.title.toLowerCase().includes(event.target.value.toLowerCase());
      });
    }
  }

  childContentLoad(event): void {
    this.interface.loading = false;
  }
  childContentUnLoad(event): void {
    this.interface.loading = true;
  }

}
