import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UserModel } from '../../../_models/user.model';
import {
  AddUserToCurrentUsers,
  CheckUserSchoolLicense,
  ClearUsers,
  CurrentUsers,
  FillCurrentUsers,
  GetTeachersParents,
  GetTeachersStudents,
  GetTeachersStudentsPagination,
  GetUserById,
  SetCurrentUser,
  SetGlobalUser,
  SetPracticeToUser,
  SetUserLanguage,
} from '../_actions/user.actions';
import { UserService } from '../_service/user.service';
import { CurrentCategories } from '../../practice/_actions/practice.actions';
import { ModalCloseAction, ModalOpenAction } from '../../modal/_actions/modal.actions';
import { GetAssignmentList, GetHomeworks } from '../../asignments/_actions/asignments.actions';
import { GetClassList } from '../../classes/_actions/classes.actions';
import { StudentModel } from '../../../_models/ui-page-students.model';
import { ModalState } from '../../modal/_state/modal.state';
import { ModalModel } from '../../../_models/modal.model';

export class UserStateModel {
  user: UserModel;
  users: UserModel[];
  students: StudentModel[];
  studentsPag: {
    currentPage: number;
    data: UserModel[];
    perPage: number;
    totalData: number;
    totalPage: number;
  };
  parents: UserModel[];
}

@State<UserStateModel>({
  name: 'LAP_USER',
  defaults: {
    user: null,
    users: [],
    students: [],
    studentsPag: { data: [], perPage: 0, totalData: 0, totalPage: 0, currentPage: 0 },
    parents: [],
  },
})
@Injectable()
export class UserState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {
  constructor(private store: Store, private userService: UserService) {}

  @Selector()
  static selectUser(state: UserStateModel) {
    return state.user;
  }

  @Selector()
  static selectUsers(state: UserStateModel) {
    return state.users;
  }

  @Selector()
  static selectAllStudentsOfCurrentTeacher(state: UserStateModel) {
    return state.students;
  }

  @Selector()
  static selectAllStudentsOfCurrentTeacherPag(state: UserStateModel) {
    return state.studentsPag;
  }

  @Selector()
  static selectAllParentsOfCurrentTeacher(state: UserStateModel) {
    return state.parents;
  }

  @Selector([ModalState])
  static selectEditedStudent(state: UserStateModel, modal: ModalModel) {
    return state.students.find((student) => student._id === modal._id);
  }

  ngxsAfterBootstrap(ctx?: StateContext<UserStateModel>): void {
    this.store.dispatch(new CurrentUsers());
  }

  ngxsOnInit(ctx?: StateContext<UserStateModel>): void {}

  ngxsOnChanges(change: NgxsSimpleChange<UserStateModel>): void {}

  @Action(CurrentUsers)
  currentUsers(ctx: StateContext<UserStateModel>) {
    let _current_user_index = undefined;
    const _state = ctx.getState();

    if (_state.user != null) {
      _current_user_index = _state.users.findIndex((_user) => _user._id == _state.user._id);
    }

    this.userService.getCurrentUsers(_current_user_index).subscribe({
      next: (_next) => {
        this.store.dispatch(new FillCurrentUsers(_next));
        if (_state.user == null) {
          this.store.dispatch(new SetCurrentUser(0));
        } else {
          ctx.dispatch(new CheckUserSchoolLicense());
        }
      },
      error: (_error) => {},
    });
  }

  @Action(GetTeachersStudents)
  getTeachersStudents(ctx: StateContext<UserStateModel>) {
    this.userService.getTeachersStudents().subscribe({
      next: (res) => {
        const _state = ctx.getState();
        ctx.patchState({
          ..._state,
          students: res,
        });
      },
    });
  }

  @Action(GetTeachersStudentsPagination)
  getTeachersStudentsPagination(ctx: StateContext<UserStateModel>, payload) {
    this.userService.getTeachersStudentsPagination(payload.page, 10).subscribe({
      next: (res) => {
        const _state = ctx.getState();
        ctx.patchState({
          ..._state,
          studentsPag: res,
        });
      },
    });
  }

  @Action(GetTeachersParents)
  getTeachersParents(ctx: StateContext<UserStateModel>) {
    this.userService.getTeachersParents().subscribe({
      next: (res) => {
        const _state = ctx.getState();
        ctx.patchState({
          ..._state,
          parents: res,
        });
      },
    });
  }

  @Action(ClearUsers)
  clearUsers(ctx: StateContext<UserStateModel>) {
    ctx.patchState({
      user: null,
      users: [],
    });
  }

  @Action(FillCurrentUsers)
  fillCurrentUsers(ctx: StateContext<UserStateModel>, payload) {
    const _state = ctx.getState();
    const _current_user_index = _state.users.findIndex((_user) => _user._id == _state.user?._id);
    ctx.patchState({
      ..._state,
      users: payload.users,
      user: payload.users[_current_user_index],
      /*      users: [..._state.users, ...payload.users].reduce(
        (acc, cur) => (acc.some((x) => x._id === cur._id) ? acc : acc.concat(cur)),
        [],
      ),*/
    });
  }

  @Action(GetUserById)
  getUserById(ctx: StateContext<UserStateModel>, payload) {
    this.userService.getUserById(payload.id).subscribe({
      next: (res) => {
        const _state = ctx.getState();
        ctx.patchState({
          ..._state,
          user: res,
        });
      },
    });
  }

  @Action(SetCurrentUser)
  setCurrentUser(ctx: StateContext<UserStateModel>, payload) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      user: _state.users[payload.index],
    });
    ctx.dispatch(new CheckUserSchoolLicense());
  }

  @Action(SetGlobalUser)
  setGlobalUser(ctx: StateContext<UserStateModel>, payload) {
    this.userService.setGlobalUser(payload.index).subscribe({
      next: (_next) => {
        let currentUser = _next[payload.index];

        if (currentUser && (currentUser.role.name === 'Teacher' || currentUser.role.name === 'Parent')) {
          this.store.dispatch(new GetAssignmentList());
          this.store.dispatch(new GetClassList());
          this.store.dispatch(new GetTeachersStudents());
        }
        if (currentUser && currentUser.role.name === 'Student') {
          this.store.dispatch(new GetHomeworks());
        }
        this.store.dispatch(new CurrentCategories());
      },
      error: (_error) => {},
    });
  }

  @Action(SetPracticeToUser)
  setPracticeToUser(ctx: StateContext<UserStateModel>, payload) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      user: {
        ..._state.user,
        practice: payload.practice_id,
      },
      users: _state.users.map((user) =>
        user._id === _state.user._id ? { ...user, practice: payload.practice_id } : user,
      ),
    });

    this.userService.setUserPractice(payload.practice_id).subscribe({
      next: (_next) => {
        this.store.dispatch(new CurrentCategories());
      },
      error: (_error) => {},
    });
  }

  @Action(AddUserToCurrentUsers)
  addUserToCurrentUsers(ctx: StateContext<UserStateModel>, payload) {
    this.userService.addUser(payload.user).subscribe({
      next: (newUser) => {
        const _state = ctx.getState();
        ctx.patchState({
          ..._state,
          users: [..._state.users, newUser],
        });
      },
    });
    ctx.dispatch(new ModalCloseAction());
  }

  @Action(SetUserLanguage)
  setUserLanguage(ctx: StateContext<UserStateModel>, payload) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      user: { ..._state.user, language: payload.language },
    });
  }

  @Action(CheckUserSchoolLicense)
  checkUserSchoolLicense(ctx: StateContext<UserStateModel>) {
    const _state = ctx.getState();
    // TODO License
    // // @ts-ignore  Check if user is not admin
    // if (_state.user.role?._id == '6301e162029cc9403c26de0c') {
    //   return;
    // }
    //
    // if (!_state.user?.school?.license?.active) {
    //   this.store.dispatch(new ModalOpenAction('license-expired', false));
    // }
    //
    // if (!_state.user.license) {
    //   this.store.dispatch(new ModalOpenAction('no-teacher', false));
    //   return;
    // }
  }
}
