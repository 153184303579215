import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UiTooltipModule } from '../ui-tooltip/ui-tooltip.module';
import { UiAppNoDataModule } from '../ui-app-no-data/ui-app-no-data.module';
import { PipesModule } from '../../app.pipes.module';
import { UiPageHomeworkComponentDetails } from "./ui-page-homework-details.component";

@NgModule({
  declarations: [UiPageHomeworkComponentDetails],
  exports: [UiPageHomeworkComponentDetails],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: UiPageHomeworkComponentDetails,
      },
    ]),
    UiTooltipModule,
    UiAppNoDataModule,
    PipesModule,
  ],
})
export class UiPageHomeworkDetailsModule {}
