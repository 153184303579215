import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiControlLicenseEditComponent} from "./ui-control-license-edit.component";
import { UiDatePickerModule } from "../ui-date-picker/ui-date-picker.module";
import { AppTranslocoModule } from "../../app.transloco.module";



@NgModule({
  declarations: [UiControlLicenseEditComponent],
  exports: [UiControlLicenseEditComponent],
  imports: [
    CommonModule,
    UiDatePickerModule,
    AppTranslocoModule
  ]
})
export class UiControlLicenseEditModule { }
