<div class="ui-forgot-container">
  <div class="ui-forgot-brand">
    <img src="assets/images/logo_line_dark.svg" alt="{{'alts.logo' | transloco}}">
  </div>
  <div class="ui-forgot-info">
    <h1>Forgot password</h1>
    <span>Enter your registered email to send instructions to restore access</span>
  </div>
  <div class="ui-forgot-data">
    <div class="ui-forgot-form ui-form">
      <div class="ui-form-input-group">
        <div class="ui-form-input" [class.ui-form-input-error]="interface._cache.error_fields.includes('email')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" class="h-6 w-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
          </svg>
          <input name="login" type="text" placeholder="Enter your email"  [value]="interface.user.email" (keyup)="actionEnterLogin($event)">
        </div>
      </div>
      <button class="ui-btn ui-btn-lg ui-btn-pap justify-center" [disabled]="!actionGrantForgot() || interface.submit" (click)="actionForgot()">
        <svg *ngIf="interface.submit" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 animate-spin" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <svg *ngIf="!interface.submit" xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='1.5'>
          <path stroke-linecap='round' stroke-linejoin='round' d='M5 13l4 4L19 7' />
        </svg>
        {{'buttons.confirm_forgot' | transloco}}
      </button>
      <div class="ui-form-text ui-form-text-xs">
        Back to <span class="ui-form-link" [routerLink]="'/auth'">sign in</span> form
      </div>
    </div>
  </div>
</div>
<div class="ui-forgot-container-footer">
  <div class="ui-forgot-footer-menu">
    <a href="https://argoprep.com/privacy-policy">{{'menu.privacy-policy' | transloco}}</a>
    <a href="https://argoprep.com/cookie-policy">{{'menu.cookie-policy' | transloco}}</a>
  </div>
  <div class="ui-forgot-copyright">{{'copyright.text' | transloco}} © 2022 <a href="https://argoprep.com" target="_blank">ArgoPrep | Argo Brothers Inc</a>. {{'copyright.rights' | transloco}}.</div>
</div>

