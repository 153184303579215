import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalCloseAction } from '../../_stores/modal/_actions/modal.actions';
import { Store } from '@ngxs/store';
import { ModalState } from '../../_stores/modal/_state/modal.state';
import { HttpClient } from '@angular/common/http';
import { DeleteControlUser } from '../../_stores/control/_actions/control.actions';

@Component({
  selector: 'ui-control-user-delete',
  templateUrl: './ui-control-user-delete.component.html',
  styleUrls: ['./ui-control-user-delete.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiControlUserDeleteComponent implements OnInit {
  interface: any = {
    loading: false,
    _id: null,
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private http: HttpClient) {}

  ngOnInit(): void {
    this.initDataID();
  }

  initDataID() {
    this.store.select(ModalState.selectOpenedID).subscribe((_openedID) => {
      this.interface._id = _openedID;
      this.cdr.detectChanges();
    });
  }

  actionConfirm(): void {
    this.interface.loading = true;
    this.store.dispatch(new DeleteControlUser({ userID: this.interface._id })).subscribe({
      next: () => {
        this.cdr.detectChanges();
      },
    });
  }

  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }
}
