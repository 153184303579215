import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiPageBooksComponent} from "./ui-page-books.component";
import {RouterModule} from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { BooksState } from "../../_stores/books/_state/books.state";
import { LazyLoadImageModule } from "ng-lazyload-image";
import {PipesModule} from "../../app.pipes.module";
import {UiAppNoDataModule} from "../ui-app-no-data/ui-app-no-data.module";



@NgModule({
  declarations: [UiPageBooksComponent],
  exports: [UiPageBooksComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: UiPageBooksComponent
      }
    ]),
    LazyLoadImageModule,
    PipesModule,
    UiAppNoDataModule
  ]
})
export class UiPageBooksModule { }
