import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { mergeMap, Observable, of, take } from 'rxjs';
import { Store } from '@ngxs/store';
import { PracticeState } from '../../_stores/practice/_state/practice.state';
import { WorksheetsService } from '../../_stores/worksheets/_service/worksheets.service';
import { SetCrumbs } from '../../_stores/crumbs/_actions/crumbs.actions';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})
export class UiPageWorksheetResolver implements Resolve<boolean> {
  constructor(
    private store: Store,
    private worksheetsService: WorksheetsService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const worksheetId = route.paramMap.get("id");
    return this.store.select(PracticeState.selectCategoryById(worksheetId)).pipe(take(1),
      mergeMap((data: any) => {
        if (data !== undefined) {
          this.store.dispatch(new SetCrumbs({ title: data.title, subtitle: data.description }));
          return of(data);
        }
        {
          return this.worksheetsService.getWorksheetById(worksheetId).pipe(map((data: any) => {
            this.store.dispatch(new SetCrumbs({ title: data.title, subtitle: data.description }));
            return data;
          }));
        }
      }));
  }
}
