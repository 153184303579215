import { ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { VideosModel } from '../../_models/videos.model';
import { VideoModel } from '../../_models/video.model';
import { UserState } from '../../_stores/user/_state/user.state';
import { Store } from '@ngxs/store';
import {
  AssignmentsBuilderAddVideo,
  AssignmentsBuilderRemoveVideo,
} from '../../_stores/assignments-builder/_actions/assignments-builder.actions';
import { ActivatedRoute } from '@angular/router';
import { AssignmentBuilderService } from '../../_utils/assignment-builder-service';

@Component({
  selector: 'ui-videos',
  templateUrl: './ui-videos.component.html',
  styleUrls: ['./ui-videos.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiVideosComponent implements OnInit {
  interface: VideosModel = {
    checked_video_list: [],
    is_selected: false,
    materialId: '',
    block: {
      assignment: true,
      title: null,
      subtitle: null,
      tree: false,
    },
    videos: [],
    video_list: [],
    _cache: {
      video: undefined,
      video_index: 0,
    },
    user: undefined,
  };

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private assignmentBuilderService: AssignmentBuilderService,
  ) {}

  @Input('title') set _title(title) {
    if (title) {
      this.interface.block.title = title;
    }
  }

  @Input('subtitle') set _subtitle(subtitle) {
    if (subtitle) {
      this.interface.block.subtitle = subtitle;
    }
  }

  @Input('tree') set _tree(tree: boolean) {
    if (tree) {
      this.interface.block.tree = tree;
    }
  }

  @Input('assignment') set _assignment(assignment: boolean) {
    if (assignment !== undefined) {
      this.interface.block.assignment = assignment;
    }
  }

  @Input('videos') set _videos(videos) {
    if (videos) {
      this.interface.videos = videos;
      this.interface.video_list = this.createVideoListByTree(this.interface.videos, []);
      this.actionSelectVideoByIndex(0);
    }
  }

  ngOnInit(): void {
    this.interface.materialId = this.route.snapshot.paramMap.get('id');
    this.initUser();
  }

  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.cdr.detectChanges();
    });
  }

  createVideoListByTree(tree, videoList): VideoModel[] | any[] {
    if (!this.interface.block.tree) {
      return this.interface.videos;
    }
    tree.forEach((subject) => {
      if (subject.videos?.length > 0) {
        subject.videos.forEach((video) => {
          videoList.push({ ...video, parentName: subject.title });
        });
      } else if (subject.children !== undefined) {
        videoList = this.createVideoListByTree(subject.children, videoList);
      }
    });
    return videoList;
  }

  actionSelectVideoByIndex(index): void {
    this.interface._cache.video_index = index;
    this.interface._cache.video = this.interface.video_list[index];
  }

  actionOpenVideo(video): void {
    this.interface._cache.video = video;
    document.querySelector('#videoplayer')?.scrollIntoView({ behavior: 'smooth' });
  }

  actionSelectToAssign() {
    this.interface.is_selected = !this.interface.is_selected;
    this.cdr.detectChanges();
  }

  actionAddToAssign(item: VideoModel) {
    if (!this.interface.checked_video_list.find((value) => value._id === item._id)) {
      this.interface.checked_video_list.push(item);
      this.cdr.detectChanges();
      item = Object.assign({}, item);
      item = Object.assign(item,{materials: "video"}, );
      this.store.dispatch(
        new AssignmentsBuilderAddVideo({ video: this.interface.videos.filter((value) => value._id === item._id) }),
      );
      this.assignmentBuilderService.materials.push(item);
    } else {
      this.interface.checked_video_list = this.interface.checked_video_list.filter((value) => value._id !== item._id);
      this.store.dispatch(
        new AssignmentsBuilderRemoveVideo({ video: this.interface.videos.filter((value) => value._id === item._id) }),
      );
      this.assignmentBuilderService.materials.filter((value) => value._id !== item._id);
      this.cdr.detectChanges();
    }
  }

  isChecked(item: VideoModel) {
    return !!this.interface.checked_video_list.find((value) => value._id === item._id);
  }
}
