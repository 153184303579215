export class CreateSchool {
  public static readonly type = '[Schools] Create School';
  constructor(public payload: { data }) {}
}

export class EditSchool {
  public static readonly type = '[Schools] Edit School';
  constructor(public payload: { data }) {}
}

export class DeleteSchool {
  public static readonly type = '[Schools] Delete School';
  constructor(public payload: { schoolID: string }) {}
}

export class GetSchools {
  public static readonly type = '[Schools] Get Schools';
  constructor() {}
}

export class GetSchool {
  public static readonly type = '[Schools] Get School';
  constructor(public schoolId: string) {}
}
