import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiStudentDeleteComponent } from './ui-student-delete.component';
import { UiAppNoDataModule } from '../ui-app-no-data/ui-app-no-data.module';

@NgModule({
  declarations: [UiStudentDeleteComponent],
  exports: [UiStudentDeleteComponent],
  imports: [CommonModule, UiAppNoDataModule],
})
export class UiStudentDeleteModule {}
