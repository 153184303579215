import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiQuizResultComponent } from "./ui-quiz-result.component";
import { UiAppNoDataModule } from "../ui-app-no-data/ui-app-no-data.module";
import { KatexModule } from "ng-katex";
import { PipesModule } from "../../app.pipes.module";
import { UiTooltipModule } from "../ui-tooltip/ui-tooltip.module";



@NgModule({
  declarations: [UiQuizResultComponent],
  exports: [UiQuizResultComponent],
  imports: [
    CommonModule,
    UiAppNoDataModule,
    KatexModule,
    PipesModule,
    UiTooltipModule
  ]
})
export class UiQuizResultModule { }
