import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiPageClassComponent } from './ui-page-class.component';
import { RouterModule } from '@angular/router';
import { UiAppNoDataModule } from '../../ui-app-no-data/ui-app-no-data.module';
import { UiTooltipModule } from '../../ui-tooltip/ui-tooltip.module';
import { PipesModule } from "../../../app.pipes.module";
import { UiAssignmentModule } from "../../ui-assignment/ui-assignment.module";

@NgModule({
  declarations: [UiPageClassComponent],
  exports: [UiPageClassComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: UiPageClassComponent
      }
    ]),
    UiAppNoDataModule,
    UiTooltipModule,
    PipesModule,
    UiAssignmentModule
  ]
})
export class UiPageClassModule {}
