import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalCloseAction } from '../../_stores/modal/_actions/modal.actions';
import { Store } from '@ngxs/store';
import { CreateSchool } from '../../_stores/schools/_actions/school.actions';

@Component({
  selector: 'ui-control-schools-add',
  templateUrl: './ui-control-schools-add.component.html',
  styleUrls: ['./ui-control-schools-add.component.scss'],
})
export class UiControlSchoolsAddComponent implements OnInit {
  public interface = {
    _cache: {
      schoolDistrict: '',
      schoolName: '',
      schoolLevel: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      ncesId: '',
    },
    _cache_error: { fields: '   ' },
    loading: false,
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }

  actionEnterSchoolDistrict($event: KeyboardEvent) {
    // @ts-ignore
    this.interface._cache.schoolDistrict = $event.target.value;
    this.interface._cache.schoolDistrict.trim();
    this.actionValidateFields();
  }

  actionEnterSchoolName($event: KeyboardEvent) {
    // @ts-ignore
    this.interface._cache.schoolName = $event.target.value;
    this.interface._cache.schoolDistrict.trim();
    this.actionValidateFields();
  }

  actionEnterSchoolLevel($event: KeyboardEvent) {
    // @ts-ignore
    this.interface._cache.schoolLevel = $event.target.value;
    this.interface._cache.schoolDistrict.trim();
    this.actionValidateFields();
  }

  actionEnterAddress($event: KeyboardEvent) {
    // @ts-ignore
    this.interface._cache.address = $event.target.value;
    this.interface._cache.schoolDistrict.trim();
    this.actionValidateFields();
  }

  actionEnterCity($event: KeyboardEvent) {
    // @ts-ignore
    this.interface._cache.city = $event.target.value;
    this.interface._cache.schoolDistrict.trim();
    this.actionValidateFields();
  }

  actionEnterState($event: KeyboardEvent) {
    // @ts-ignore
    this.interface._cache.state = $event.target.value;
    this.interface._cache.schoolDistrict.trim();
    this.actionValidateFields();
  }

  actionEnterZip($event: KeyboardEvent) {
    // @ts-ignore
    this.interface._cache.zip = $event.target.value;
    this.interface._cache.schoolDistrict.trim();
    this.actionValidateFields();
  }

  actionEnterNcesId($event: KeyboardEvent) {
    // @ts-ignore
    this.interface._cache.ncesId = $event.target.value;
    this.actionValidateFields();
  }

  actionValidateFields() {
    this.interface._cache_error.fields = '';
    Object.keys(this.interface._cache).map((item) => {
      if (!!this.interface._cache[item]) {
      } else {
        this.interface._cache_error.fields = this.interface._cache_error.fields.concat(item.toString());
      }
    });
  }

  actionAddSchool() {
    this.interface.loading = true;
    this.store.dispatch(new CreateSchool({ data: this.interface._cache })).subscribe({
      next: () => {
        this.interface.loading = false;
        this.actionClose();
        this.cdr.detectChanges();
      },
    });
  }
}
