<div class="ui-homework">
  <div class="ui-homework-header">
    <div class="ui-homework-title" (click)="actionOpenHomeworkPage()">
      <h4>{{interface.homework.title}}</h4>
    </div>
    <div class="ui-homework-class">{{interface.homework?.class?.title}}</div>
    <div class="ui-homework-date">You edited on {{interface.homework?.dates?.dueDate | date}}</div>
  </div>
  <div class="ui-homework-body">
    <div class="ui-homework-info mb-4" [class.ui-homework-info-error]="(interface.homework?.dates?.dueDate | agoDate) < 0"
         [class.ui-homework-info-attention]="((interface.homework?.dates?.dueDate | agoDate) >= 0 && (interface.homework?.dates?.dueDate | agoDate) < 1)"
         [class.ui-homework-info-success]="interface.homework?.dates?.dueDate === null">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <label>
        <ng-container>{{interface.homework?.dates?.dueDate === null ? 'without a deadline' : interface.homework?.dates?.dueDate | agoDate:true}}</ng-container>
        <ng-container *ngIf="(interface.homework?.dates?.dueDate | agoDate) < 0"> days past due</ng-container>
        <ng-container *ngIf="interface.homework?.dates?.dueDate !== null && (interface.homework?.dates?.dueDate | agoDate) >= 0"> days
          left
        </ng-container>
      </label>
    </div>
    <div class="ui-homework-info">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
      </svg>
      <p>Due:</p>
      <span>{{interface.homework?.dates?.dueDate | date}}</span>
    </div>
    <div class="ui-homework-info">
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.795 21.0001H5C4.46957 21.0001 3.96086 20.7893 3.58579 20.4143C3.21071 20.0392 3 19.5305 3 19.0001V7.00006C3 6.46963 3.21071 5.96092 3.58579 5.58585C3.96086 5.21077 4.46957 5.00006 5 5.00006H17C17.5304 5.00006 18.0391 5.21077 18.4142 5.58585C18.7893 5.96092 19 6.46963 19 7.00006V11.0001"
          stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M18 22.0001C20.2091 22.0001 22 20.2092 22 18.0001C22 15.7909 20.2091 14.0001 18 14.0001C15.7909 14.0001 14 15.7909 14 18.0001C14 20.2092 15.7909 22.0001 18 22.0001Z"
          stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M15 3.00006V7.00006" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
        <path d="M7 3.00006V7.00006" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
        <path d="M3 11.0001H19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
        <path d="M18 16.496V18L19 19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
      </svg>
      <p>Scheduled:</p>
      <span>{{interface.homework?.dates?.scheduleDate | date}}</span>
    </div>
    <div class="ui-homework-info">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
           stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
              d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
      </svg>
      <p>Number of materials:</p>
      <span><strong>{{interface.homework?.materials?.length}}</strong></span>
    </div>
    <div class="ui-homework-info">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
           stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
              d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
              d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
      </svg>
      <p>Total progress:</p>
      <span><strong>{{getTotalProgress()}}</strong>  %</span>
    </div>
  </div>
  <div class="ui-homework-footer">
    <div class="ui-homework-cup">
      <img src="assets/images/shapes/trophy.png" loading="lazy" alt="">
      <strong>{{getTotalDone()}} / {{interface.homework?.materials?.length}}</strong>
      <span>Done</span>
    </div>
    <div class="ui-homework-students">
      <ng-container *ngFor="let student of interface.homework?.students | slice: 0:3; let s=index">
        <div class="ui-homework-student">
          <img src="{{student.avatar}}" alt="" tooltip />
        </div>
      </ng-container>
    </div>
  </div>
</div>
