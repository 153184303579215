import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiControlGradeDeleteComponent } from './ui-control-grade-delete.component';

@NgModule({
  declarations: [UiControlGradeDeleteComponent],
  exports: [UiControlGradeDeleteComponent],
  imports: [CommonModule],
})
export class UiControlGradeDeleteModule {}
