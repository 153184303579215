import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ModalState } from '../../_stores/modal/_state/modal.state';
import { ModalCloseAction } from '../../_stores/modal/_actions/modal.actions';
import { DeleteControlRoles, LoadControlRoles } from '../../_stores/control/_actions/control.actions';

@Component({
  selector: 'ui-control-role-delete',
  templateUrl: './ui-control-role-delete.component.html',
  styleUrls: ['./ui-control-role-delete.component.scss'],
})
export class UiControlRoleDeleteComponent implements OnInit {
  interface: any = {
    _id: null,
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {
    this.store.dispatch(new LoadControlRoles());
  }

  ngOnInit(): void {
    this.initDataID();
  }

  initDataID() {
    this.store.select(ModalState.selectOpenedID).subscribe((_openedID) => {
      this.interface._id = _openedID;
      this.cdr.detectChanges();
    });
  }

  actionConfirm(): void {
    this.store.dispatch(new DeleteControlRoles({ roleID: this.interface._id })).subscribe({
      next: () => {
        this.interface.loading = false;
        this.store.dispatch(new ModalCloseAction());
        this.cdr.detectChanges();
      },
      error: () => {
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
    });
  }

  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }
}
