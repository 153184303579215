import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiControlSchoolsAddComponent } from "./ui-control-schools-add.component";
import { TranslocoModule } from "@ngneat/transloco";



@NgModule({
  declarations: [UiControlSchoolsAddComponent],
  exports:[UiControlSchoolsAddComponent],
  imports: [
    CommonModule,
    TranslocoModule
  ]
})
export class UiControlSchoolsAddModule { }
