import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiProfileBindComponent } from "./ui-profile-bind.component";
import { UiTooltipModule } from "../ui-tooltip/ui-tooltip.module";



@NgModule({
  declarations: [UiProfileBindComponent],
  exports: [UiProfileBindComponent],
  imports: [
    CommonModule,
    UiTooltipModule
  ]
})
export class UiProfileBindModule { }
