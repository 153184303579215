import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiPageQuestionsComponent} from "./ui-page-questions.component";
import {RouterModule} from "@angular/router";
import {PipesModule} from "../../app.pipes.module";
import {KatexModule} from "ng-katex";
import {UiTooltipModule} from "../ui-tooltip/ui-tooltip.module";
import { UiQuizModule } from "../ui-quiz/ui-quiz.module";



@NgModule({
  declarations: [UiPageQuestionsComponent],
  exports: [UiPageQuestionsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: UiPageQuestionsComponent,
        children: []
      }
    ]),
    PipesModule,
    KatexModule,
    UiTooltipModule,
    UiQuizModule
  ]
})
export class UiPageQuestionsModule { }
