import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiStudentEditComponent } from './ui-student-edit.component';
import { UiAppNoDataModule } from '../ui-app-no-data/ui-app-no-data.module';
import { TranslocoModule } from '@ngneat/transloco';
import { UiTooltipModule } from "../ui-tooltip/ui-tooltip.module";
import { PipesModule } from "../../app.pipes.module";

@NgModule({
  declarations: [UiStudentEditComponent],
  exports: [UiStudentEditComponent],
  imports: [CommonModule, UiAppNoDataModule, TranslocoModule, UiTooltipModule, PipesModule]
})
export class UiStudentEditModule {}
