import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { ModalState } from '../../_stores/modal/_state/modal.state';
import { ModalCloseAction, ModalOpenAction } from '../../_stores/modal/_actions/modal.actions';
import { UiModalModel } from '../../_models/ui-modal.model';
import { TranslocoService } from '@ngneat/transloco';
import { SetUserLanguage } from '../../_stores/user/_actions/user.actions';
import { UserState } from '../../_stores/user/_state/user.state';
import { AuthLogout } from '../../_stores/auth/_actions/auth.actions';
import { EventChangeLanguage, EventOpenModalChangeLanguage } from "../../_stores/event_ws/_actions/events.actions";

@Component({
  selector: 'ui-modal',
  templateUrl: './ui-modal.component.html',
  styleUrls: ['./ui-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiModalComponent implements OnInit {
  interface: UiModalModel = {
    class: undefined,
    status: false,
    type: undefined,
    no_close: false,
    user: undefined,
    users: [],
    video_src: null,
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private transloco: TranslocoService) {}

  ngOnInit(): void {
    this.initModalStatus();
    this.initUser();
  }

  initModalStatus(): void {
    this.store.select(ModalState.selectStatus).subscribe((res: any) => {
      if(!res) {
        return;
      }
      this.interface.status = res.status;
      this.interface.type = res.type;
      this.interface.no_close = !res.close;
      this.interface.video_src = res.video_src;
      if (this.interface.status) {
        document.body.classList.add('ui-modal-open');
      } else {
        document.body.classList.remove('ui-modal-open');
      }

      if(this.interface.type == 'language_select') {
        this.store.dispatch(new EventOpenModalChangeLanguage());
      }

      this.cdr.detectChanges();
    });
  }
  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.cdr.detectChanges();
    });
  }

  actionChangeLanguage(lang): void {
    if (this.transloco.getActiveLang() != lang) {
      this.store.dispatch(new EventChangeLanguage({ change: "ChangeLanguage" + this.transloco.getActiveLang().toUpperCase() + "to" + lang.toUpperCase() }));
    }
    this.transloco.setActiveLang(lang);
    this.store.dispatch(new SetUserLanguage(lang));
    this.store.dispatch(new ModalCloseAction());
  }
  actionOpenProfileSelect(): void {
    this.store.dispatch(new ModalOpenAction('profile_select', false));
  }

  actionCloseModal(e?): void {
    if (this.interface.no_close) {
      return;
    }
    if (!e || (e && e.target.className === 'ui-modal')) {
      this.store.dispatch(new ModalCloseAction());
    }
  }
  actionLogout(): void {
    this.store.dispatch(new AuthLogout());
  }
  actionExit(): void {
    this.store.dispatch(new ModalCloseAction());
    this.store.dispatch(new AuthLogout());
  }
}
