import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  ClearControlDashboardSchoolData,
  CreateControlGrade,
  CreateControlRole,
  DeleteControlGrade,
  DeleteControlRoles,
  DeleteControlSchoolsLicense,
  DeleteControlUser,
  EditControlGrade,
  EditControlRoles,
  LoadControlBooks,
  LoadControlCategories,
  LoadControlDashboardData,
  LoadControlDashboardSchoolData,
  LoadControlGrades,
  LoadControlQuestions,
  LoadControlRoles,
  LoadControlSchools,
  LoadControlSchoolsLicense,
  LoadControlUserData,
  LoadControlUsers,
  LoadControlVideos,
  LoadControlWorksheets,
  UpdateControlUsersPage,
} from '../_actions/control.actions';
import { ControlModel } from '../../../_models/control.model';
import { ControlService } from '../_service/control.service';
import { ModalState } from '../../modal/_state/modal.state';
import { ModalModel } from '../../../_models/modal.model';
import { EventUserDelete } from '../../event_ws/_actions/events.actions';
import { ModalCloseAction } from '../../modal/_actions/modal.actions';

export const _ControlDefault: ControlModel = {
  books: [],
  categories: {
    currentPage: 1,
    data: [],
    perPage: 0,
    totalData: 0,
    totalPage: 0,
  },
  grades: [],
  license: [],
  questions: {
    currentPage: 1,
    data: [],
    perPage: 0,
    totalData: 0,
    totalPage: 0,
  },
  roles: [],
  schools: {
    currentPage: 1,
    data: [],
    perPage: 0,
    totalData: 0,
    totalPage: 0,
  },
  users: {
    currentPage: 1,
    data: [],
    perPage: 0,
    totalData: 0,
    totalPage: 0,
  },
  videos: {
    currentPage: 1,
    data: [],
    perPage: 0,
    totalData: 0,
    totalPage: 0,
  },
  worksheets: {
    currentPage: 1,
    data: [],
    perPage: 0,
    totalData: 0,
    totalPage: 0,
  },
  dashboard: undefined,
  school: undefined,
  user: undefined,
};

@State<ControlModel>({
  name: 'LAP_CONTROL',
  defaults: _ControlDefault,
})
@Injectable()
export class ControlState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {
  constructor(private store: Store, private controlService: ControlService) {}

  @Selector()
  static selectState(state: ControlModel) {
    return state;
  }

  @Selector()
  static selectUsers(state: ControlModel) {
    return state.users;
  }

  @Selector()
  static selectRoles(state: ControlModel) {
    return state.roles;
  }

  @Selector()
  static selectGrades(state: ControlModel) {
    return state.grades;
  }

  @Selector()
  static selectCategories(state: ControlModel) {
    return state.categories;
  }

  @Selector()
  static selectQuestions(state: ControlModel) {
    return state.questions;
  }

  @Selector()
  static selectVideos(state: ControlModel) {
    return state.videos;
  }

  @Selector()
  static selectWorksheets(state: ControlModel) {
    return state.worksheets;
  }

  @Selector()
  static selectBooks(state: ControlModel) {
    return state.books;
  }

  @Selector()
  static selectSchools(state: ControlModel) {
    return state.schools;
  }

  @Selector()
  static selectSchoolsLicense(state: ControlModel) {
    return state.license;
  }

  @Selector([ModalState])
  static selectEditedLicense(state: ControlModel, modal: ModalModel) {
    return state.license.find((data) => data.license._id === modal._id);
  }

  @Selector([ModalState])
  public static selectRoleByModalId(state: ControlModel, modal: ModalModel) {
    return state.roles.find((_role) => _role._id == modal._id);
  }

  @Selector([ModalState])
  public static selectGradeByModalId(state: ControlModel, modal: ModalModel) {
    return state.grades.find((_grade) => _grade._id == modal._id);
  }

  @Selector()
  static selectDashboard(state: ControlModel) {
    return state.dashboard;
  }

  @Selector()
  static selectSchool(state: ControlModel) {
    return state.school;
  }

  @Selector()
  static selectUser(state: ControlModel) {
    return state.user;
  }

  ngxsAfterBootstrap(ctx?: StateContext<ControlModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      users: {
        ...state.users,
        currentPage: 1,
      },
    });
  }

  ngxsOnChanges(change: NgxsSimpleChange<ControlModel>): void {}

  ngxsOnInit(ctx?: StateContext<ControlModel>): any {}

  @Action(LoadControlUsers)
  loadControlUsers(ctx: StateContext<ControlModel>, payload) {
    const state = ctx.getState();
    const _page = payload.page ? payload.page : state.users.currentPage;
    this.controlService.getUsersList(_page, 10, payload.role).subscribe((_users) => {
      ctx.patchState({
        users: _users,
      });
    });
  }
  @Action(UpdateControlUsersPage)
  updateControlUsersPage(ctx: StateContext<ControlModel>, payload) {
    const state = ctx.getState();
    ctx.patchState({
      users: {
        ...state.users,
        currentPage: payload.page,
      },
    });
  }

  @Action(LoadControlRoles)
  loadControlRoles(ctx: StateContext<ControlModel>) {
    this.controlService.getRolesList().subscribe((_roles) => {
      ctx.patchState({
        roles: _roles,
      });
    });
  }

  @Action(CreateControlRole)
  create(ctx: StateContext<ControlModel>, { payload }) {
    this.controlService.createRole(payload.data).subscribe({
      next: (res) => {
        const state = ctx.getState();
        ctx.patchState({
          roles: [res, ...state.roles],
        });
        ctx.dispatch(new LoadControlRoles());
      },
      error: () => {},
      complete: () => {},
    });
  }

  @Action(EditControlRoles)
  edit(ctx: StateContext<ControlModel>, { payload }) {
    return this.controlService.editRole(payload.data).subscribe({
      next: () => {
        const state = ctx.getState();
        ctx.dispatch(new LoadControlRoles());
        ctx.setState({
          ...state,
          roles: state.roles,
        });
      },
      error: () => {},
      complete: () => {},
    });
  }

  @Action(DeleteControlRoles)
  deleteRole({ getState, setState }: StateContext<ControlModel>, { payload }: any) {
    const state = getState();
    return this.controlService.deleteRole(payload.roleID).subscribe({
      next: (res) => {
        setState({
          ...state,
          roles: state.roles.filter((item) => item._id !== res._id),
        });
      },
      error: () => {},
      complete: () => {},
    });
  }

  @Action(DeleteControlUser)
  deleteUser(ctx: StateContext<ControlModel>, { payload }: any) {
    return this.controlService.deleteUser(payload.userID).subscribe({
      next: () => {
        ctx.dispatch(new EventUserDelete({ userID: payload.userID }));
        ctx.dispatch(new ModalCloseAction());
        ctx.dispatch(new LoadControlUsers());
      },
      error: () => {},
      complete: () => {},
    });
  }

  @Action(LoadControlGrades)
  loadControlGrades(ctx: StateContext<ControlModel>) {
    this.controlService.getGradesList().subscribe((_grades) => {
      ctx.patchState({
        grades: _grades,
      });
    });
  }

  @Action(CreateControlGrade)
  createGrade(ctx: StateContext<ControlModel>, { payload }) {
    this.controlService.createGrade(payload.data).subscribe({
      next: (res) => {
        const state = ctx.getState();
        ctx.patchState({
          grades: [res, ...state.grades],
        });
        ctx.dispatch(new LoadControlRoles());
      },
      error: () => {},
      complete: () => {},
    });
  }

  @Action(EditControlGrade)
  editGrade(ctx: StateContext<ControlModel>, { payload }) {
    console.log('editGrade payload: ', payload);
    return this.controlService.editGrade(payload.data).subscribe({
      next: () => {
        const state = ctx.getState();
        ctx.setState({
          ...state,
          grades: state.grades,
        });
        ctx.dispatch(new LoadControlGrades());
      },
      error: () => {},
      complete: () => {},
    });
  }

  @Action(DeleteControlGrade)
  deleteGrade({ getState, setState }: StateContext<ControlModel>, { payload }: any) {
    const state = getState();
    return this.controlService.deleteGrade(payload.gradeID).subscribe({
      next: (res) => {
        setState({
          ...state,
          grades: state.grades.filter((item) => item._id !== res._id),
        });
      },
      error: () => {},
      complete: () => {},
    });
  }

  @Action(LoadControlCategories)
  loadControlCategories(ctx: StateContext<ControlModel>, payload) {
    this.controlService.getCategoriesList(payload.page, 10).subscribe((_categories) => {
      ctx.patchState({
        categories: _categories,
      });
    });
  }

  @Action(LoadControlQuestions)
  loadControlQuestions(ctx: StateContext<ControlModel>, payload) {
    this.controlService.getQuestionsList(payload.page, 10).subscribe((_questions) => {
      ctx.patchState({
        questions: _questions,
      });
    });
  }

  @Action(LoadControlVideos)
  loadControlVideos(ctx: StateContext<ControlModel>, payload) {
    this.controlService.getVideoList(payload.page, 10).subscribe((_videos) => {
      ctx.patchState({
        videos: _videos,
      });
    });
  }

  @Action(LoadControlWorksheets)
  loadControlWorksheets(ctx: StateContext<ControlModel>, payload) {
    this.controlService.getWorksheetsList(payload.search, payload.page, payload.grade).subscribe((_worksheets) => {
      ctx.patchState({
        worksheets: _worksheets,
      });
    });
  }

  @Action(LoadControlBooks)
  loadControlBooks(ctx: StateContext<ControlModel>) {
    this.controlService.getBooksList().subscribe((_books) => {
      ctx.patchState({
        books: _books,
      });
    });
  }

  @Action(LoadControlSchools)
  loadControlSchools(ctx: StateContext<ControlModel>, payload) {
    this.controlService.getSchoolsList(payload.search, payload.page, payload.sort).subscribe((_schools) => {
      ctx.patchState({
        schools: _schools,
      });
    });
  }

  @Action(LoadControlSchoolsLicense)
  loadControlSchoolsLicense(ctx: StateContext<ControlModel>) {
    this.controlService.getSchoolsLicenseList().subscribe((_license) => {
      ctx.patchState({
        license: _license,
      });
    });
  }

  @Action(DeleteControlSchoolsLicense)
  deleteControlSchoolsLicense(ctx: StateContext<ControlModel>, payload) {
    this.controlService.deleteSchoolsLicense(payload._id).subscribe((_license) => {
      ctx.dispatch(new LoadControlSchoolsLicense());
    });
  }

  @Action(LoadControlDashboardData)
  loadControlDashboardData(ctx: StateContext<ControlModel>) {
    this.controlService.getDashboardData().subscribe((_data) => {
      ctx.patchState({
        dashboard: _data,
      });
    });
  }

  @Action(LoadControlDashboardSchoolData)
  loadControlDashboardSchoolData(ctx: StateContext<ControlModel>, payload) {
    this.controlService.getDashboardSchoolData(payload.schoolID).subscribe((_data) => {
      ctx.patchState({
        school: _data,
      });
    });
  }

  @Action(ClearControlDashboardSchoolData)
  clearControlDashboardSchoolData(ctx: StateContext<ControlModel>) {
    ctx.patchState({
      school: null,
    });
  }

  @Action(LoadControlUserData)
  loadControlUserData(ctx: StateContext<ControlModel>, payload) {
    this.controlService.getDetailUserData(payload._id).subscribe((_data) => {
      ctx.patchState({
        user: _data,
      });
    });
  }
}
