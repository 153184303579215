import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiPageVideoComponent} from "./ui-page-video.component";
import {RouterModule} from "@angular/router";
import {PipesModule} from "../../app.pipes.module";
import {UiVideosModule} from "../ui-videos/ui-videos.module";
import {UiBooksModule} from "../ui-books/ui-books.module";



@NgModule({
  declarations: [UiPageVideoComponent],
  exports: [UiPageVideoComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: UiPageVideoComponent,
        children: [],
      }
    ]),
    PipesModule,
    UiVideosModule,
    UiBooksModule,
  ]
})
export class UiPageVideoModule { }
