<div class="ui-page-header">
  <div class="ui-page-menu-action" *ngIf="!interface.app?.sidebar_show">
    <button (click)="actionToggleSidebar()" tooltip description="Show sidebar">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
        <path d="M4 6H20M4 12H12M4 18H20"  stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.5 14L20 11.5L17.5 9" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  </div>
  <div class="ui-page-header-school" *ngIf="interface.user?.school">
    <div class="ui-page-header-school-item">
      <div class="ui-page-header-school-icon">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
        </svg>
      </div>
      <div class="ui-page-header-school-data">
        <p>{{interface.user?.school?.schoolName}}</p>
        <span>{{interface.user?.school?.address}}</span>
      </div>
    </div>
    <div class="ui-page-header-school-item">
      <div class="ui-page-header-school-icon" [class.ui-page-header-school-icon-error]="!interface.user?.school?.license?.active" [class.ui-page-header-school-icon-success]="interface.user?.school?.license?.active">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z" />
        </svg>
      </div>
      <div class="ui-page-header-school-data">
        <p>License</p>
        <span>
          <ng-container *ngIf="interface.user?.school?.license?.active">
            Expires on {{interface.user?.school?.license?.endDate | date:'longDate'}}
          </ng-container>
          <ng-container *ngIf="!interface.user?.school?.license?.active">
            Not licensed
          </ng-container>

        </span>
      </div>
    </div>
  </div>
  <div class="ui-page-header-invite">

  </div>
  <div class="ui-page-header-action">
    <button tooltip title="Support" description="If you have any questions or issues please email us at info@argoprep.com" position="left">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>
    </button>
  </div>
</div>
