import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { LoadControlSchoolsLicense } from '../../_stores/control/_actions/control.actions';
import { ControlState } from '../../_stores/control/_state/control.state';
import { ModalOpenAction, ModalOpenActionWithID } from '../../_stores/modal/_actions/modal.actions';
import { UserModel } from '../../_models/user.model';

@Component({
  selector: 'ui-control-schools-licenses',
  templateUrl: './ui-control-schools-licenses.component.html',
  styleUrls: ['./ui-control-schools-licenses.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiControlSchoolsLicensesComponent implements OnInit {
  interface: any = {
    data: [],
    _cash: {
      data: [],
    },
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {
    this.store.dispatch(new LoadControlSchoolsLicense());
  }

  ngOnInit(): void {
    this.initData();
  }

  initData(): void {
    this.store.select(ControlState.selectSchoolsLicense).subscribe((_data) => {
      this.interface.data = _data;
      this.interface._cash.data = _data;
      this.cdr.detectChanges();
    });
  }

  actionOpenAddDataModal(): void {
    this.store.dispatch(new ModalOpenAction('control_license_add'));
  }

  actionOpenEditDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('control_license_edit', _id));
  }

  actionOpenDeleteDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('control_license_delete', _id, false));
  }

  actionSearchLicense(event) {
    if (event.target.value && event.target.value.length > 0) {
      this.interface.data = this.interface.data.filter((item) => {
        return item.school.schoolName.toLowerCase().includes(event.target.value.toLowerCase());
      });
    } else {
      this.interface.data = this.interface._cash.data;
    }
  }
  getCountUsers(users: UserModel[]) {
    let argoprep_count = 0;
    let clever_count = 0;
    for (const user of users) {
      if (user.providerRegister && user.providerRegister.length > 0) {
        if (user.providerRegister.find((value) => value.name === 'argoprep' && value.active === true)) {
          argoprep_count++;
        }
        if (user.providerRegister.find((value) => value.name === 'clever' && value.active === true)) {
          clever_count++;
        }
      }
    }
    return { clever: clever_count, argoprep: argoprep_count };
  }
}
