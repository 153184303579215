import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiVideoComponent } from "./ui-video.component";
import { UiAppNoDataModule } from "../ui-app-no-data/ui-app-no-data.module";
import { UiVideoplayerModule } from "../ui-videoplayer/ui-videoplayer.module";



@NgModule({
  declarations: [UiVideoComponent],
  exports: [UiVideoComponent],
  imports: [
    CommonModule,
    UiAppNoDataModule,
    UiVideoplayerModule
  ]
})
export class UiVideoModule { }
