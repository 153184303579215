import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiControlStatisticComponent} from "./ui-control-statistic.component";
import {RouterModule} from "@angular/router";
import {UiControlUsersComponent} from "../ui-control-users/ui-control-users.component";
import {UiAppNoDataModule} from "../../_ui/ui-app-no-data/ui-app-no-data.module";
import {UiTooltipModule} from "../../_ui/ui-tooltip/ui-tooltip.module";
import {PipesModule} from "../../app.pipes.module";
import {AppTranslocoModule} from "../../app.transloco.module";



@NgModule({
  declarations: [UiControlStatisticComponent],
  exports: [UiControlStatisticComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: UiControlStatisticComponent,
        children: [],
      }
    ]),
    UiAppNoDataModule,
    UiTooltipModule,
    PipesModule,
    AppTranslocoModule,
  ]
})
export class UiControlStatisticModule { }
