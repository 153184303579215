import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiPageStudentsComponent } from './ui-page-students.component';
import { RouterModule } from '@angular/router';
import { UiTooltipModule } from '../ui-tooltip/ui-tooltip.module';
import { UiAppNoDataModule } from '../ui-app-no-data/ui-app-no-data.module';
import { PipesModule } from "../../app.pipes.module";

@NgModule({
  declarations: [UiPageStudentsComponent],
  exports: [UiPageStudentsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: UiPageStudentsComponent,
        children: [
          {
            path: "**",
            component: UiPageStudentsComponent
          }
        ]
      }
    ]),
    UiTooltipModule,
    UiAppNoDataModule,
    PipesModule
  ]
})
export class UiPageStudentsModule {}
