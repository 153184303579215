import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ModalCloseAction } from '../../_stores/modal/_actions/modal.actions';
import { ModalState } from '../../_stores/modal/_state/modal.state';
import { EditAssignment } from '../../_stores/asignments/_actions/asignments.actions';
import { AssignmentModel } from '../../_models/assignment.model';
import { AssignmentsState } from '../../_stores/asignments/_state/assignments.state';



@Component({
  selector: 'ui-assignment-archive',
  templateUrl: './ui-assignment-archive.component.html',
  styleUrls: ['./ui-assignment-archive.component.scss'],
})
export class UiAssignmentArchiveComponent implements OnInit {
  interface: {
    _id: null,
    loading: boolean,
    assignment: AssignmentModel
  } = {
    _id: null,
    loading: false,
    assignment: undefined
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initAssignment();
  }

  initAssignment(): void {
    this.store.select(AssignmentsState.selectAssignmentsByModalId).subscribe((_assignment) => {
      this.interface.assignment = {..._assignment};
      this.interface.assignment.options = {...this.interface.assignment.options, isActive: false};
    });
  }

  actionConfirm(): void {
    this.interface.loading = true;
    this.store.dispatch(new EditAssignment({ data: this.interface.assignment })).subscribe((value) => {
      this.interface.loading = false;
      this.store.dispatch(new ModalCloseAction());
      this.cdr.detectChanges();
    });
  }

  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }
}
