<div class="ui-worksheet-container">
  <div class="ui-worksheet-section">
    <div class="ui-worksheet-actions">
      <div class="mr-auto text-sm text-slate-500 line-clamp-1">{{getIndex() + 1}} of {{interface._cache.gallery.list.length}} worksheet pages</div>
      <button (click)="actionPrevGallery()" class="ui-worksheet-action" tooltip title="Prev page" position="above" [disabled]="interface._cache.gallery.index == 0">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
      </button>
      <button (click)="actionNextGallery()" class="ui-worksheet-action" tooltip title="Next page" position="above" [disabled]="interface._cache.gallery.index == interface._cache.gallery.list.length-1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
        </svg>
      </button>
    </div>
    <div class="ui-worksheet-single">
      <ng-container *ngIf="!interface.worksheet">
        <ui-no-data type="loading" text="Loading worksheet"></ui-no-data>
      </ng-container>
      <ng-container *ngIf="interface.worksheet">
        <div class="ui-worksheet-page">
          <img src="{{interface._cache.gallery.current}}" alt="" loading="lazy">
          <ui-editor (dataURL)="actionGetEditWorksheet($event)" (hide)="actionToggleEditMode()"
                     [backgroundImage]="interface._cache.gallery.current" [position]="'top'"
                     [show]="interface._cache.editing"
                     [backgroundImageList] = "interface._cache.gallery.list"></ui-editor>
        </div>
      </ng-container>

    </div>
    <div class="ui-worksheet-actions">
      <div class="mr-auto text-sm text-slate-500 line-clamp-1">{{getIndex() + 1}} of {{interface._cache.gallery.list.length}} worksheet pages</div>
      <button (click)="actionPrevGallery()" class="ui-worksheet-action" tooltip title="Prev page" position="above" [disabled]="interface._cache.gallery.index == 0">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
      </button>
      <button (click)="actionNextGallery()" class="ui-worksheet-action" tooltip title="Next page" position="above" [disabled]="interface._cache.gallery.index == interface._cache.gallery.list.length-1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
        </svg>
      </button>
    </div>
  </div>
  <div class="ui-worksheet-aside">
    <div class="ui-worksheet-controls">
      <button class="ui-worksheet-control" (click)="actionToggleEditMode()"  tooltip description="Writing mode" [disabled]="!interface.controls.edit || interface.controls.upload">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
        </svg>
      </button>
      <button class="ui-worksheet-control" (click)="actionPrint()" tooltip description="Print worksheet" [disabled]="!interface.controls.print">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
        </svg>
      </button>
      <button *ngIf="!interface.homework" class="ui-worksheet-control ui-worksheet-control-exit" (click)="actionExitPractice()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        <p>Exit worksheet</p>
      </button>
      <button *ngIf="interface.homework" class="ui-worksheet-control ui-worksheet-control-exit" (click)="actionExitHomework()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        <p>Exit homework</p>
      </button>
    </div>
    <div class="ui-worksheet-files">
      <label>If you need worksheet files to work offline or in an external editor, you can use the links below</label>
      <div class="ui-worksheet-files-buttons">
        <button class="ui-btn" (click)="actionDownloadWorksheet()" [disabled]="!interface.controls.download_worksheet">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m-6 3.75l3 3m0 0l3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75" />
          </svg>
          <span>Worksheet</span>
        </button>
        <button class="ui-btn" (click)="actionDownloadAnswers()" [disabled]="!interface.controls.download_worksheet_answers">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25" />
          </svg>
          <span>Answers</span>
        </button>
      </div>
    </div>
    <div *ngIf="interface.homework" class="ui-worksheet-homework">
      <label>Your homework</label>
      <div *ngIf="interface._cache.files.length == 0" class="ui-worksheet-homework-upload">
        <svg class="ui-worksheet-homework-upload-icon" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
          <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div class="ui-worksheet-homework-upload-input">
          <label for="homework-upload">
            <span>Upload a file</span>
            <input id="homework-upload" name="homework-upload" type="file" class="sr-only" accept="image/x-png,image/gif,image/jpeg,application/pdf" (change)="actionUpload($event)">
          </label>
          <p class="pl-1">or drag and drop</p>
        </div>
        <div class="ui-worksheet-homework-upload-text">
          PNG, JPG, GIF up to 10MB
        </div>
      </div>
      <div *ngIf="interface._cache.files.length > 0" class="ui-worksheet-homework-upload-files">
        <ng-container *ngFor="let file of interface._cache.files;  let p=index">
          <div class="ui-worksheet-homework-upload-file">
            <div class="ui-worksheet-homework-upload-file-icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
              </svg>
            </div>
            <div class="ui-worksheet-homework-upload-file-data">
              <p>{{file.data.name}}</p>
              <span>Last edited: {{file.data.lastModified | date: 'longDate'}}</span>
            </div>
            <div class="ui-worksheet-homework-upload-file-action" (click)="actionClearUploadFiles()" tooltip description="Delete file">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
          </div>
        </ng-container>
      </div>
      <button class="ui-btn" (click)="actionSubmitHomework()" [disabled]="interface._cache.files.length == 0 || interface.loading">
          <svg *ngIf="!interface.loading" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
          </svg>
          <svg *ngIf="interface.loading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <span>Submit homework</span>
        </button>
    </div>
  </div>
</div>
