import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { SortPipe } from '../../_pipes/sort.pipe';
import { CategoriesTreeModel } from '../../_models/categories-tree.model';
import { CategoryModel } from '../../_models/category.model';

@Component({
  selector: 'ui-categories-tree',
  templateUrl: './ui-categories-tree.component.html',
  styleUrls: ['./ui-categories-tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiCategoriesTreeComponent implements OnInit, OnChanges, OnDestroy {
  @Input('practice') set _practice(practice: CategoryModel[]) {
    if (practice) {
      this.interface._cache.tree = practice;
      this.initTabs();
    }
  }
  @Input() type: any;

  private destroy$ = new Subject<void>();

  interface: CategoriesTreeModel = {
    tree: {
      practices: [],
      tabs: [],
    },
    _cache: {
      active: undefined,
      tree: [],
    },
  };

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private sort: SortPipe,
  ) {}

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(): void {}

  initTabs(): void {
    if (this.interface._cache.tree.length == 0) {
      return;
    }
    this.interface.tree.tabs = this.sort.transform(this.interface._cache.tree[0].children, 'desc', 'slug');

    this.actionChangeActiveTab(0);
  }

  actionChangeActiveTab(i): void {
    this.interface._cache.active = this.interface.tree.tabs[i];
    this.interface.tree.practices = this.interface.tree.tabs[i].children;
  }
}
