import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiProfileSelectComponent} from "./ui-profile-select.component";



@NgModule({
  declarations: [UiProfileSelectComponent],
  exports: [UiProfileSelectComponent],
  imports: [
    CommonModule
  ]
})
export class UiProfileSelectModule { }
