import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiBooksComponent} from "./ui-books.component";
import {UiAppNoDataModule} from "../ui-app-no-data/ui-app-no-data.module";
import {LazyLoadImageModule} from "ng-lazyload-image";
import {RouterModule} from "@angular/router";
import {PipesModule} from "../../app.pipes.module";
import {UiTooltipModule} from "../ui-tooltip/ui-tooltip.module";



@NgModule({
  declarations: [UiBooksComponent],
  exports: [UiBooksComponent],
  imports: [
    CommonModule,
    UiAppNoDataModule,
    LazyLoadImageModule,
    RouterModule,
    PipesModule,
    UiTooltipModule,
  ]
})
export class UiBooksModule { }
