import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { Clipboard } from '@angular/cdk/clipboard';
import { ActivatedRoute } from '@angular/router';
import { ClassesState } from '../../_stores/classes/_state/classes.state';
import { ClassesService } from '../../_stores/classes/_services/classes.service';
import { UiClassEditModel } from '../../_models/ui-class-edit.model';
import { API } from '../../_stores/api';
import { ModalCloseAction } from '../../_stores/modal/_actions/modal.actions';
import { GetClassList } from "../../_stores/classes/_actions/classes.actions";
import {
  EventInviteToClassLinkCopied,
  EventInviteToSchoolLinkCopied
} from "../../_stores/event_ws/_actions/events.actions";
import { NotificationOpen } from "../../_stores/notification/_actions/notification.actions";

@Component({
  selector: 'ui-class-edit',
  templateUrl: './ui-class-edit.component.html',
  styleUrls: ['./ui-class-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiClassEditComponent implements OnInit {
  id = null;
  interface: UiClassEditModel = {
    class: undefined,
    loading:false,
  };

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private clipboard: Clipboard,
    private classService: ClassesService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initClass();
  }

  initClass() {
    this.store.select(ClassesState.selectClassByModalID).subscribe((_class) => {
      this.interface.class = {..._class};
      this.cdr.detectChanges();
    });
  }

  actionChangeClassTitle(event): void {
    this.interface.class.title = event.target.value;
  }
  actionGetClassInviteLink(): string {
    return window.location.origin + '/invite/' + this.interface.class.sin;
  }
  actionToggleArchive(): void {
    this.interface.class = {
      ...this.interface.class,
      options: {
        ...this.interface.class.options,
        isArchive: !this.interface.class.options.isArchive,
      },
    };
  }
  actionDownloadInstructions(): void {
    const _href = 'https://argoprep.com/?ept_pdf&invite='+this.interface.class.sin;
/*
    const downloadLink = document.createElement('a');
    downloadLink.href = API.url('classes/' + this.interface.class._id + '/pdf');
    downloadLink.href = _href;
    downloadLink.setAttribute('download', 'TEST');
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.remove();
*/
    window.open(_href, 'argo', 'width=700,height=900');
  }
  actionClipboardInviteLink(): void {
    this.store.dispatch(new EventInviteToClassLinkCopied({ classId: this.interface.class._id }))
    this.clipboard.copy(this.actionGetClassInviteLink());
    this.store.dispatch(new NotificationOpen('class_invite_link_copy'));
  }


  actionConfirm(): void {
    this.interface.loading = true;
    delete this.interface.class.sin;
    /*TODO пофиксить на сервере ошибку при передаче sin
      Cast to ObjectId failed for value "68468" (type number) at path "sin" because of "BSONTypeError"
    */
    this.classService.updateClass(this.interface.class).subscribe({
      next: (res) => {
        this.interface.loading = false;
        this.store.dispatch(new GetClassList());
        this.store.dispatch(new ModalCloseAction());
        this.cdr.detectChanges();
      },
      error: (res) => {
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
    });
  }
  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }
}
