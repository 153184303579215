import { ChangeDetectorRef, Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { BooksService } from '../../_stores/books/_service/books.service';
import { Store } from '@ngxs/store';
import { BooksState } from '../../_stores/books/_state/books.state';
import { ActivatedRoute, Router } from '@angular/router';
import { PageBookModel } from '../../_models/page-book.model';
import { ClearBookPages, LoadBookPages, LoadBookVideos } from '../../_stores/books/_actions/books.actions';
import { EventEBookOpened, EventEBookPrinted } from '../../_stores/event_ws/_actions/events.actions';
import { UserState } from '../../_stores/user/_state/user.state';
import { DatePipe } from '@angular/common';
import {
  AssignmentsBuilderAddEBook,
  AssignmentsBuilderRemoveEBook,
} from '../../_stores/assignments-builder/_actions/assignments-builder.actions';
import { AssignmentBuilderService } from '../../_utils/assignment-builder-service';

@Component({
  selector: 'ui-page-e-book',
  templateUrl: './ui-page-e-book.component.html',
  styleUrls: ['./ui-page-e-book.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiPageEBookComponent implements OnInit {
  interface: PageBookModel = {
    _cache: {
      id: undefined,
      pages: { current: 0, data: undefined },
      print_collections: [],
      touch: { time: 0, x: 0, y: 0 },
    },
    update: false,
    user: undefined,
    book: undefined,
    book_videos: [],
    pages: {
      single: undefined,
      spread: [],
      number: 1,
    },
    actions: {
      printWarning: false,
      bookmark: false,
      menu: false,
      notes: false,
      note: false,
      print: false,
      search: false,
      write: false,
      zoom: false,
      watch: false,
      fullscreen: false,
      assignments: false,
    },
  };

  constructor(
    private assignmentBuilderService: AssignmentBuilderService,
    private cdr: ChangeDetectorRef,
    private booksService: BooksService,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
  ) {
    this.interface._cache.id = this.route.snapshot.paramMap.get('id');
    this.interface.update = true;
  }

  ngOnInit(): void {
    this.initUser();
    this.initBook();
    this.initBookPage();
    this.initBookSpread();
    this.initBookVideos();
    this.store.dispatch(new EventEBookOpened({ bookId: this.interface._cache.id }));
  }

  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.cdr.detectChanges();
    });
  }

  initBook(): void {
    this.store.select(BooksState.selectBookById(this.interface._cache.id)).subscribe((_book) => {
      if (!this.interface.book) {
        this.interface.book = _book;
        this.store.dispatch(new ClearBookPages());
        this.store.dispatch(new LoadBookVideos(this.interface.book.videos));
        this.loadBookPage();
        this.cdr.markForCheck();
      }
    });
  }

  initBookPage(): void {
    this.store.select(BooksState.selectBookPage).subscribe((_page) => {
      this.interface.pages.single = _page;
      if (this.interface.pages.single) {
        this.interface.update = false;
        this.cdr.detectChanges();
      }
    });
  }

  initBookSpread(): void {
    this.store.select(BooksState.selectBookSpread).subscribe((_pages) => {
      this.interface.pages.spread = [..._pages].sort((a, b) => a.page - b.page);
      this.interface._cache.pages.data = _pages;
      this.cdr.detectChanges();
    });
  }

  initBookVideos(): void {
    this.store.select(BooksState.selectBookVideos).subscribe((_categories) => {
      this.interface.book_videos = _categories;
      this.cdr.detectChanges();
    });
  }

  loadBookPage(): void {
    this.interface.update = true;
    this.store.dispatch(new LoadBookPages(this.interface._cache.id, this.interface.pages.number));
  }

  checkPrintCollectionPage(page): boolean {
    let _print_page = this.interface._cache.print_collections.find((_page) => _page._id == page._id);
    return !!_print_page;
  }

  actionNextPage(): void {
    if (this.interface.pages.number == this.interface.book.count_page) {
      return;
    }
    this.interface.pages.number += 1;
    this.loadBookPage();
  }

  actionPrevPage(): void {
    if (this.interface.pages.number == 1) {
      return;
    }
    this.interface.pages.number -= 1;
    this.loadBookPage();
  }

  actionNextSpreadPage(): void {
    if (this.interface.pages.number >= this.interface.book.count_page) {
      this.interface.pages.number = this.interface.book.count_page;
      return;
    }
    this.interface.pages.number += 2;
    this.loadBookPage();
  }

  actionPrevSpreadPage(): void {
    if (this.interface.pages.number <= 2) {
      this.interface.pages.number = 1;
      return;
    }
    this.interface.pages.number -= 2;
    this.loadBookPage();
  }

  actionFirstPage(): void {
    this.interface.pages.number = 1;
    this.loadBookPage();
  }

  actionLastPage(): void {
    this.interface.pages.number = this.interface.book.count_page;
    this.loadBookPage();
  }

  actionAddToPrint(page): void {
    let _print_pages = this.interface._cache.print_collections.filter((_page) => _page._id != page._id);
    if (!this.checkPrintCollectionPage(page)) {
      _print_pages.push(page);
    }
    this.interface._cache.print_collections = _print_pages;
  }

  actionPrintPage(): void {
    this.store.dispatch(
      new EventEBookPrinted({ bookId: this.interface._cache.id, bookPage: this.interface._cache.print_collections }),
    );
    let _printwindow;
    const chrome =
      navigator.userAgent.toLowerCase().indexOf('chrome') > -1 && navigator.vendor.toLowerCase().indexOf('google') > -1;
    const watermark =
      'The use of this page is granted to "' +
      this.interface.user.school.schoolName +
      '" and expires on "' +
      this.datePipe.transform(this.interface.user.school.license.endDate, 'longDate') +
      '". After this date, this page may not be used under any purposes under copyright laws.';
    if (chrome) {
      _printwindow = window.open('/', 'argo', 'width=700,height=450');
    } else {
      _printwindow = window.open('', 'PRINT');
    }

    _printwindow.document.write(
      '<style type="text/css" media="all">@page { margin: 0; size: A4 } body { margin: 0 } .ui-print-page { margin: 0; overflow: hidden; position: relative; box-sizing: border-box; page-break-after: always; } .ui-print-page-watermark { position: absolute; background: transparent; width: 75%; height: auto; bottom: 1%; left: 12.565%; z-index: 99; display: flex; align-items: center; justify-content: center; margin: 0 auto; font-size: small; text-align: center; color: #7a7a7a; } body.A3 .ui-print-page { width: 297mm; height: 419mm } body.A3.landscape .ui-print-page { width: 420mm; height: 296mm } body.A4 .ui-print-page { width: 210mm; height: 296mm } body.A4.landscape .ui-print-page { width: 297mm; height: 209mm } body.A5 .ui-print-page { width: 148mm; height: 209mm } body.A5.landscape .ui-print-page { width: 210mm; height: 147mm } body.letter .ui-print-page { width: 216mm; height: 279mm } body.letter.landscape .ui-print-page { width: 280mm; height: 215mm } body.legal .ui-print-page { width: 216mm; height: 356mm } body.legal.landscape .ui-print-page { width: 357mm; height: 215mm } @media screen { body { background: #e0e0e0 } .ui-print-page { background: white; box-shadow: 0 .5mm 2mm rgba(0,0,0,.3); margin: 5mm auto; } } @media print { body.A3.landscape { width: 420mm } body.A3, body.A4.landscape { width: 297mm } body.A4, body.A5.landscape { width: 210mm } body.A5 { width: 148mm } body.letter, body.legal { width: 216mm } body.letter.landscape { width: 280mm } body.legal.landscape { width: 357mm } }</style>',
    );
    _printwindow.document.write('<script>window.onload = function() {window.print();window.close();};</script>');
    this.interface._cache.print_collections.forEach((page: any) => {
      let _page_data = page.data;
      _page_data = _page_data
        .replace('height=&quot;100%&quot;', 'height="auto"')
        .replaceAll('&quot;', '"')
        .replaceAll('&lt;', '<')
        .replaceAll('&gt;', '>');
      _printwindow.document.write(
        '<section class="ui-print-page">' +
          _page_data +
          '<div class="ui-print-page-watermark">' +
          watermark +
          '</div></section>',
      );
    });
    _printwindow.document.close();
    _printwindow.focus();
    setTimeout(() => {
      _printwindow.document.body.classList.add('A4');
    }, 600);
  }

  actionCloseEditor(event?): void {
    this.actionToggleWrite();
  }

  actionToggleWrite(): void {
    this.interface.actions.menu = false;
    this.interface.actions.search = false;
    this.interface.actions.write = !this.interface.actions.write;
  }

  actionToggleSearch(e?): void {
    if (e && e.target.className != 'ui-book-search-overlay') {
      return;
    }

    this.interface.actions.menu = false;
    this.interface.actions.write = false;
    this.interface.actions.search = !this.interface.actions.search;
  }

  actionSearch(event): void {
    if (event.target.value > this.interface.book.count_page) {
      event.target.value = this.interface.book.count_page;
    }

    this.interface.pages.number = Number(event.target.value);
    this.loadBookPage();
  }

  actionToggleMenu(e?): void {
    if (e && e.target.className != 'ui-book-toc-overlay') {
      return;
    }
    this.interface.actions.write = false;
    this.interface.actions.search = false;
    this.interface.actions.menu = !this.interface.actions.menu;
  }

  actionOpenPage(page): void {
    this.interface.pages.number = Number(page);
    this.loadBookPage();
  }

  @HostListener('touchstart', ['$event'])
  //@HostListener('touchmove', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('touchcancel', ['$event'])
  handleTouch(event) {
    if (event.target.classList.contains('ui-book-page-data')) {
      let touch = event.touches[0] || event.changedTouches[0];
      // check the events
      if (event.type === 'touchstart') {
        this.interface._cache.touch.x = touch.pageX;
        this.interface._cache.touch.y = touch.pageY;
        this.interface._cache.touch.time = event.timeStamp;
      } else if (event.type === 'touchend') {
        let deltaX = touch.pageX - this.interface._cache.touch.x;
        let deltaY = touch.pageY - this.interface._cache.touch.y;
        let deltaTime = event.timeStamp - this.interface._cache.touch.time;

        // simulte a swipe -> less than 500 ms and more than 60 px
        if (deltaTime < 500) {
          // touch movement lasted less than 500 ms
          if (Math.abs(deltaX) > 60) {
            // delta x is at least 60 pixels
            if (deltaX > 0) {
              this.actionSwipeRight(event);
            } else {
              this.actionSwipeLeft(event);
            }
          }

          if (Math.abs(deltaY) > 60) {
            // delta y is at least 60 pixels
            if (deltaY > 0) {
              this.actionSwipeDown(event);
            } else {
              this.actionSwipeUp(event);
            }
          }
        }
      }
    }
  }

  actionSwipeLeft(event) {
    this.actionNextPage();
  }

  actionSwipeRight(event) {
    this.actionPrevPage();
  }

  actionSwipeUp(event) {}

  actionSwipeDown(event) {}

  actionAssignPage(spreadPage) {
    this.init_cash();
    const pageNumber = this.interface._cache.pages.data[spreadPage].page;
    const pageId = this.interface._cache.pages.data[spreadPage]._id;
    const collectionIndex = this.interface._cache.assignment.pages.findIndex((_aPage) => _aPage === pageNumber);
    if (this.interface._cache.assignment.pages[collectionIndex]) {
      this.interface._cache.assignment.pages.splice(collectionIndex, 1);
      this.store.dispatch(new AssignmentsBuilderRemoveEBook({ e_book: this.interface._cache.assignment }));
    } else {
      this.init_cash();
      this.assignmentBuilderService.addEbookAssignment({
        id: this.interface._cache.assignment.id,
        materials: 'book',
        title: this.interface._cache.assignment.title,
        pages: { page: pageNumber, id: pageId },
      });
      this.interface._cache.assignment.pages.push({ page: pageNumber, id: pageId });
      this.store.dispatch(new AssignmentsBuilderAddEBook({ e_book: this.interface._cache.assignment }));
    }
  }

  checkAssign(spreadPage) {
    if (!this.interface._cache.assignment) {
      return false;
    }
    const pageNumber = this.interface?._cache?.pages?.data[spreadPage].page;
    // const collectionIndex = this.interface._cache.assignment.pages.findIndex((_aPage) => _aPage === pageNumber);
    const findEbook = this.assignmentBuilderService.ebooks.find((ebook) => ebook._id === this.interface.book._id);
    if (findEbook) {
      return !!findEbook.pages.find((page) => page.page === pageNumber);
    }
    // return !!this.interface._cache.assignment.pages[collectionIndex];
    return false;
  }

  actionSelectToAssign() {
    this.interface.actions.assignments = !this.interface.actions.assignments;
  }

  private init_cash() {
    if (this.interface.actions.assignments) {
      this.interface._cache.assignment = {
        id: this.interface.book._id,
        title: this.interface.book.title,
        pages: [],
      };
    } else {
      this.interface._cache.assignment = undefined;
    }
  }
}
