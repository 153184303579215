<div class="ui-page-sidebar">
  <div class="ui-page-logo">
    <h1>Libraries</h1>
    <button (click)="actionToggleSidebar()" tooltip description="Hide sidebar">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
        <path d="M20 6H4M20 12H12M20 18H4"  stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.5 14L4 11.5L6.5 9"  stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  </div>
  <div class="ui-page-user">
    <div class="ui-page-userinfo">
      <div class="ui-page-username">{{interface.user?.displayName}}</div>
      <div class="ui-page-userrole">{{interface.user?.role?.name}}</div>
      <div class="ui-page-userain">{{interface.user?.ain}}</div>
    </div>
    <div class="ui-page-useraction">
      <button  tooltip description="Edit user" (click)="actionOpenEditUserModal()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
        </svg>
      </button>
    </div>
  </div>
  <div class="ui-page-menus">
    <ng-container *ngIf="interface.user?.role?._id == '6301e162029cc9403c26de0c'" [ngTemplateOutlet]="MENU_ADMINISTRATOR"></ng-container>
    <ng-container *ngIf="interface.user?.role?._id == '63514e3dac62e30fadcd6780'" [ngTemplateOutlet]="MENU_LIBRARIAN"></ng-container>
  </div>
</div>

<ng-template #MENU_ADMINISTRATOR>
  <div class="ui-page-menu">
    <div class="ui-page-menu-title">
      <span>{{'menu.menu' | transloco}}</span>
    </div>
    <ul>
      <li routerLink="/" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>
        <span>{{'menu.dashboard' | transloco}}</span>
      </li>
      <li routerLink="/control/users" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
        <span>Users</span>
      </li>
      <li routerLink="/control/roles" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z" />
        </svg>
        <span>Roles</span>
      </li>
      <li routerLink="/control/statistic" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
        </svg>
        <span>Statistic</span>
      </li>
    </ul>
  </div>
  <div class="ui-page-menu">
    <div class="ui-page-menu-title">
      <span>{{'menu.practices' | transloco}}</span>
    </div>
    <ul>
      <li routerLink="/control/practices/grades" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
        </svg>
        <span>Grades</span>
      </li>
      <li routerLink="/control/practices/categories" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
        </svg>
        <span>Categories</span>
      </li>
      <li routerLink="/control/practices/questions" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <span>Questions</span>
      </li>
      <li routerLink="/control/practices/videos" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
        </svg>
        <span>Videos</span>
      </li>
      <li routerLink="/control/practices/worksheets" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
        <span>Worksheets</span>
      </li>
      <li routerLink="/control/practices/books" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
        </svg>
        <span>Books</span>
      </li>
    </ul>
  </div>
  <div class="ui-page-menu">
    <div class="ui-page-menu-title">
      <span>Libraries</span>
    </div>
    <ul>
      <li routerLink="/control/libraries" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
        </svg>
        <span>List</span>
      </li>
      <li routerLink="/control/libraries/licenses" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
        </svg>
        <span>Licenses</span>
      </li>
    </ul>
  </div>
</ng-template>
<ng-template #MENU_LIBRARIAN>
  <div class="ui-page-menu">
    <div class="ui-page-menu-title">
      <span>{{'menu.menu' | transloco}}</span>
    </div>
    <ul>
      <li routerLink="/" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>
        <span>{{'menu.dashboard' | transloco}}</span>
      </li>
      <li routerLink="/books" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
        </svg>
        <span>Books</span>
      </li>
    </ul>
  </div>
  <div class="ui-page-menu">
    <div class="ui-page-menu-title">
      <span>{{'menu.practices' | transloco}}</span>
    </div>
    <ul>
      <li routerLink="/practices/practice_drills" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 6C6.89543 6 6 6.89543 6 8V16C6 17.1046 6.89543 18 8 18H10.5C11.0523 18 11.5 17.5523 11.5 17V12C11.5 10.6193 12.6193 9.5 14 9.5H18V8C18 6.89543 17.1046 6 16 6H8ZM7.25 8C7.25 7.58579 7.58579 7.25 8 7.25H8.01C8.42421 7.25 8.76 7.58579 8.76 8C8.76 8.41421 8.42421 8.75 8.01 8.75H8C7.58579 8.75 7.25 8.41421 7.25 8ZM10 7.25C9.58579 7.25 9.25 7.58579 9.25 8C9.25 8.41421 9.58579 8.75 10 8.75H10.01C10.4242 8.75 10.76 8.41421 10.76 8C10.76 7.58579 10.4242 7.25 10.01 7.25H10Z" fill="#E879F9" class="fill-[#F68383]"></path>
          <path d="M13 12C13 11.4477 13.4477 11 14 11H17C17.5523 11 18 11.4477 18 12V17C18 17.5523 17.5523 18 17 18H14C13.4477 18 13 17.5523 13 17V12Z" fill="#F0ABFC" class="fill-[#fdcbcb]"></path>
        </svg>
        <span>Practice drills</span>
      </li>
      <li routerLink="/practices/practice_questions" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none">
          <path d="m6 9 6-3 6 3v6l-6 3-6-3V9Z" class="fill-[#e4e5fb]"></path>
          <path d="m6 9 6 3v7l-6-3V9Z" class="fill-[#b1b2f0]"></path>
          <path d="m18 9-6 3v7l6-3V9Z" class="fill-[#8d87e6]"></path>
        </svg>
        <span>Practice questions</span>
      </li>
      <li routerLink="/practices/video_lectures" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M19 12a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" class="fill-[#83caf6] "></path>
          <path d="M11.082 9.107a.685.685 0 0 0-.72-.01.757.757 0 0 0-.362.653v4.5c0 .27.138.52.362.653.224.133.5.13.72-.01l3.571-2.25A.758.758 0 0 0 15 12a.758.758 0 0 0-.347-.643l-3.571-2.25Z" class="fill-[#f1f8fe]"></path>
        </svg>
        <span>Video lectures</span>
      </li>
      <li routerLink="/practices/worksheets" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none">
          <path d="M6 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8ZM6 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-1Z" class="fill-[#dc8c5b]"></path>
          <path d="M13 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2V8Z" class="fill-[#f0d2b8]"></path>
          <path d="M13 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-1Z" class="fill-[#dc8c5b]"></path>
        </svg>
        <span>Worksheets</span>
      </li>
    </ul>
  </div>
</ng-template>
