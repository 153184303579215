<div class="ui-block ui-block-no-margin ui-block-3 ui-block-white">
  <div class="ui-block-inner ui-block-inner-col my-6">
    <ng-container *ngIf="interface.loading"><ui-no-data type="loading"></ui-no-data></ng-container>
    <ng-container *ngIf="!interface.loading">
      <div class="ui-stat-user">
        <div class="flex items-center gap-4">
          <div class="ui-stat-user-avatar">
            <img src="{{interface.user.avatar}}" alt="" loading="lazy">
          </div>
          <div class="ui-stat-user-info">
            <div class="ui-stat-user-info-username">{{interface.user.displayName}}</div>
            <div class="ui-stat-user-info-userrole">{{interface.user.role.name}}</div>
            <div class="ui-stat-user-info-userain">{{interface.user.ain}}</div>
          </div>
        </div>
        <div class="ui-stat-user-details">
          <div class="ui-stat-user-detail">
            <p>First name</p>
            <span>{{interface.user.firstName}}</span>
          </div>
          <div class="ui-stat-user-detail">
            <p>Last name</p>
            <span>{{interface.user.lastName}}</span>
          </div>
          <div class="ui-stat-user-detail">
            <p>Email</p>
            <span>
              <ng-container *ngIf="interface.user.role._id == '62ebc2e38e33b7bc52c853c6'">----</ng-container>
              <ng-container *ngIf="interface.user.role._id != '62ebc2e38e33b7bc52c853c6'">{{interface.user.email}}</ng-container>
            </span>
          </div>
          <div class="ui-stat-user-detail">
            <p>Language</p>
            <span>{{interface.user.language}}</span>
          </div>
          <div class="ui-stat-user-detail">
            <p>Practice</p>
            <span>{{interface.user?.practice?.title}}</span>
          </div>

          <div class="ui-stat-user-detail mt-4">
            <p>Created</p>
            <span>{{interface.user.createdAt | date: 'medium'}}</span>
          </div>
          <div class="ui-stat-user-detail">
            <p>Updated</p>
            <span>{{interface.user.updatedAt | date: 'medium'}}</span>
          </div>
        </div>
        <div class="ui-stat-user-school">
          <div class="ui-stat-user-school-icon">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
            </svg>
          </div>
          <div class="ui-stat-user-school-data">
            <p [routerLink]="'/control/libraries/'+interface.user?.school?._id+'/detail'">{{interface.user?.school?.schoolName}}</p>
            <span>{{interface.user?.school?.address}}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="ui-block ui-block-no-margin ui-block-9 ui-block-white">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Trackers</h2>
      <p>&nbsp;</p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
  </div>
  <div class="ui-block-inner ui-block-inner-col my-4">
    <ng-container *ngIf="interface.loading"><ui-no-data type="loading"></ui-no-data></ng-container>
    <ng-container *ngIf="!interface.loading">
      <apx-chart
        [colors]="interface.charts.tracker.colors"
        [series]="interface.charts.tracker.series"
        [chart]="interface.charts.tracker.chart"
        [yaxis]="interface.charts.tracker.yaxis"
        [xaxis]="interface.charts.tracker.xaxis"
        [stroke]="interface.charts.tracker.stroke"
        [tooltip]="interface.charts.tracker.tooltip"
        [legend]="interface.charts.tracker.legend"
        [dataLabels]="interface.charts.tracker.dataLabels"
        [plotOptions]="interface.charts.tracker.plotOptions"
      ></apx-chart>
    </ng-container>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>
<div class="ui-block ui-block-no-margin ui-block-2 ui-block-white self-baseline">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Classes</h2>
      <p>
        <ng-container *ngIf="interface.user?.role._id == '62ebc2e38e33b7bc52c853c6'">Consists of classes</ng-container>
        <ng-container *ngIf="interface.user?.role._id != '62ebc2e38e33b7bc52c853c6'">Creator of the class</ng-container>
      </p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
  </div>
  <div class="ui-block-inner ui-block-inner-col my-4">
    <ng-container *ngIf="interface.loading"><ui-no-data type="loading"></ui-no-data></ng-container>
    <ng-container *ngIf="!interface.loading">
      <div class="ui-stat-classes">
        <ng-container *ngFor="let _class of interface.user.classes">
          <div class="ui-stat-class">
            <div class="ui-stat-class-icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
              </svg>
            </div>
            <div class="ui-stat-class-text">
              <h3>{{_class.title}}</h3>
              <p>
                <ng-container *ngIf="_class.teacher == interface.user._id">&nbsp;{{interface.user.firstName}} {{interface.user.lastName}} </ng-container>
                <ng-container *ngIf="_class.teacher != interface.user._id">&nbsp;{{_class.teacher.firstName}} {{_class.teacher.lastName}}</ng-container>
              </p>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="interface.user?.classes.length == 0"><ui-no-data text="No classes..."></ui-no-data></ng-container>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>
<div class="ui-block ui-block-no-margin ui-block-3 ui-block-white self-baseline !hidden">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Students</h2>
      <p>From classes</p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
  </div>
  <div class="ui-block-inner ui-block-inner-col my-4">
    <ng-container *ngIf="interface.loading"><ui-no-data type="loading"></ui-no-data></ng-container>
    <ng-container *ngIf="!interface.loading">
      <div class="ui-stat-classes">
        <ng-container *ngFor="let _class of interface.user.classes">

        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="interface.user.classes.length == 0"><ui-no-data text="No classes..."></ui-no-data></ng-container>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>
<div class="ui-block ui-block-no-margin ui-block-4 ui-block-white self-baseline">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>
        <ng-container *ngIf="interface.user.role._id == '62ebc2e38e33b7bc52c853c6'">Homeworks</ng-container>
        <ng-container *ngIf="interface.user.role._id != '62ebc2e38e33b7bc52c853c6'">Assignments</ng-container>
      </h2>
      <p>
        <ng-container *ngIf="interface.user.role._id == '62ebc2e38e33b7bc52c853c6'">Appointed</ng-container>
        <ng-container *ngIf="interface.user.role._id != '62ebc2e38e33b7bc52c853c6'">Created</ng-container>
      </p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
  </div>
  <div class="ui-block-inner ui-block-inner-col my-4">
    <ng-container *ngIf="interface.loading"><ui-no-data type="loading"></ui-no-data></ng-container>
    <ng-container *ngIf="!interface.loading">
      <ng-container *ngIf="interface.user.role._id == '62ebc2e38e33b7bc52c853c6'">
        <div class="ui-stat-homeworks">
          <ng-container *ngFor="let homework of interface.user.homeworks">
            <div class="ui-stat-homework">
              <div class="ui-stat-homework-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                </svg>
              </div>
              <div class="ui-stat-homework-text">
                <h3>{{homework.title}}</h3>
                <div>
                  <span><b>Class: </b>{{homework.class.title}} </span>
                  <span><b>Created: </b>{{homework.createdAt | date: 'longDate'}}</span>
                  <span><b>Done: </b>{{homework.options.isDone}}</span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="interface.user.homeworks.length == 0"><ui-no-data text="No homework..."></ui-no-data></ng-container>
      </ng-container>
      <ng-container *ngIf="interface.user.role._id != '62ebc2e38e33b7bc52c853c6'">
        <div class="ui-stat-assignments">
          <ng-container *ngFor="let assignment of interface.user.assignments">
            <div class="ui-stat-assignment">
              <div class="ui-stat-assignment-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                </svg>
              </div>
              <div class="ui-stat-assignment-text">
                <h3>{{assignment.title}}</h3>
                <div>
                  <span><b>Class: </b>{{assignment.class.title}} </span>
                  <span><b>Created: </b>{{assignment.createdAt | date: 'longDate'}}</span>
                  <span><b>Students: </b>{{assignment.students.length}}</span>
                  <span><b>Done: </b>{{assignment.options.isDone}}</span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="interface.user.assignments.length == 0"><ui-no-data text="No assignments..."></ui-no-data></ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>
<div class="ui-block ui-block-no-margin ui-block-3 ui-block-white self-baseline">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Activity</h2>
      <p>Total events <b>{{interface.user.events.length}}</b></p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
  </div>
  <div class="ui-block-inner ui-block-inner-col my-4">
    <ng-container *ngIf="interface.loading"><ui-no-data type="loading"></ui-no-data></ng-container>
    <ng-container *ngIf="!interface.loading">
      <div class="ui-stat-events">
        <ng-container *ngFor="let event of interface.user.events | sort: 'desc': 'count'  let a=index">
          <div class="ui-stat-event">
            <div class="ui-stat-event-icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 12.75c1.148 0 2.278.08 3.383.237 1.037.146 1.866.966 1.866 2.013 0 3.728-2.35 6.75-5.25 6.75S6.75 18.728 6.75 15c0-1.046.83-1.867 1.866-2.013A24.204 24.204 0 0112 12.75zm0 0c2.883 0 5.647.508 8.207 1.44a23.91 23.91 0 01-1.152 6.06M12 12.75c-2.883 0-5.647.508-8.208 1.44.125 2.104.52 4.136 1.153 6.06M12 12.75a2.25 2.25 0 002.248-2.354M12 12.75a2.25 2.25 0 01-2.248-2.354M12 8.25c.995 0 1.971-.08 2.922-.236.403-.066.74-.358.795-.762a3.778 3.778 0 00-.399-2.25M12 8.25c-.995 0-1.97-.08-2.922-.236-.402-.066-.74-.358-.795-.762a3.734 3.734 0 01.4-2.253M12 8.25a2.25 2.25 0 00-2.248 2.146M12 8.25a2.25 2.25 0 012.248 2.146M8.683 5a6.032 6.032 0 01-1.155-1.002c.07-.63.27-1.222.574-1.747m.581 2.749A3.75 3.75 0 0115.318 5m0 0c.427-.283.815-.62 1.155-.999a4.471 4.471 0 00-.575-1.752M4.921 6a24.048 24.048 0 00-.392 3.314c1.668.546 3.416.914 5.223 1.082M19.08 6c.205 1.08.337 2.187.392 3.314a23.882 23.882 0 01-5.223 1.082" />
              </svg>

            </div>
            <div class="ui-stat-event-name">
              <p>{{event.type}}</p>
              <span>{{event.createdAt | date: 'h:mm a MMMM d, y'}}</span>
            </div>
            <div class="ui-stat-event-date"></div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="interface.user.events.length == 0"><ui-no-data text="No user events registered..."></ui-no-data></ng-container>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>

