<ui-page-small-sidebar></ui-page-small-sidebar>
<ui-page-sidebar></ui-page-sidebar>
<div class="ui-page-container">
  <ui-page-header></ui-page-header>
  <ui-page-crumbs></ui-page-crumbs>
  <div class="ui-page-body">
    <ng-container *ngIf="interface.loading">
      <ng-container *ngIf="interface.user?.role?._id == '6301e162029cc9403c26de0c'" [ngTemplateOutlet]="DASHBOARD_ADMINISTRATOR"></ng-container>
      <ng-container *ngIf="interface.user?.role?._id == '63514e3dac62e30fadcd6780'" [ngTemplateOutlet]="DASHBOARD_LIBRARIAN"></ng-container>
    </ng-container>
    <router-outlet (activate)="childContentLoad($event)" (deactivate)="childContentUnLoad($event)"></router-outlet>
  </div>
  <ui-page-footer></ui-page-footer>
</div>
<ui-modal [class.ui-modal-no-close]="interface.modal_no_close"></ui-modal>
<ui-notification></ui-notification>


<ng-template #DASHBOARD_ADMINISTRATOR>
  <ui-control-dashboard></ui-control-dashboard>
</ng-template>
<ng-template #DASHBOARD_LIBRARIAN>
  <ui-faq-videos></ui-faq-videos>
  <ui-books></ui-books>
</ng-template>
