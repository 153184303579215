import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SchoolModel } from '../../../_models/school.model';
import { API } from '../../api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SchoolsService {
  constructor(private http: HttpClient) {}

  createSchool(data: SchoolModel) {
    return this.http.post<SchoolModel>(API.url('schools'), data);
  }

  editSchool(data: SchoolModel) {
    return this.http.patch<SchoolModel>(API.url('schools/' + data._id), data);
  }

  deleteSchool(id: string) {
    return this.http.delete<SchoolModel>(API.url('schools/' + id));
  }

  getSchoolsList(): Observable<SchoolModel[]> {
    return this.http.get<SchoolModel[]>(API.url('schools'));
  }

  getSchool(schoolId: string) {
    return this.http.get<SchoolModel>(API.url('schools/' + schoolId));
  }
}
