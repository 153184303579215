import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { AssignmentsState } from '../../_stores/asignments/_state/assignments.state';
import { AssignmentModel } from '../../_models/assignment.model';
import { GetHomeworks } from '../../_stores/asignments/_actions/asignments.actions';

@Component({
  selector: 'ui-page-homeworks',
  templateUrl: './ui-page-homeworks.component.html',
  styleUrls: ['./ui-page-homeworks.component.scss'],
})
export class UiPageHomeworksComponent implements OnInit {
  interface: {
    loading: boolean
    homeworks: AssignmentModel[],
    tabs: any[],
    _cache: {
      homeworks: AssignmentModel[],
      tab: any
    }
  } = {
    loading: true,
    homeworks: [],
    tabs: [],
    _cache: {
      homeworks: [],
      tab: undefined
    },
  };

  constructor(private store: Store, private router: Router, private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.initTabs();
    this.initHomeworks();
  }

  initTabs(): void {
    this.interface.tabs = [
      {
        _id: null,
        title: 'active'
      },
      {
        _id: null,
        title: 'completed'
      },
      {
        _id: null,
        title: 'scheduled'
      }
    ];
    this.interface._cache.tab = this.interface.tabs[0];
  }
  initHomeworks(): void {
    this.store.select(AssignmentsState.selectHomeworkList).subscribe({
      next: (_homeworks) => {
        this.interface._cache.homeworks = _homeworks;
        this.initHomeworksList();
        this.cdr.detectChanges();
      },
    });
  }
  initHomeworksList(): void {
    if(this.interface._cache.tab.title == 'active') {
      this.interface.homeworks = this.interface._cache.homeworks.filter((_homework) => _homework.options.isActive);
    }
    if(this.interface._cache.tab.title == 'completed') {
      this.interface.homeworks = this.interface._cache.homeworks.filter((_homework) => _homework.options.isActive && _homework.options.isDone);
    }
    if(this.interface._cache.tab.title == 'scheduled') {
      this.interface.homeworks = this.interface._cache.homeworks.filter((_homework) => _homework.options.isActive && !_homework.dates.scheduleDate !== null);
    }
  }

  actionChangeActiveTab(tab): void {
    this.interface._cache.tab = tab;
    this.initHomeworksList();
  }
  actionSearchHomeworks(event): void {
    if (event.target.value.length === 0) {
      this.initHomeworksList();
    } else {
      this.interface.homeworks = this.interface._cache.homeworks.filter((item) => {
        return item.title.toLowerCase().includes(event.target.value.toLowerCase());
      });
    }
  }

  childContentLoad(event): void {
    this.interface.loading = false;
  }
  childContentUnLoad(event): void {
    this.interface.loading = true;
  }
}
