import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { CategoryModel } from "../../_models/category.model";
import { Store } from "@ngxs/store";
import { ActivatedRoute, Router } from "@angular/router";
import { AssignmentBuilderService } from "../../_utils/assignment-builder-service";
import {
  ClearQuizQuestions,
  LoadQuizQuestions,
  UpdateQuizQuestionsResult
} from "../../_stores/practice/_actions/practice.actions";
import { UserState } from "../../_stores/user/_state/user.state";
import { PracticeState } from "../../_stores/practice/_state/practice.state";
import { ModalOpenAction } from "../../_stores/modal/_actions/modal.actions";
import {
  AssignmentsBuilderAddDrill,
  AssignmentsBuilderAddQuiz
} from "../../_stores/assignments-builder/_actions/assignments-builder.actions";
import { QuestionProgressModel, UiQuizModel } from "../../_models/ui-quiz.model";
import { HttpClient } from "@angular/common/http";
import { API } from "../../_stores/api";

@Component({
  selector: 'ui-quiz',
  templateUrl: './ui-quiz.component.html',
  styleUrls: ['./ui-quiz.component.scss']
})
export class UiQuizComponent implements OnInit, OnDestroy {
  @Input('id') set _id(id) {
    if (id) {
      this.interface.quiz._id = id;
      this.store.dispatch(new LoadQuizQuestions(this.interface.quiz._id));
    }
  }
  @Input('homework') set _homework(homework) {
    if (homework) {
      this.interface.homework = homework;
    }
  }
  @Input('assignment') set _assignment(assignment) {
    if (assignment) {
      this.interface.assignment = assignment;
    }
  }

  interface: UiQuizModel = {
    homework: false,
    assignment: null,
    tracker: undefined,
    controls: {
      pause: false,
      sound: true,
      text: false,
      time: 0,
      video: false,
      zoom: false,
    },
    questions: {
      current: undefined,
      list: [],
      progress: [],
    },
    quiz: new (class implements CategoryModel {
      _id: string;
      description: string;
      materials: any;
      options: { visible: boolean };
      order: number;
      parent: CategoryModel;
      slug: string;
      thumbnail: string;
      title: string;
      type: string;
      unlock: {
        type: string;
        payment_id: string | null;
      };
    })(),
    user: undefined,
    _cache: {
      choose_answer: undefined,
      current_question_index: 0,
      timer: undefined,
      timerUpdateProgress: undefined,
      animations: {
        index: 0,
        show: false,
      },
    },
  };

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private assignmentBuilderService: AssignmentBuilderService,
  ) {}

  ngOnInit(): void {
    this.initTracker();
    this.initUser();
    this.initQuestions();
  }

  ngOnDestroy(): void {
    //this.store.dispatch(new ClearQuizQuestions());
  }

  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.cdr.detectChanges();
    });
  }

  initQuestions(): void {
    this.store.select(PracticeState.selectQuestions).subscribe((_questions) => {
      this.interface.questions.list = _questions;
      this.initDefaultProgress();
      this.loadCurrentQuestion();
      this.cdr.detectChanges();
    });
  }

  initDefaultProgress(): void {
    if(this.interface.questions.progress.length > 0) {
      return;
    }
    this.interface.questions.progress = this.interface.questions.list.reduce(
      (progress: QuestionProgressModel[], question) => {
        progress.push({
          id: question._id,
          answered: false,
          correct: false,
          answers: [],
        });
        return progress;
      },
      [],
    );
    this.initTimer();
  }

  initTimer(): void {
    if (this.interface._cache.timer) {
      return;
    }
    this.interface._cache.timer = setInterval(() => {
      if (!this.interface.controls.pause) {
        this.interface.controls.time++;
        this.cdr.detectChanges();
      }
    }, 1000);
  }

  initTracker(): void {
    if(this.interface.homework) {
      let _body = {
        assignment: this.interface.assignment,
        material: this.interface.quiz._id,
        typeMaterial: "Question"
      };
      this.http.post<any>(API.url('trackers/prep-begin'), _body).subscribe((_tracker) => {
        this.interface.tracker = _tracker;
        this.interface.questions.progress = [..._tracker.materialData];
        this.cdr.detectChanges();
      });
    } else {
      let _body = {
        material: this.interface.quiz._id,
        typeMaterial: "Question"
      };
      this.http.post<any>(API.url('trackers/practice-begin'), _body).subscribe((_tracker) => {
        this.interface.tracker = _tracker;
        this.interface.questions.progress = [..._tracker.materialData];
        this.cdr.detectChanges();
      });
    }
  }

  loadCurrentQuestion(): void {
    this.interface.questions.current = this.interface.questions.list[this.interface._cache.current_question_index];
  }

  actionExitPractice(): void {
    this.store.dispatch(new ModalOpenAction('practice_exit', false));
  }

  actionExitHomework(): void {
    this.store.dispatch(new ModalOpenAction('homework_exit', false));
  }

  actionChooseQuestion(index): void {
    this.interface._cache.current_question_index = index;
    this.loadCurrentQuestion();
  }

  actionNextQuestion(): void {
    this.interface._cache.choose_answer = undefined;
    this.interface._cache.current_question_index += 1;
    this.loadCurrentQuestion();
  }

  actionPrevQuestion(): void {
    this.interface._cache.choose_answer = undefined;
    this.interface._cache.current_question_index -= 1;
    this.loadCurrentQuestion();
  }

  actionChooseAnswer(answer): void {
    this.interface._cache.choose_answer = answer;
  }

  actionEnterAnswer(answer, event): void {
    let _answer = { ...answer };
    if (event.target.value != _answer.text) {
      _answer.isCorrect = false;
      _answer.text = event.target.value;
    }
    this.interface._cache.choose_answer = _answer;
  }

  actionCheckAnswer(): void {
    const _answer = this.interface._cache.choose_answer;
    if (_answer.isCorrect) {
      this.actionPlaySuccessSound();
      this.actionShowSuccessAnimation();
    }

    if (!_answer.isCorrect) {
      this.actionPlayWrongSound();
    }
    this.actionUpdateQuestionProgress();

    const _not_answered = this.interface.questions.progress.filter((_question) => !_question.answered);
    if(_not_answered.length == 0 && this.interface.homework) {
      this.store.dispatch( new ModalOpenAction('homework_done'));
    }
  }

  actionCheckQuestionAnswered(): boolean {
    return this.interface.questions.progress[this.interface._cache.current_question_index].answered;
  }

  actionCheckQuestionAnsweredCorrect(): boolean {
    return this.interface.questions.progress[this.interface._cache.current_question_index].correct;
  }

  actionCheckQuestionAnsweredWrong(): boolean {
    return !this.interface.questions.progress[this.interface._cache.current_question_index].correct;
  }

  actionQuestionAnsweredValue(): string {
    const _progress_answers = this.interface.questions.progress[this.interface._cache.current_question_index].answers;
    return _progress_answers.length > 0 ? _progress_answers[0].text : '';
  }

  actionCheckPaginationQuestionAnswered(question_index?): boolean {
    return this.interface.questions.progress[question_index].answered;
  }

  actionCheckPaginationQuestionAnsweredCorrect(question_index?): boolean {
    return this.interface.questions.progress[question_index].correct;
  }

  actionCheckPaginationQuestionAnsweredWrong(question_index?): boolean {
    return !this.interface.questions.progress[question_index].correct;
  }

  actionCheckAnswerCorrect(answer): boolean {
    const _progress = this.interface.questions.progress[this.interface._cache.current_question_index];
    if (!_progress.answered) {
      return false;
    }
    const _answer = _progress.answers.find((_answer) => _answer?._id == answer?._id);
    return !!(_progress.correct && _answer);
  }

  actionCheckAnswerWrong(answer): boolean {
    const _progress = this.interface.questions.progress[this.interface._cache.current_question_index];
    if (!_progress.answered) {
      return false;
    }
    const _answer = _progress.answers.find((_answer) => _answer?._id == answer?._id);
    return !!(!_progress.correct && _answer);
  }

  actionUpdateQuestionProgress(): void {
    const _answer = this.interface._cache.choose_answer;
    this.interface.questions.progress = Object.assign([], this.interface.questions.progress);
    this.interface.questions.progress[this.interface._cache.current_question_index] = Object.assign(
      {},
      this.interface.questions.progress[this.interface._cache.current_question_index],
    );
    this.interface.questions.progress[this.interface._cache.current_question_index].answered = true;
    this.interface.questions.progress[this.interface._cache.current_question_index].correct = _answer.isCorrect;
    this.interface.questions.progress[this.interface._cache.current_question_index].answers = [
      ...[this.interface._cache.choose_answer],
    ];
    this.actionChooseAnswer(undefined);
    this.store.dispatch(new UpdateQuizQuestionsResult(this.interface.questions.progress));
    if(this.interface.homework) {
      let _body = {
        trackerId: this.interface.tracker._id,
        questionId: this.interface.questions.current._id,
        typeTracker: "Preparation",
        answers: [
          _answer._id
        ]
      };
      this.http.put<any>(API.url('answers/check'), _body).subscribe((_check) => {
        this.cdr.detectChanges();
      });
    } else {
      let _body = {
        trackerId: this.interface.tracker._id,
        questionId: this.interface.questions.current._id,
        typeTracker: "Practice",
        answers: [
          _answer._id
        ]
      };
      this.http.put<any>(API.url('answers/check'), _body).subscribe((_check) => {
        this.cdr.detectChanges();
      });
    }
  }

  actionCheckFullQuestionsAnswered(): boolean {
    const _not_answered = this.interface.questions.progress.filter((_question) => !_question.answered);
    return _not_answered.length == 0;
  }

  actionPlaySuccessSound(): void {
    if (this.interface.controls.sound) {
      const _audio = new Audio();
      const sound = Math.floor(Math.random() * 3);
      _audio.src = 'assets/sounds/answer-success_00' + sound + '.mp3';
      _audio.load();
      _audio.play();
    }
  }

  actionPlayWrongSound(): void {
    if (this.interface.controls.sound) {
      const _audio = new Audio();
      const sound = Math.floor(Math.random() * 4);
      _audio.src = 'assets/sounds/answer-wrong_00' + sound + '.wav';
      _audio.load();
      _audio.play();
    }
  }

  actionShowSuccessAnimation(): void {
    this.interface._cache.animations.index = Math.floor(Math.random() * 18);
    this.interface._cache.animations.show = true;
    setTimeout(() => {
      this.interface._cache.animations.show = false;
      this.cdr.detectChanges();
    }, 1800);
  }

  actionOpenVideoExplanation(): void {
    this.store.dispatch(
      new ModalOpenAction('video_4_3', true, this.interface.questions.current.explanation?.video?.link_file),
    );
  }

  actionSelectToAssign() {
    let find = this.assignmentBuilderService.materials.find((value) => value._id === this.interface.quiz._id);
    if (find) {
      return;
    }
    if (this.router.url.includes('drills')) {
      this.store.dispatch(new AssignmentsBuilderAddDrill({ drill: this.interface.questions.list }));
      this.store.select(PracticeState.selectCategoryById(this.interface.quiz._id)).subscribe({
        next: (res) => {
          this.assignmentBuilderService.materials.push(res);
        },
      });
    }
    if (this.router.url.includes('questions')) {
      this.store.dispatch(new AssignmentsBuilderAddQuiz({ quiz: this.interface.questions.list }));
      this.store.select(PracticeState.selectCategoryById(this.interface.quiz._id)).subscribe({
        next: (res) => {
          this.assignmentBuilderService.materials.push(res);
        },
      });
    }
  }

  actionTogglePause(): void {
    this.interface.controls.pause = !this.interface.controls.pause;
  }

  actionToggleSound(): void {
    this.interface.controls.sound = !this.interface.controls.sound;
  }

  actionToggleZoomThumbnail(): void {
    this.interface.controls.zoom = !this.interface.controls.zoom;
  }
}
