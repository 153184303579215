<div class="ui-block ui-block-no-margin ui-block-white col-span-2 row-span-5">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Roles</h2>
      <p>School users by role</p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
  </div>
  <div class="ui-block-inner ui-block-inner-col">
      <div class="ui-school-stat-users">
        <ng-container *ngIf="!interface.loading">
        <div class="ui-school-stat-users-roles">
          <ng-container *ngFor="let role of interface.statistic?.user.roles let a=index">
            <div class="ui-school-stat-users-role">
              <div class="ui-school-stat-users-role-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 002.25-2.25V6.75a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 6.75v10.5a2.25 2.25 0 002.25 2.25zm.75-12h9v9h-9v-9z" />
                </svg>
              </div>
              <div class="ui-school-stat-users-role-name">{{role.name}}</div>
              <div class="ui-school-stat-users-role-count" tooltip description="Show users" [routerLink]="'/control/users/'+role._id">{{role.count}}</div>
            </div>
          </ng-container>
          <ui-no-data *ngIf="interface.statistic?.user.roles.length == 0" text="User roles not found"></ui-no-data>
        </div>
        </ng-container>
        <ng-container *ngIf="interface.loading"><ui-no-data type="loading" text="Loading data" class="min-h-[240px] mt-4 flex-grow"></ui-no-data></ng-container>
      </div>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>
<div class="ui-block ui-block-no-margin ui-block-white col-span-10 row-span-6">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>New users</h2>
      <p>Registered new user statistic by date</p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile gap-4">
      <ui-date-picker [future]="true" class="w-52 h-[44px]"></ui-date-picker>
      <ui-date-picker [future]="true" class="w-52 h-[44px]"></ui-date-picker>
    </div>
  </div>
  <div class="ui-block-inner ui-block-inner-col flex-grow">
    <ng-container *ngIf="!interface.loading">
      <apx-chart
        [colors]="interface.charts.users_register_by_roles.colors"
        [series]="interface.charts.users_register_by_roles.series"
        [chart]="interface.charts.users_register_by_roles.chart"
        [yaxis]="interface.charts.users_register_by_roles.yaxis"
        [xaxis]="interface.charts.users_register_by_roles.xaxis"
        [stroke]="interface.charts.users_register_by_roles.stroke"
        [tooltip]="interface.charts.users_register_by_roles.tooltip"
        [legend]="interface.charts.users_register_by_roles.legend"
        [dataLabels]="interface.charts.users_register_by_roles.dataLabels"
        [plotOptions]="interface.charts.users_register_by_roles.plotOptions"
      ></apx-chart>
    </ng-container>
    <ng-container *ngIf="interface.loading"><ui-no-data type="loading" text="Loading data" class="mt-4 flex-grow"></ui-no-data></ng-container>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>
<div class="ui-block ui-block-no-margin ui-block-white col-span-2">
  <div class="ui-block-header">
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
  </div>
  <div class="ui-block-inner ui-block-inner-col flex-grow">
    <ng-container *ngIf="interface.loading">
      <ng-container *ngIf="interface.loading"><ui-no-data type="loading" text="Loading data" class=""></ui-no-data></ng-container>
    </ng-container>
    <ng-container *ngIf="!interface.loading">
      <div class="ui-school-stat-licenses">
        <div *ngIf="!interface.license" class="ui-school-stat-no-license">
          <p>The school does not have a license, the use of educational content is not available.</p>
          <button class="ui-btn ui-btn-sm ui-btn-blue-outline" (click)="actionOpenAddLicenseModal()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
            <span>Add license</span>
          </button>
        </div>
        <div *ngIf="interface.license" class="ui-school-stat-license">
          <span>License</span>
          <p>{{interface.license?.active? 'Active': 'Deactivated'}}</p>
          <div class="ui-school-stat-license-data">
            <div class="ui-school-stat-license-data-item">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
              </svg>
              <p>Created</p>
              <span>{{interface.license?.startDate | date:'mediumDate'}}</span>
            </div>
            <div class="ui-school-stat-license-data-item">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
              </svg>
              <p>Updated</p>
              <span>{{interface.license?.updatedAt | date:'mediumDate'}}</span>
            </div>
            <div class="ui-school-stat-license-data-item">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
              </svg>
              <p>Expired</p>
              <span>{{interface.license?.endDate | date:'mediumDate'}}</span>
            </div>
            <div class="ui-school-stat-license-data-item">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
              </svg>
              <p>Max users</p>
              <span>{{interface.license?.maxCountUser}}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>
<div class="ui-block ui-block-no-margin ui-block-white col-span-3">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Events</h2>
      <p>Users events</p>
    </div>
  </div>
  <div class="ui-block-inner ui-block-inner-col">
    <ng-container *ngIf="interface.loading"><ui-no-data type="loading" text="Loading data" class="mt-4"></ui-no-data></ng-container>
    <ng-container *ngIf="!interface.loading">
      <div class="ui-school-stat-events">
        <ng-container *ngFor="let event of interface.statistic?.event.group | sort: 'desc': 'count'  let a=index">
          <div class="ui-school-stat-event">
            <div class="ui-school-stat-event-icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 12.75c1.148 0 2.278.08 3.383.237 1.037.146 1.866.966 1.866 2.013 0 3.728-2.35 6.75-5.25 6.75S6.75 18.728 6.75 15c0-1.046.83-1.867 1.866-2.013A24.204 24.204 0 0112 12.75zm0 0c2.883 0 5.647.508 8.207 1.44a23.91 23.91 0 01-1.152 6.06M12 12.75c-2.883 0-5.647.508-8.208 1.44.125 2.104.52 4.136 1.153 6.06M12 12.75a2.25 2.25 0 002.248-2.354M12 12.75a2.25 2.25 0 01-2.248-2.354M12 8.25c.995 0 1.971-.08 2.922-.236.403-.066.74-.358.795-.762a3.778 3.778 0 00-.399-2.25M12 8.25c-.995 0-1.97-.08-2.922-.236-.402-.066-.74-.358-.795-.762a3.734 3.734 0 01.4-2.253M12 8.25a2.25 2.25 0 00-2.248 2.146M12 8.25a2.25 2.25 0 012.248 2.146M8.683 5a6.032 6.032 0 01-1.155-1.002c.07-.63.27-1.222.574-1.747m.581 2.749A3.75 3.75 0 0115.318 5m0 0c.427-.283.815-.62 1.155-.999a4.471 4.471 0 00-.575-1.752M4.921 6a24.048 24.048 0 00-.392 3.314c1.668.546 3.416.914 5.223 1.082M19.08 6c.205 1.08.337 2.187.392 3.314a23.882 23.882 0 01-5.223 1.082" />
              </svg>

            </div>
            <div class="ui-school-stat-event-name">{{event.name}}</div>
            <div class="ui-school-stat-event-count">{{event.count}}</div>
          </div>
        </ng-container>
        <ui-no-data *ngIf="interface.statistic?.event.group.length == 0" text="User events not found" ></ui-no-data>
      </div>
    </ng-container>
  </div>
  <div class="ui-block-footer">
  </div>
</div>
