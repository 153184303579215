import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiVideoplayerComponent} from "./ui-videoplayer.component";
import {PipesModule} from "../../app.pipes.module";



@NgModule({
  declarations: [UiVideoplayerComponent],
  exports: [UiVideoplayerComponent],
  imports: [
    CommonModule,
    PipesModule
  ]
})
export class UiVideoplayerModule { }
