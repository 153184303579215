import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiHomeworkExitComponent } from "./ui-homework-exit.component";



@NgModule({
  declarations: [UiHomeworkExitComponent],
  exports: [UiHomeworkExitComponent],
  imports: [
    CommonModule
  ]
})
export class UiHomeworkExitModule { }
