import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiPageForgotComponent } from "./ui-page-forgot.component";
import { RouterModule } from "@angular/router";
import { AppTranslocoModule } from "../../app.transloco.module";
import { UiTooltipModule } from "../ui-tooltip/ui-tooltip.module";



@NgModule({
  declarations: [UiPageForgotComponent],
  exports: [UiPageForgotComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: UiPageForgotComponent
      }
    ]),
    AppTranslocoModule,
    UiTooltipModule
  ]
})
export class UiPageForgotModule { }
