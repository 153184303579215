import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {PageModel} from "../../_models/page.model";
import {Store} from "@ngxs/store";
import {Router} from "@angular/router";
import {RouterState} from "@ngxs/router-plugin";
import {ModalState} from "../../_stores/modal/_state/modal.state";
import {UserState} from "../../_stores/user/_state/user.state";

@Component({
  selector: 'ui-page-control',
  templateUrl: './ui-page-control.component.html',
  styleUrls: ['./ui-page-control.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiPageControlComponent implements OnInit {
  interface: PageModel = {
    error: false,
    loading: false,
    update: false,
    modal_no_close: false,
    user: undefined
  };
  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.store.select(RouterState.url).subscribe((_url) => {
      if (_url == '/control') {
        this.interface.loading = true;
        this.initUser();
      }
    });
  }

  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.cdr.detectChanges();
    });
  }
  childContentLoad(event): void {
    this.interface.loading = false;
  }
  childContentUnLoad(event): void {
    this.interface.loading = true;
  }


}
