import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Store } from '@ngxs/store';
import { LoadControlUsers, UpdateControlUsersPage } from "../../_stores/control/_actions/control.actions";
import { ControlState } from '../../_stores/control/_state/control.state';
import { ModalOpenAction, ModalOpenActionWithID } from '../../_stores/modal/_actions/modal.actions';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ui-control-users',
  templateUrl: './ui-control-users.component.html',
  styleUrls: ['./ui-control-users.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiControlUsersComponent implements OnInit, OnDestroy {
  interface: any = {
    pages: [],
    currentPage: 1,
    data: [],
    perPage: 0,
    totalData: 0,
    totalPage: 0,
  };
  public role: any;

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
  ) {
    this.role = this.route.snapshot.paramMap.get('role');
    this.activatedRoute.fragment.subscribe((x) => {
      this.interface.currentPage = x;
      this.store.dispatch(new LoadControlUsers(this.interface.currentPage, this.role));
    });
  }

  ngOnDestroy(): void {
    this.store.dispatch(new UpdateControlUsersPage(1));
  }

  ngOnInit(): void {
    this.initData();
  }

  initData(): void {
    this.store.select(ControlState.selectUsers).subscribe((_data) => {
      this.interface.data = _data.data;
      this.interface = {
        ...this.interface,
        currentPage: _data.currentPage,
        totalPage: _data.totalPage,
        totalData: _data.totalData,
        pages: this.getPages(this.interface.currentPage, this.interface.totalPage),
      };
      this.cdr.detectChanges();
    });
  }

  actionOpenAddDataModal(): void {
    this.store.dispatch(new ModalOpenAction('control_user_add'));
  }

  actionOpenEditDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('control_user_edit', _id));
  }

  actionOpenDeleteDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('control_user_delete', _id, false));
  }

  actionFirstPage(): void {
    this.store.dispatch(new LoadControlUsers(1, this.role));
  }
  actionPrevPage(): void {
    this.store.dispatch(new LoadControlUsers(this.interface.currentPage - 1, this.role));
  }
  actionLoadPage(page): void {
    this.store.dispatch(new LoadControlUsers(page));
  }
  actionNextPage(event): void {
    let element = document.querySelector('thead');
    element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    this.interface = {
      ...this.interface,
      currentPage: event,
    };
    this.interface.currentPage = event;
    this.store.dispatch(new LoadControlUsers(this.interface.currentPage, this.role));
    this.cdr.detectChanges();
  }
  actionLastPage(): void {
    this.store.dispatch(new LoadControlUsers(this.interface.totalPage, this.role));
  }
  private getPages(currentPage: number, totalPage: number) {
    if (currentPage <= 10) {
      if (totalPage < 10) {
        return [...Array(totalPage).keys()].map((x) => ++x);
      }
      return [...Array(10).keys()].map((x) => ++x);
    }
    if (currentPage > 10) {
      if (currentPage >= totalPage - 2) {
        return [totalPage - 3, totalPage - 2, totalPage - 1, totalPage];
      } else {
        return [currentPage - 1, currentPage, currentPage + 1];
      }
    }
  }
}
