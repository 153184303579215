import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiControlRoleEditComponent } from './ui-control-role-edit.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [UiControlRoleEditComponent],
  exports: [UiControlRoleEditComponent],
  imports: [CommonModule, TranslocoModule],
})
export class UiControlRoleEditModule {}
