import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngxs/store';

@Component({
  selector: 'ui-pagination',
  templateUrl: './ui-pagination.component.html',
  styleUrls: ['./ui-pagination.component.scss'],
})
export class UiPaginationComponent implements OnInit, OnChanges {
  @Input() pages: number[];
  @Input() currentPage: number;
  @Input() totalPage: number;
  @Output() nextPage = new EventEmitter<number>();

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {}

  actionFirstPage(): void {
    this.pages = [];
    this.nextPage.emit(1);
    this.cdr.detectChanges();
  }

  actionPrevPage(): void {
    this.pages = [];
    this.nextPage.emit(this.currentPage - 1);
    this.cdr.detectChanges();
  }

  actionLoadPage(page): void {
    this.pages = [];
    this.nextPage.emit(page);
    this.cdr.detectChanges();
  }

  actionNextPage(): void {
    this.pages = [];
    this.nextPage.emit(this.currentPage + 1);
    this.cdr.detectChanges();
  }

  actionLastPage(): void {
    this.pages = [];
    this.nextPage.emit(this.totalPage);
    this.cdr.detectChanges();
  }

  private getPages(currentPage: number, totalPage: number) {
    if (currentPage <= 10) {
      return [...Array(10).keys()].map((x) => ++x);
    }
    if (currentPage > 10) {
      if (currentPage >= totalPage - 2) {
        return [totalPage - 3, totalPage - 2, totalPage - 1, totalPage];
      } else {
        return [currentPage - 1, currentPage, currentPage + 1];
      }
    }
  }
}
