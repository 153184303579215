import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RouterState } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { CrumbsState } from '../../_stores/crumbs/_state/crumbs.state';
import { ModalCloseAction } from '../../_stores/modal/_actions/modal.actions';

@Component({
  selector: 'ui-page-crumbs',
  templateUrl: './ui-page-crumbs.component.html',
  styleUrls: ['./ui-page-crumbs.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiPageCrumbsComponent implements OnInit {
  interface = {
    url: '',
    title: ' ',
    subtitle: ' ',
    breadcrumbs: [],
    _cache_list: [],
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initRouteUrl();
  }

  initRouteUrl(): void {
    this.store.select(RouterState.url).subscribe((_url) => {
      this.interface.url = _url;
      this.initDefaultAndClear();
      this.initCrumbsTitle();
    });
  }
  initCrumbsTitle(): void {
    this.store.select(CrumbsState.selectCrumbs).subscribe((crumb) => {
      this.interface.title = crumb.title;
      this.interface.subtitle = crumb.subtitle;
      this.cdr.markForCheck();
    });
  }

  initDefaultAndClear(): void {
    this.store.dispatch(new ModalCloseAction());
    this.interface.title = '';
    this.interface.subtitle = '';
  }
}
