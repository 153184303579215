<div class="ui-control-role-container">
  <div class="ui-block ui-block-white">
    <div class="ui-block-header">
      <div class="ui-block-header-title">
        <h2>Roles</h2>
        <p>Total number of roles: <strong>{{interface.data.length}}</strong></p>
      </div>
      <div class="ui-block-header-action ui-block-header-action-hidden-mobile">
        <button class="ui-btn ui-btn-sm" (click)="actionOpenAddDataModal()">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
               stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
          </svg>
          <span>Add new</span>
        </button>
      </div>
    </div>
    <div class="ui-block-inner ui-block-inner-col my-4">
      <ng-container *ngIf="interface.data.length == 0">
        <ui-no-data text="Loading data..."></ui-no-data>
      </ng-container>
      <ng-container *ngIf="interface.data.length > 0">
        <ng-container [ngTemplateOutlet]="DATA_TABLE_ACTIONS"></ng-container>
        <ng-container [ngTemplateOutlet]="DATA_TABLE"></ng-container>
      </ng-container>
    </div>
    <div class="ui-block-footer">
      <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet">
        <button class="ui-btn ui-btn-sm" (click)="actionOpenAddDataModal()">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
               stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
          </svg>
          <span>Add new</span>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #DATA_TABLE_ACTIONS></ng-template>
<ng-template #DATA_TABLE>
  <table class="ui-table">
    <caption></caption>
    <thead>
    <tr>
      <th><div class="ui-form-checkbox"><input type="checkbox"></div></th>
      <th>Name</th>
      <th>Count of Users</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let item of interface.data | sort:'desc':'count_users'">
      <tr>
        <td>
          <div class="ui-form-checkbox"><input type="checkbox"></div>
        </td>
        <td>
          <div class="flex items-center">
            <div class="font-medium text-slate-900">{{item.name}}</div>
            <div class="text-slate-400"></div>
          </div>
        </td>
        <td>
          <div class="flex items-center justify-center">
            <div class="font-medium text-pap-500 cursor-pointer" tooltip description="Show users" [routerLink]="'/control/users/'+item._id">{{item.count_users}}</div>
            <div class="text-slate-400"></div>
          </div>
        </td>
        <td class="ml-auto">
          <button tooltip title="Edit" class="ui-btn ui-btn-xs ui-btn-icon" (click)="actionOpenEditDataItemModal(item._id)">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
            </svg>
          </button>
          <button tooltip title="Delete" class="ui-btn ui-btn-xs ui-btn-icon ui-btn-error" (click)="actionOpenDeleteDataItemModal(item._id)">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
            </svg>
          </button>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</ng-template>
