import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { LoadControlBooks } from '../../_stores/control/_actions/control.actions';
import { ControlState } from '../../_stores/control/_state/control.state';
import { ModalOpenAction, ModalOpenActionWithID } from '../../_stores/modal/_actions/modal.actions';
import { PracticeState } from '../../_stores/practice/_state/practice.state';

@Component({
  selector: 'ui-control-books',
  templateUrl: './ui-control-books.component.html',
  styleUrls: ['./ui-control-books.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiControlBooksComponent implements OnInit {
  interface: any = {
    grades: [],
    series: [],
    books: [],
    _cache: {
      books: [],
      searched: false,
      open_choose_grade: false,
      open_choose_series: false,
      choose_grade: undefined,
      choose_series: undefined,
    },
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {
    this.store.dispatch(new LoadControlBooks());
  }

  ngOnInit(): void {
    this.initData();
  }

  initData(): void {
    this.store.select(ControlState.selectBooks).subscribe((_books) => {
      this.interface.books = _books;
      this.interface._cache.books = _books;
      this.initGrades();
      this.initSeries();
      this.cdr.detectChanges();
    });
  }

  initGrades(): void {
    this.store.select(PracticeState.selectGrades).subscribe((_grades) => {
      this.interface.grades = _grades;
      this.cdr.detectChanges();
    });
  }

  initSeries(): void {
    this.interface.series = this.interface.books.reduce((series, book) => {
      if (!series.includes(book.product.series)) {
        series.push(book.product.series);
      }
      return series;
    }, []);
  }

  actionOpenAddDataModal(): void {
    this.store.dispatch(new ModalOpenAction(''));
  }

  actionOpenEditDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('', _id));
  }

  actionOpenDeleteDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('', _id));
  }

  actionSearchBooks(event): void {
    if (event.target.value.length === 0) {
      this.interface.books = this.interface._cache.books;
      this.interface._cache.searched = false;
    } else {
      this.interface._cache.searched = true;
      this.actionClearChooseGrade();
      this.actionClearChooseSerie();
      this.interface.books = this.interface._cache.books.filter((item) => {
        return item.title.toLowerCase().includes(event.target.value.toLowerCase());
      });
    }
  }
  actionToggleChooseGrade(): void {
    this.interface._cache.open_choose_grade = !this.interface._cache.open_choose_grade;
  }
  actionToggleChooseSeries(): void {
    this.interface._cache.open_choose_series = !this.interface._cache.open_choose_series;
  }
  actionSearchBooksByGrade(grade): void {
    this.actionClearChooseSerie();
    this.interface._cache.open_choose_grade = false;
    this.interface._cache.choose_grade = grade;
    this.interface.books = this.interface._cache.books.filter((item) => {
      return item.product.grade.replace(/-/g, '_').includes(grade.slug);
    });
  }
  actionSearchBooksBySerie(serie): void {
    this.actionClearChooseGrade();
    this.interface._cache.open_choose_series = false;
    this.interface._cache.choose_serie = serie;
    this.interface.books = this.interface._cache.books.filter((item) => {
      return item.product.series == serie;
    });
  }

  actionClearChooseGrade(): void {
    this.interface._cache.choose_grade = undefined;
    this.interface.books = this.interface._cache.books;
  }
  actionClearChooseSerie(): void {
    this.interface._cache.choose_serie = undefined;
    this.interface.books = this.interface._cache.books;
  }
}
