import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiPageFooterComponent} from "./ui-page-footer.component";
import {AppTranslocoModule} from "../../app.transloco.module";
import {RouterModule} from "@angular/router";



@NgModule({
  declarations: [UiPageFooterComponent],
  exports: [UiPageFooterComponent],
    imports: [
        CommonModule,
        AppTranslocoModule,
        RouterModule
    ]
})
export class UiPageFooterModule { }
