<div class="ui-reports-sidebar">
    <div class="ui-reports-classes">
      <div class="ui-reports-class-all" [class.ui-reports-class-all-active]="interface.class == null" (click)="actionSelectClass(null)">
        <div class="ui-reports-class-all-icons">
          <div class="ui-reports-class-all-icon">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
            </svg>
          </div>
          <div class="ui-reports-class-all-icon">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6" />
            </svg>
          </div>
          <div class="ui-reports-class-all-icon">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
            </svg>
          </div>
        </div>
        <div class="ui-reports-class-all-name">
          <h2>All statistics</h2>
          <p>{{interface.classes.length}} classes</p>
        </div>
      </div>
      <div class="ui-reports-class-divider">
        <div class="ui-reports-class-divider-line" aria-hidden="true">
          <span></span>
        </div>
        <div class="ui-reports-class-divider-text ">
          <span>Or only for class</span>
        </div>
        <div class="ui-reports-class-divider-line" aria-hidden="true">
          <span></span>
        </div>
      </div>
      <ng-container *ngFor="let _class of interface.classes | sort: 'desc': 'createdAt'; let cl=index">
        <div class="ui-reports-class" [class.ui-reports-class-active]="interface.class?._id == _class._id" (click)="actionSelectClass(_class)">
          <div class="ui-reports-class-header">
            <div class="ui-reports-class-header-title">
              <div class="ui-reports-class-header-title-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                </svg>
              </div>
              <div class="ui-reports-class-header-title-text">
                <h3>{{_class.title}}</h3>
                <p>{{_class.countAssignment}} Assignments</p>
              </div>
            </div>
          </div>
          <div class="ui-reports-class-students">
            <ng-container *ngFor="let student of _class.students | slice: 0:5; let s=index">
              <div class="ui-reports-class-student">
                <img  [src]="student.avatar" alt="{{student.displayName}}" tooltip description="{{student.displayName}}"/>
              </div>
            </ng-container>
            <div *ngIf="_class.students?.length > 5"
                 class="ui-reports-class-student ui-reports-class-student-label">
              +{{_class.students?.length - 5}}
            </div>
            <div *ngIf="_class.students?.length == 0" class="ui-reports-class-student ui-reports-class-no-student-label">No students from class</div>
          </div>
          <div class="ui-reports-class-progress">
            <p>
              <span>Progress: {{getTotalClassProgress(_class)}}%</span>
              <span>Done: {{_class.doneCountAssignment}} / {{_class.countAssignment}}</span>
            </p>
            <progress [max]="_class.countAssignment" [value]="_class.doneCountAssignment"></progress>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="interface.classes.length == 0">
        <ui-no-data type="loading" text="loading classes"></ui-no-data>
      </ng-container>
    </div>
</div>
<div class="ui-reports-content">
  <div class="ui-reports-students">
    <button class="ui-btn ui-btn-sm" (click)="actionSelectStudent(null)" [class.ui-btn-pap]="interface.student == null" tooltip description="Statistics from all students">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
      </svg>
      <span>All Students</span>
    </button>
    <ng-container *ngFor="let student of interface.students; let a=index">
      <div class="ui-reports-student" [class.ui-reports-student-active]="student._id == interface.student?._id">
        <img  [src]="student.avatar" alt="{{student.displayName}}" tooltip description="Statistic from {{student.displayName}}" (click)="actionSelectStudent(student)"/>
      </div>
    </ng-container>
  </div>
  <div class="ui-block ui-block-white ui-block-no-margin sm:col-span-12">
    <div class="ui-block-header">
      <div class="ui-block-header-title">
        <h2>{{interface.student? interface.student.displayName +' progress' : 'Student Activity & Progress'}}</h2>
        <p>{{interface.class?.title || 'All classes'}}</p>
      </div>
      <div class="ui-block-header-action ui-block-header-action-hidden-mobile gap-2 relative">
        <button class="ui-btn ui-btn-sm" (click)="actionSelectPeriod('day')" [class.ui-btn-pap]="interface.period == 'day'" tooltip description="Statistic by day">
          <span>Day</span>
        </button>
        <button class="ui-btn ui-btn-sm" (click)="actionSelectPeriod('week')" [class.ui-btn-pap]="interface.period == 'week'" tooltip description="Statistic by week">
          <span>Week</span>
        </button>
        <button class="ui-btn ui-btn-sm" (click)="actionSelectPeriod('month')" [class.ui-btn-pap]="interface.period == 'month'" tooltip description="Statistic by month">
          <span>Month</span>
        </button>
        <ui-date-picker [future]="true" [date]="interface._cache.period.start" class="ui-reports-period" [class.ui-reports-period-active]="interface.period == 'custom'" (selectedDate)="actionSelectPeriodDate($event)"></ui-date-picker>
      </div>
    </div>
    <div class="ui-block-inner ui-block-inner-col flex-grow">
      <div *ngIf="!interface.loading" class="ui-reports-trackers">
        <div class="ui-reports-trackers-shorts">
          <div class="ui-reports-trackers-short">
            <label>
              <h3>{{interface.report.short.quiz}}q</h3>
              <span>/</span>
              <small>{{interface.period}}</small>
            </label>
            <p>Passed Quizzes</p>
          </div>
          <div class="ui-reports-trackers-short">
            <label>
              <h3>{{interface.report.short.video}}v</h3>
              <span>/</span>
              <small>{{interface.period}}</small>
            </label>
            <p>Viewed videos</p>
          </div>
          <div class="ui-reports-trackers-short">
            <label>
              <h3>{{interface.report.short.worksheet}}w</h3>
              <span>/</span>
              <small>{{interface.period}}</small>
            </label>
            <p>Passed Worksheets</p>
          </div>
          <div class="ui-reports-trackers-short">
            <label>
              <h3>{{interface.report.short.book_pages}}p</h3>
              <span>/</span>
              <small>{{interface.period}}</small>
            </label>
            <p>Read book pages</p>
          </div>
        </div>
        <div class="ui-reports-trackers-data">
          <apx-chart
            [series]="interface.report.trackers.series"
            [chart]="interface.report.trackers.chart"
            [dataLabels]="interface.report.trackers.dataLabels"
            [plotOptions]="interface.report.trackers.plotOptions"
            [yaxis]="interface.report.trackers.yaxis"
            [legend]="interface.report.trackers.legend"
            [fill]="interface.report.trackers.fill"
            [stroke]="interface.report.trackers.stroke"
            [tooltip]="interface.report.trackers.tooltip"
            [xaxis]="interface.report.trackers.xaxis"
            [colors]="interface.report.trackers.colors"
          ></apx-chart>
        </div>
      </div>
      <ui-no-data *ngIf="interface.loading" type="loading" text="Loading data" class="mt-4 h-[453px] flex-grow"></ui-no-data>
    </div>
    <div class="ui-block-footer">
      <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet">
        <button class="ui-btn ui-btn-sm ui-btn-blue-outline">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
          </svg>
          <span>Button</span>
        </button>
      </div>
    </div>
  </div>
  <div class="ui-block ui-block-white ui-block-no-margin col-span-6 xl:col-span-6 2xl:col-span-3">
    <div class="ui-block-header"></div>
    <div class="ui-block-inner ui-block-inner-col">
      <div *ngIf="!interface.loading" class="ui-reports-total">
        <div class="ui-reports-total-title">Assignments</div>
        <div class="ui-reports-total-counter">{{interface.report.assignments.total}}</div>
        <div class="ui-reports-total-data">
          <div class="ui-reports-total-data-item">
            <div class="ui-reports-total-data-item-icon bg-emerald-100 text-emerald-700">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
              </svg>

            </div>
            <div class="ui-reports-total-data-item-name">Complete</div>
            <div class="ui-reports-total-data-item-count">{{interface.report.assignments.complete}}</div>
          </div>
          <div class="ui-reports-total-data-item">
            <div class="ui-reports-total-data-item-icon bg-rose-100 text-rose-500">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M11.412 15.655L9.75 21.75l3.745-4.012M9.257 13.5H3.75l2.659-2.849m2.048-2.194L14.25 2.25 12 10.5h8.25l-4.707 5.043M8.457 8.457L3 3m5.457 5.457l7.086 7.086m0 0L21 21" />
              </svg>
            </div>
            <div class="ui-reports-total-data-item-name">Not completed</div>
            <div class="ui-reports-total-data-item-count">{{interface.report.assignments.incomplete}}</div>
          </div>
        </div>
      </div>
      <ui-no-data *ngIf="interface.loading" type="loading" text="Loading data" class="h-[204px]"></ui-no-data>
    </div>
    <div class="ui-block-footer"></div>
  </div>
  <div class="ui-block ui-block-white ui-block-no-margin col-span-6 xl:col-span-6 2xl:col-span-3">
    <div class="ui-block-header"></div>
    <div class="ui-block-inner ui-block-inner-col">
      <div *ngIf="!interface.loading" class="ui-reports-total">
        <div class="ui-reports-total-title">Worksheets</div>
        <div class="ui-reports-total-counter">{{interface.report.worksheets.total}}</div>
        <div class="ui-reports-total-data">
          <div class="ui-reports-total-data-item">
            <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-400">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
              </svg>
            </div>
            <div class="ui-reports-total-data-item-name">Download</div>
            <div class="ui-reports-total-data-item-count">{{interface.report.worksheets.download}}</div>
          </div>
          <div class="ui-reports-total-data-item">
            <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-400">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
              </svg>
            </div>
            <div class="ui-reports-total-data-item-name">Printed</div>
            <div class="ui-reports-total-data-item-count">{{interface.report.worksheets.print}}</div>
          </div>
        </div>
      </div>
      <ui-no-data *ngIf="interface.loading" type="loading" text="Loading data" class="h-[204px]"></ui-no-data>
    </div>
    <div class="ui-block-footer"></div>
  </div>
  <div class="ui-block ui-block-white ui-block-no-margin col-span-6 xl:col-span-6 2xl:col-span-3">
    <div class="ui-block-header"></div>
    <div class="ui-block-inner ui-block-inner-col">
      <div *ngIf="!interface.loading" class="ui-reports-total">
        <div class="ui-reports-total-title">Books</div>
        <div class="ui-reports-total-counter">{{interface.report.books.total}}</div>
        <div class="ui-reports-total-data">
          <div class="ui-reports-total-data-item">
            <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-400">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
              </svg>
            </div>
            <div class="ui-reports-total-data-item-name">Download</div>
            <div class="ui-reports-total-data-item-count">{{interface.report.books.download}}</div>
          </div>
          <div class="ui-reports-total-data-item">
            <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-400">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
              </svg>
            </div>
            <div class="ui-reports-total-data-item-name">Printed</div>
            <div class="ui-reports-total-data-item-count">{{interface.report.books.print}}</div>
          </div>
        </div>
      </div>
      <ui-no-data *ngIf="interface.loading" type="loading" text="Loading data" class="h-[204px]"></ui-no-data>
    </div>
    <div class="ui-block-footer"></div>
  </div>
  <div class="ui-block ui-block-white ui-block-no-margin col-span-6 xl:col-span-6 2xl:col-span-3">
    <div class="ui-block-header"></div>
    <div class="ui-block-inner ui-block-inner-col">
      <div *ngIf="!interface.loading" class="ui-reports-total">
        <div class="ui-reports-total-title">Average Session Time</div>
        <div class="ui-reports-total-counter text-center my-auto">{{interface.report.session.time}}</div>
        <div class="ui-reports-total-data">
        </div>
      </div>
      <ui-no-data *ngIf="interface.loading" type="loading" text="Loading data" class="h-[204px]"></ui-no-data>
    </div>
    <div class="ui-block-footer"></div>
  </div>
  <div class="ui-block ui-block-white ui-block-no-margin col-span-12 xl:col-span-6">
    <div class="ui-block-header">
      <div class="ui-block-header-title">
        <h2>Assignment Activity Feed</h2>
        <p>{{interface.student? interface.student.displayName +' do the following assignment materials' : 'Activity of students who have started but not completed an assignment'}}</p>
      </div>
      <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
    </div>
    <div class="ui-block-inner ui-block-inner-col">
      <div *ngIf="!interface.loading" class="ui-reports-goings">
        <ng-container *ngFor="let going of interface.report.goings | sort: 'desc': 'createdAt'; let a=index">
          <div class="ui-reports-going">
            <div class="ui-reports-going-icon" [ngSwitch]="going.typeMaterial">
              <svg *ngSwitchCase="'Question'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
              </svg>
              <svg *ngSwitchCase="'Worksheet'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
              </svg>
              <svg *ngSwitchCase="'Video'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
              </svg>
              <svg *ngSwitchCase="'Book'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
              </svg>
            </div>
            <div class="ui-reports-going-data">
              <p><b>{{going.user?.displayName}}</b>
                <ng-container [ngSwitch]="going.typeMaterial">
                  <ng-container *ngSwitchCase="'Question'">Started homework quiz but did not answers for all questions</ng-container>
                  <ng-container *ngSwitchCase="'Worksheet'">Opened homework worksheet but did not submit his work</ng-container>
                  <ng-container *ngSwitchCase="'Video'">Started watching homework video but didn't finish watching</ng-container>
                  <ng-container *ngSwitchCase="'Book'">Opened homework books pages but did not submit his work</ng-container>
                </ng-container>
              </p>
              <span>{{going.createdAt | date: 'medium'}}</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="interface.report.goings.length == 0">
          <ui-no-data text="No assignments"></ui-no-data>
        </ng-container>
      </div>
      <ui-no-data *ngIf="interface.loading" type="loading" text="Loading data" class="h-[204px] mt-4"></ui-no-data>
    </div>
    <div class="ui-block-footer">
      <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
    </div>
  </div>
  <div class="ui-block ui-block-white ui-block-no-margin col-span-12 xl:col-span-6">
    <div class="ui-block-header">
      <div class="ui-block-header-title">
        <h2>{{interface.student? interface.student.displayName +' activity' : 'General Activity Feed'}}</h2>
        <p>{{interface.student? interface.student.displayName +' activities from the system' : 'Here you can find the most recent events students are doing'}} </p>
      </div>
      <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
    </div>
    <div class="ui-block-inner ui-block-inner-col">
      <div *ngIf="!interface.loading" class="ui-reports-activities">
        <ng-container *ngFor="let activity of interface.report.activity | sort: 'desc': 'createdAt'; let a=index">
          <div class="ui-reports-activity">
            <div class="ui-reports-activity-icon ui-reports-activity-icon-{{activity.type}}" [ngSwitch]="activity.type">
              <svg *ngSwitchCase="'WorksheetDownloaded'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25" />
              </svg>
              <svg *ngSwitchCase="'BookOpened'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
              </svg>
              <svg *ngSwitchCase="'PracticeTrackerStart'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
              </svg>
              <svg *ngSwitchCase="'PracticeTrackerEnd'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
              </svg>
              <svg *ngSwitchCase="'InviteToSchoolLinkCopied'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
              </svg>
              <svg *ngSwitchCase="'OpenModalChangeLanguage'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
              </svg>
              <svg *ngSwitchCase="'PrepTrackerStart'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
              </svg>
              <svg *ngSwitchCase="'PrepTrackerEnd'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
              </svg>
              <svg *ngSwitchCase="'BookPrinted'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
              </svg>
              <svg *ngSwitchCase="'ClassCreated'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
              </svg>
              <svg *ngSwitchCase="'AssignmentCreated'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
              </svg>

              <svg *ngSwitchDefault xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
              </svg>

            </div>
            <div class="ui-reports-activity-data">
              <span>{{activity.createdAt | date: 'medium'}}</span>
              <p>
                <b>{{activity.user?.displayName || 'SOMEWERE'}}</b>
                <ng-container [ngSwitch]="activity.type">
                  <ng-container *ngSwitchCase="'WorksheetDownloaded'">Download worksheet</ng-container>
                  <ng-container *ngSwitchCase="'BookOpened'">Open interactive book</ng-container>
                  <ng-container *ngSwitchCase="'PracticeTrackerStart'">Started doing practice</ng-container>
                  <ng-container *ngSwitchCase="'PracticeTrackerEnd'">Complete practice</ng-container>
                  <ng-container *ngSwitchCase="'InviteToSchoolLinkCopied'">Copied invite link to his school</ng-container>
                  <ng-container *ngSwitchCase="'OpenModalChangeLanguage'">Opened changes language for system video explanation from dashboard</ng-container>
                  <ng-container *ngSwitchCase="'PrepTrackerStart'">Started doing the task from homework:</ng-container>
                  <ng-container *ngSwitchCase="'PrepTrackerEnd'">Complete doing the task from homework:</ng-container>
                  <ng-container *ngSwitchCase="'ClassCreated'">Created Class</ng-container>
                  <ng-container *ngSwitchCase="'AssignmentCreated'">Created Assignment</ng-container>
                  <ng-container *ngSwitchCase="'BookPrinted'">Printing {{activity.resource[0]?.pages_count}} pages from </ng-container>
                  <ng-container *ngSwitchDefault>{{activity.type}}</ng-container>
                </ng-container>
                <ng-container [ngSwitch]="activity.type">
                  <ng-container *ngSwitchCase="'WorksheetDownloaded'"><strong>{{activity.resourceData[0]?.title}}</strong></ng-container>
                  <ng-container *ngSwitchCase="'BookOpened'"><strong>{{activity.resourceData[0]?.title}}</strong></ng-container>
                  <ng-container *ngSwitchCase="'PracticeTrackerStart'"><strong>{{activity.resourceData[0]?.title}}</strong></ng-container>
                  <ng-container *ngSwitchCase="'PracticeTrackerEnd'"><strong>{{activity.resourceData[0]?.title}}</strong></ng-container>
                  <ng-container *ngSwitchCase="'PrepTrackerStart'"><strong>{{activity.resourceData[0]?.title}}</strong></ng-container>
                  <ng-container *ngSwitchCase="'PrepTrackerEnd'"><strong>{{activity.resourceData[0]?.title}}</strong></ng-container>
                  <ng-container *ngSwitchCase="'BookPrinted'"><strong>{{activity.resourceData[0]?.title}}</strong></ng-container>
                </ng-container>
              </p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="interface.report.activity.length == 0">
          <ui-no-data text="No student activity"></ui-no-data>
        </ng-container>
      </div>
      <ui-no-data *ngIf="interface.loading" type="loading" text="Loading data" class="h-[204px] mt-4"></ui-no-data>
    </div>
    <div class="ui-block-footer">
      <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
    </div>
  </div>
</div>
