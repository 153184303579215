<div class="ui-control-user-edit-container">
  <div class="ui-form">
    <div class='ui-form-select' [class.ui-form-select-error]="interface._cache.error_fields.includes('school')">
      <svg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='1.5'>
        <path d='M12 14l9-5-9-5-9 5 9 5z' />
        <path d='M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z' />
        <path stroke-linecap='round' stroke-linejoin='round' d='M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222' />
      </svg>
      <input id='school' name='school' type='text' readonly [value]="interface._cache.selected_school" placeholder="{{'inputs.school' | transloco}}" (click)="actionToggleSearchSchool()">
      <div class="ui-form-select-list" [class.ui-form-select-list-open]="interface._cache.open_search_school">
        <div class='ui-form-input'>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
          <input id='school_search' name='school_search' type='text' required (keyup)='actionSearchSchool($event)' placeholder="{{'inputs.school_search' | transloco}}">
        </div>
        <div class="ui-form-select-list-items ui-select-school-list">
          <ng-container *ngIf="interface.schools.length == 0">
            <div class="ui-form-select-list-item">
              <p>Not found</p>
              <span>No schools from your request</span>
            </div>
          </ng-container>
          <ng-container *ngFor='let school of interface.schools'>
            <div class="ui-form-select-list-item" (click)="actionSelectSchool(school)">
              <p>{{school.schoolName}}</p>
              <span>{{school.address}}, {{school.city}}</span>
            </div>
          </ng-container>
        </div>

      </div>
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" (click)="actionToggleSearchSchool()">
        <path *ngIf="!interface._cache.open_search_school" stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
        <path *ngIf="interface._cache.open_search_school" stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
      </svg>
    </div>
    <div class="ui-form-select">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
        <path d="M12 14l9-5-9-5-9 5 9 5z" />
        <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
      </svg>
      <input name="grades" type="text" readonly [value]="interface._cache.grade_selected?.title" placeholder="Select grade" (click)="actionToggleSelectGrade()">
      <div class="ui-form-select-list" [class.ui-form-select-list-open]="interface._cache.open_select_grade">
        <div class="ui-form-select-list-items ui-select-school-list">
          <ng-container *ngFor="let grade of interface.grades | sort:'asc':'order'">
            <div class="ui-form-select-list-item" (click)="actionSelectGrade(grade)">
              <p>{{grade.title}}</p>
            </div>
          </ng-container>
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 pointer-events-none" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" >
        <path *ngIf="!interface._cache.open_select_grade" stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
        <path *ngIf="interface._cache.open_select_grade" stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
      </svg>
    </div>
    <div class="ui-form-avatar">
      <div class="ui-form-avatar-current" tooltip description="Select your avatar from list">
        <svg *ngIf="!interface.user.avatar" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
        <img *ngIf="interface.user.avatar" src="{{interface.user.avatar}}" alt="" loading="lazy">
      </div>
      <div class="ui-form-avatars-list">
        <ng-container *ngFor="let avatar of [].constructor(12); let a=index">
          <img src="https://cdn.argoprep.com/avatars/avatar-{{a}}.svg" alt="{{a}}" loading="lazy" (click)="actionSelectAvatar(a)">
        </ng-container>
      </div>
    </div>
    <div class="ui-form-radio-group">
      <ng-container *ngFor='let role of interface.roles'>
        <div class="ui-form-radio ui-form-radio-xs ui-form-radio-compact" [class.ui-form-radio-selected]="interface.user.role._id == role._id" (click)="actionSelectRole(role)">
          <input name="firstname" type="radio" [checked]="interface.user.role._id == role._id">
          <span>{{role.name | titlecase}}</span>
        </div>
      </ng-container>
    </div>

    <div class="ui-form-input-group ui-form-input-group-row justify-between">
      <div class="ui-form-input ui-form-input-small ui-form-input-no-pr">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
        <input name="firstname" type="text" [value]="interface.user.firstName" placeholder="Firstname" (keyup)="actionEnterFirstName($event)">
      </div>
      <div class="ui-form-input ui-form-input-small ui-form-input-no-pr">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
        <input name="lastname" type="text" [value]="interface.user.lastName" placeholder="Lastname" (keyup)="actionEnterLastName($event)">
      </div>
    </div>
    <div class="ui-form-input-group">
      <div class="ui-form-input">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" class="h-6 w-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
        </svg>
        <input name="login" type="text" placeholder="Login" [value]="interface.user.email" (keyup)="actionEnterLogin($event)">
      </div>
    </div>
    <div class="ui-form-input-group">
      <div class="ui-form-input">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
        </svg>
        <input name="password" type="text" placeholder="Password" [value]="interface.user.password || ''" (keyup)="actionEnterPassword($event)">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-auto" tooltip description="Generate new password" (click)="actionGeneratePassword()">
          <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12c0-1.232.046-2.453.138-3.662a4.006 4.006 0 013.7-3.7 48.678 48.678 0 017.324 0 4.006 4.006 0 013.7 3.7c.017.22.032.441.046.662M4.5 12l-3-3m3 3l3-3m12 3c0 1.232-.046 2.453-.138 3.662a4.006 4.006 0 01-3.7 3.7 48.657 48.657 0 01-7.324 0 4.006 4.006 0 01-3.7-3.7c-.017-.22-.032-.441-.046-.662M19.5 12l-3 3m3-3l3 3" />
        </svg>
      </div>
      <div class="ui-form-text ui-form-text-xs">If you don't want to change your password, leave the field blank.</div>
    </div>
  </div>
  <div class="ui-control-user-edit-actions">
    <button class="ui-school-license-action-no" (click)="actionClose()">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
      </svg>
      <span>Cancel</span>
    </button>
    <button class="ui-school-license-action-yes" (click)="actionConfirm()" [disabled]="interface.loading">
      <svg *ngIf="!interface.loading" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
      </svg>
      <svg *ngIf="interface.loading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      <span>Confirm</span>
    </button>
  </div>
</div>

