import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {PageQuestionsResultModel} from "../../_models/page-questions-result.model";
import {Store} from "@ngxs/store";
import {UserState} from "../../_stores/user/_state/user.state";
import {PracticeState} from "../../_stores/practice/_state/practice.state";
import { ClearQuizQuestions, ClearQuizQuestionsResult } from "../../_stores/practice/_actions/practice.actions";

@Component({
  selector: 'ui-page-questions-result',
  templateUrl: './ui-page-questions-result.component.html',
  styleUrls: ['./ui-page-questions-result.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiPageQuestionsResultComponent implements OnInit {
  interface: PageQuestionsResultModel = {
    progress: {
      list: [],
      statistic: {
        answered: 0,
        correct: 0,
        wrong: 0
      }
    },
    questions: [],
    user: undefined,
    _cache: {}

  }
  constructor(private store: Store,private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.initUser();
    this.initQuestions();
    this.initResult();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ClearQuizQuestions());
    this.store.dispatch(new ClearQuizQuestionsResult());
  }

  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.cdr.detectChanges();
    });
  }
  initQuestions(): void {
    this.store.select(PracticeState.selectQuestions).subscribe((_questions) => {
      this.interface.questions = _questions;
      this.cdr.detectChanges();
    });
  }
  initResult(): void {
    this.store.select(PracticeState.selectQuestionsResult).subscribe((_result) => {
      this.interface.progress.list = _result;
      this.interface.progress.list.forEach(_question => {
        if (_question.answered) {
          this.interface.progress.statistic.answered++;
          if (_question.correct) {
            this.interface.progress.statistic.correct++;
          } else {
            this.interface.progress.statistic.wrong++;
          }
        }
      });
      this.cdr.detectChanges();
    });
  }

  getQuestionProgress(question) {
    return this.interface.progress.list.find(_question => _question.id === question.id);
  }

}
