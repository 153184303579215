import { ClassModel } from '../../../_models/class.model';

export class GetClassList {
  public static readonly type = '[Class] Get Class List';
}

export class ClearClasses {
  public static readonly type = '[Class] ClearClasses';
}

export class GetClass {
  public static readonly type = '[Class] Get Class';
  constructor(public payload: { id: string }) {}
}

export class CreateClass {
  public static readonly type = '[Class] Create Class';
  constructor(public payload: { title: string }) {}
}

export class UpdateClass {
  public static readonly type = '[Class] Update Class';
  constructor(public payload: { classItem: ClassModel }) {}
}

export class DeleteClass {
  public static readonly type = '[Class] Delete Class';
  constructor(public payload: { deletedClassId: string }) {}
}

export class SetClassList {
  public static readonly type = '[Class] Set Class List';
  constructor(public payload: { classItems: ClassModel[] }) {}
}
