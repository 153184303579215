import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '../../api';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  public login(email, password) {
    return this.http.post<any>(API.url('auth/login'), { email, password });
  }

  public logout() {
    return this.http.post<any>(API.url('auth/logout'), {});
  }

  public status() {
    return this.http.get<any>(API.url('auth/status'));
  }

  public token() {
    return this.http.get<any>(API.url('auth/token'));
  }

  public forgot(email) {
    return this.http.post<any>(API.url('auth/forgot'), { email });
  }
}
