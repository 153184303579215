export class CurrentUsers {
  static readonly type = '[User] CurrentUsers';
}
export class GetTeachersStudents {
  static readonly type = '[User] Get teacher`s students';
}

export class GetTeachersStudentsPagination {
  static readonly type = '[User] Get teacher`s students';
  constructor(public page: number) {}
}
export class GetTeachersParents {
  static readonly type = '[User] GetTeachersParents';
}
export class ClearUsers {
  public static readonly type = '[User] ClearUsers';
  constructor() {}
}
export class GetUserById {
  public static readonly type = '[User] Get user by id';
  constructor(public id: string) {}
}

export class FillCurrentUsers {
  public static readonly type = '[User] FillCurrentUsers';
  constructor(public users: any) {}
}
export class SetCurrentUser {
  public static readonly type = '[User] SetCurrentUser';
  constructor(public index: number) {}
}
export class SetGlobalUser {
  public static readonly type = '[User] SetGlobalUser';
  constructor(public index: number) {}
}

export class SetPracticeToUser {
  public static readonly type = '[User] SetPracticeToUser';
  constructor(public practice_id: number) {}
}

export class AddUserToCurrentUsers {
  public static readonly type = '[User] AddUserToCurrentUsers';
  constructor(public user: any) {}
}

export class SetUserLanguage {
  public static readonly type = '[User] SetUserLanguage';
  constructor(public language: string) {}
}

export class CheckUserSchoolLicense {
  public static readonly type = '[User] CheckUserSchoolLicense';
  constructor() {}
}
