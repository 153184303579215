import { AssignmentModel, CreateAssignmentDataModel } from '../../../_models/assignment.model';

export class CreateAssignment {
  public static readonly type = '[Assignments] Create Assignment';
  constructor(public payload: { data: CreateAssignmentDataModel }) {}
}

export class EditAssignment {
  public static readonly type = '[Assignments] Edit Assignment';
  constructor(public payload: { data: AssignmentModel }) {}
}
export class GetAssignment {
  public static readonly type = '[Assignments] Get Assignment';
  constructor(public payload: { classID: string; assignmentID: string }) {}
}
export class DeleteAssignment {
  public static readonly type = '[Assignments] Delete Assignment';
  constructor(public payload: { assignmentID: string }) {}
}
export class GetAssignmentList {
  public static readonly type = '[Assignments] Get Assignment List';
}
export class GetHomeworks {
  public static readonly type = '[Assignments] Get Homework List';
}

export class ClearAssignments {
  public static readonly type = '[Assignments] ClearAssignments';
}
