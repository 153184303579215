import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiPageAssignmentComponent } from './ui-page-assignment.component';
import { RouterModule } from '@angular/router';
import { UiAppNoDataModule } from '../ui-app-no-data/ui-app-no-data.module';
import { UiTooltipModule } from '../ui-tooltip/ui-tooltip.module';
import { PipesModule } from "../../app.pipes.module";
import { KatexModule } from "ng-katex";
import { UiQuizResultModule } from "../ui-quiz-result/ui-quiz-result.module";

@NgModule({
  declarations: [UiPageAssignmentComponent],
  exports: [UiPageAssignmentComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: UiPageAssignmentComponent
      }
    ]),
    UiTooltipModule,
    UiAppNoDataModule,
    PipesModule,
    KatexModule,
    UiQuizResultModule
  ]
})
export class UiPageAssignmentModule {}
