import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiVideosComponent} from "./ui-videos.component";
import {PipesModule} from "../../app.pipes.module";
import {UiVideoplayerModule} from "../ui-videoplayer/ui-videoplayer.module";



@NgModule({
  declarations: [UiVideosComponent],
  exports: [UiVideosComponent],
  imports: [
    CommonModule,
    PipesModule,
    UiVideoplayerModule
  ]
})
export class UiVideosModule { }
