import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoadPrivateGuard } from './_guards/load-private.guard';
import { LoadPublicGuard } from './_guards/load-puplic.guard';
import { ActivatePrivateGuard } from './_guards/activate-private.guard';

const routerOptions: ExtraOptions = {
  enableTracing: false,
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
  paramsInheritanceStrategy: 'always',
  onSameUrlNavigation: 'reload',
  scrollOffset: [0, 30],
};

const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, routerOptions),
    RouterModule.forChild([
      {
        path: 'auth',
        canLoad: [LoadPublicGuard],
        loadChildren: () => import('./_ui/ui-page-auth/ui-page-auth.module').then((mod) => mod.UiPageAuthModule),
      },
      {
        path: 'forgot',
        canLoad: [LoadPublicGuard],
        loadChildren: () => import('./_ui/ui-page-forgot/ui-page-forgot.module').then((mod) => mod.UiPageForgotModule),
      },
      {
        path: 'register',
        canLoad: [LoadPublicGuard],
        loadChildren: () => import('./_ui/ui-page-register/ui-page-register.module').then((mod) => mod.UiPageRegisterModule),
      },
      {
        path: 'invite',
        canLoad: [LoadPublicGuard],
        loadChildren: () => import('./_ui/ui-page-invite/ui-page-invite.module').then((mod) => mod.UiPageInviteModule),
      },
      {
        path: '',
        children: [
          {
            path: '',
            canLoad: [LoadPrivateGuard],
            canActivate: [ActivatePrivateGuard],
            loadChildren: () => import('./_ui/ui-page/ui-page.module').then((mod) => mod.UiPageModule),
          },
          {
            path: '**',
            redirectTo: '/',
          },
        ],
      },
    ]),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
