import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { AssignmentModel } from "../../_models/assignment.model";
import { Store } from "@ngxs/store";
import { AssignmentsState } from "../../_stores/asignments/_state/assignments.state";
import { EditAssignment } from "../../_stores/asignments/_actions/asignments.actions";
import { ModalCloseAction } from "../../_stores/modal/_actions/modal.actions";

@Component({
  selector: 'ui-assignment-unarchive',
  templateUrl: './ui-assignment-unarchive.component.html',
  styleUrls: ['./ui-assignment-unarchive.component.scss']
})
export class UiAssignmentUnarchiveComponent implements OnInit {
  interface: {
    _id: null,
    loading: boolean,
    assignment: AssignmentModel
  } = {
    _id: null,
    loading: false,
    assignment: undefined
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initAssignment();
  }

  initAssignment(): void {
    this.store.select(AssignmentsState.selectAssignmentsByModalId).subscribe((_assignment) => {
      this.interface.assignment = {..._assignment};
      this.interface.assignment.options = {...this.interface.assignment.options, isActive: true};
    });
  }

  actionConfirm(): void {
    this.interface.loading = true;
    this.store.dispatch(new EditAssignment({ data: this.interface.assignment })).subscribe((value) => {
      this.interface.loading = false;
      this.store.dispatch(new ModalCloseAction());
      this.cdr.detectChanges();
    });
  }

  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }
}
