import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiControlSchoolsDeleteComponent } from './ui-control-schools-delete.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [UiControlSchoolsDeleteComponent],
  exports: [UiControlSchoolsDeleteComponent],
  imports: [CommonModule, TranslocoModule],
})
export class UiControlSchoolsDeleteModule {}
