import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiHomeworksComponent} from "./ui-homeworks.component";
import {UiAppNoDataModule} from "../ui-app-no-data/ui-app-no-data.module";
import {RouterModule} from "@angular/router";
import {UiTooltipModule} from "../ui-tooltip/ui-tooltip.module";
import {PipesModule} from "../../app.pipes.module";
import { UiHomeworkModule } from "../ui-homework/ui-homework.module";



@NgModule({
  declarations: [UiHomeworksComponent],
  exports: [UiHomeworksComponent],
  imports: [
    CommonModule,
    UiAppNoDataModule,
    RouterModule,
    UiTooltipModule,
    PipesModule,
    UiHomeworkModule
  ]
})
export class UiHomeworksModule { }
