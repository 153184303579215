import { ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { PracticeService } from "../../_stores/practice/_service/practice.service";
import { VideoModel } from "../../_models/video.model";
import { ModalOpenAction } from "../../_stores/modal/_actions/modal.actions";
import { Store } from "@ngxs/store";
import { API } from "../../_stores/api";
import { HttpClient } from "@angular/common/http";


@Component({
  selector: 'ui-video',
  templateUrl: './ui-video.component.html',
  styleUrls: ['./ui-video.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiVideoComponent implements OnInit {
  @Input('id') set _id(id) {
    if (id) {
      this.interface._id = id;
      this.initVideo();
    }
  }
  @Input('homework') set _homework(homework) {
    if (homework) {
      this.interface.homework = homework;
    }
  }
  @Input('assignment') set _assignment(assignment) {
    if (assignment) {
      this.interface.assignment = assignment;
    }
  }

  interface: {
    _id: string
    homework: string,
    assignment: string,
    tracker: any,
    video: VideoModel
  } = {
    _id: null,
    homework: null,
    assignment: null,
    tracker: null,
    video: undefined
  }



  constructor(private store: Store,private cdr: ChangeDetectorRef,  private http: HttpClient, private practiceService: PracticeService) { }

  ngOnInit(): void {
  }

  initVideo(): void {
    this.practiceService.getVideoById(this.interface._id).subscribe((_video) => {
      this.interface.video = _video;
      this.cdr.detectChanges();
    });
  }

  actionExitHomework(): void {
    this.store.dispatch(new ModalOpenAction('homework_exit', false));
  }
  actionVideoIsPlay(event): void {
    if (event) {
      this.initTracker();
    }
  }
  actionVideoTime(event): void {
    let _duration = this.interface.video.duration*60;
    let _play_time = Math.floor(event);
    let _play_percent = Math.floor((_play_time / _duration) * 100);
    if(_play_percent > 80 && this.interface.tracker) {
      if(this.interface.homework) {
        let _body = {
          trackerId: this.interface.tracker._id,
          time: _play_time,
        };
        this.http.put<any>(API.url('trackers/prep-update'), _body).subscribe((_tracker) => {
          this.cdr.detectChanges();
        });
        this.store.dispatch( new ModalOpenAction('homework_done'));
      } else {
        let _body = {
          trackerId: this.interface.tracker._id,
          time: _play_time,
        };
        this.http.put<any>(API.url('trackers/practice-update'), _body).subscribe((_tracker) => {
          this.cdr.detectChanges();
        });
      }

      this.interface.tracker = null;
    }
  }

  initTracker(): void {
    if(this.interface.homework) {
      let _body = {
        assignment: this.interface.assignment,
        material: this.interface._id,
        typeMaterial: "Video"
      };
      this.http.post<any>(API.url('trackers/prep-begin'), _body).subscribe((_tracker) => {
        this.interface.tracker = _tracker;
        this.cdr.detectChanges();
      });
    } else {
      let _body = {
        material: this.interface._id,
        typeMaterial: "Video"
      };
      this.http.post<any>(API.url('trackers/practice-begin'), _body).subscribe((_tracker) => {
        this.interface.tracker = _tracker;
        this.cdr.detectChanges();
      });
    }
  }

}
