import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from "@angular/common";
import { UiWorksheetComponent } from "./ui-worksheet.component";
import { UiTooltipModule } from "../ui-tooltip/ui-tooltip.module";
import { UiEditorModule } from "../ui-editor/ui-editor.module";
import { UiAppNoDataModule } from "../ui-app-no-data/ui-app-no-data.module";



@NgModule({
  declarations: [UiWorksheetComponent],
  exports: [
    UiWorksheetComponent
  ],
  imports: [
    CommonModule,
    UiTooltipModule,
    UiEditorModule,
    UiAppNoDataModule
  ],
  providers: [DatePipe],
})
export class UiWorksheetModule { }
