import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PageSidebarModel } from '../../_models/page-sidebar.model';
import { UserState } from '../../_stores/user/_state/user.state';
import { Store } from '@ngxs/store';
import { ModalOpenAction, ModalOpenActionWithID } from '../../_stores/modal/_actions/modal.actions';
import { Router } from '@angular/router';
import { ClassesState } from '../../_stores/classes/_state/classes.state';
import { AssignmentsState } from '../../_stores/asignments/_state/assignments.state';
import { AppState } from '../../_stores/app/_state/app.state';
import { ToggleSidebar } from '../../_stores/app/_actions/app.actions';

@Component({
  selector: 'ui-page-sidebar',
  templateUrl: './ui-page-sidebar.component.html',
  styleUrls: ['./ui-page-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiPageSidebarComponent implements OnInit {
  interface: PageSidebarModel = {
    app: undefined,
    user: undefined,
    classes: [],
    assignments: [],
    homeworks: [],
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private router: Router) {}

  ngOnInit(): void {
    this.initApp();
    this.initUser();
    this.initClasses();
    this.initAssignments();
    this.initHomeworks();
  }

  initApp(): void {
    this.store.select(AppState.selectState).subscribe((_app) => {
      if (!_app) {
        return;
      }

      this.interface.app = _app;
      if (this.interface.app.sidebar_show) {
        document.querySelector('.ui-page-sidebar').classList.add('ui-page-sidebar-show');
      } else {
        document.querySelector('.ui-page-sidebar').classList.remove('ui-page-sidebar-show');
      }
      this.cdr.detectChanges();
    });
  }

  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.cdr.detectChanges();
    });
  }

  initClasses(): void {
    this.store.select(ClassesState.selectClassList).subscribe((_classes) => {
      if (_classes) {
        this.interface.classes = _classes.filter((_classes) => !_classes.options.isArchive);
        this.cdr.detectChanges();
      }
    });
  }

  initAssignments(): void {
    this.store.select(AssignmentsState.selectAssignmentList).subscribe((_assignments) => {
      if (_assignments) {
        this.interface.assignments = _assignments.filter((_assignments) => _assignments.options.isActive);
        this.cdr.detectChanges();
      }
    });
  }

  initHomeworks(): void {
    this.store.select(AssignmentsState.selectHomeworkList).subscribe({
      next: (res) => {
        this.interface.homeworks = res;
        this.cdr.detectChanges();
      },
    });
  }

  actionOpenAddClassModal(): void {
    this.store.dispatch(new ModalOpenAction('class-add'));
  }

  actionAddAssignment(): void {
    this.router.navigate(['/assignments/builder']).then();
  }

  actionToggleSidebar(): void {
    this.store.dispatch(new ToggleSidebar());
  }

  actionOpenEditUserModal(): void {
    this.store.dispatch(new ModalOpenActionWithID('profile_edit', this.interface.user._id, false));
  }
}
