import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { RouterModule } from '@angular/router';
import { UiPageEBookComponent } from './ui-page-e-book.component';
import { PipesModule } from "../../app.pipes.module";
import { UiTooltipModule } from "../ui-tooltip/ui-tooltip.module";
import {UiEditorModule} from "../ui-editor/ui-editor.module";
import {UiVideosModule} from "../ui-videos/ui-videos.module";




@NgModule({
  declarations: [UiPageEBookComponent],
  imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: "",
                component: UiPageEBookComponent
            }
        ]),
        PipesModule,
        UiTooltipModule,
        UiEditorModule,
        UiVideosModule
    ],
  providers: [DatePipe],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class UiPageEBookModule { }
