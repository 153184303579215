import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'ui-date-picker',
  templateUrl: './ui-date-picker.component.html',
  styleUrls: ['./ui-date-picker.component.scss'],
})
export class UiDatePickerComponent implements OnInit {
  @Input() placeholder = 'Select date';
  @Input() position = 'bottom';
  @Input() class = 'default';
  @Input() disabled = false;
  @Input() future = false;
  @Input('date') set _date(date) {
    if (date) {
      this.initDate(date);
    }
  }

  showDatepicker = false;
  datepickerValue!: string;
  month!: number;
  year!: number;

  displayMonth!: number;
  displayYear!: number;

  no_of_days = [] as number[];
  blankdays = [] as number[];
  language: string;
  @Output() selectedDate = new EventEmitter<Date>();

  constructor(private eRef: ElementRef, private transloco: TranslocoService) {}

  ngOnInit(): void {
    this.initDate();
    this.transloco.langChanges$.subscribe((t) => {
      this.language = t;
      this.getNoOfDays();
    });
  }

  initDate(date?) {
    if (date || !this.datepickerValue) {
      const today = date ? new Date(date) : new Date();
      this.month = today.getMonth();
      this.year = today.getFullYear();
      this.datepickerValue = today.toDateString();
    }
  }

  isToday(date: any) {
    const today = new Date();
    const d = new Date(this.year, this.month, date);
    return today.toDateString() === d.toDateString();
  }
  isFutureDate(date: any): boolean {
    if (this.future) {
      return false;
    }
    const selectedDate = new Date(this.year, this.month, date);
    return selectedDate > new Date();
  }

  isSelectDate(date: any): boolean {
    const selectedDate = new Date(this.year, this.month, date);
    return this.datepickerValue == selectedDate.toDateString();
  }

  getDateValue(date: any) {
    const selectedDate = new Date(this.year, this.month, date);
    // if (!this.future && selectedDate > new Date()) {
    //   return;
    // }
    this.selectedDate.emit(selectedDate);
    this.datepickerValue = selectedDate.toDateString();
    this.showDatepicker = false;
  }

  getNoOfDays() {
    const date = new Date(this.year, this.month + 1, 0);
    const daysInMonth = date.getDate();
    this.displayMonth = date.getMonth();
    this.displayYear = date.getFullYear();

    let dayOfWeek = new Date(this.year, this.month).getDay();
    dayOfWeek = this.checkOptionOfCalendar() ? dayOfWeek : dayOfWeek - 1 < 0 ? 6 : dayOfWeek - 1;
    const blankdaysArray = [];
    for (let i = 1; i <= dayOfWeek; i++) {
      blankdaysArray.push(i);
    }

    const daysArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }

    this.blankdays = blankdaysArray;
    this.no_of_days = daysArray;
  }

  trackByIdentity = (index: number, item: any) => item;

  checkOptionOfCalendar(): boolean {
    return this.language == 'en';
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showDatepicker = false;
    }
  }
}
