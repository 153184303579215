import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { EditControlRoles, LoadControlRoles } from '../../_stores/control/_actions/control.actions';
import { ModalCloseAction } from '../../_stores/modal/_actions/modal.actions';
import { ControlState } from '../../_stores/control/_state/control.state';
import { take } from 'rxjs';

@Component({
  selector: 'ui-control-role-edit',
  templateUrl: './ui-control-role-edit.component.html',
  styleUrls: ['./ui-control-role-edit.component.scss'],
})
export class UiControlRoleEditComponent implements OnInit {
  interface: any = {
    role: { name: '' },
    _cache: {
      error_fields: [],
      name: '',
    },
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {
    this.store.dispatch(new LoadControlRoles());
  }

  ngOnInit(): void {
    this.initRole();
  }

  actionEnterRoleName(event) {
    this.interface._cache.name = event.target.value;
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'name');
  }

  actionEditRole(): void {
    this.interface.loading = true;
    this.store
      .dispatch(
        new EditControlRoles({
          data: {
            _id: this.interface.role._id,
            name: this.interface._cache.name,
          },
        }),
      )
      .subscribe({
        next: () => {
          this.interface.loading = false;
          this.store.dispatch(new ModalCloseAction());
          this.cdr.detectChanges();
        },
        error: () => {
          this.interface.loading = false;
          this.cdr.detectChanges();
        },
      });
  }

  initRole() {
    this.store
      .select(ControlState.selectRoleByModalId)
      .pipe(take(1))
      .subscribe((_role) => {
        this.interface = { ...this.interface, role: _role };
        this.interface._cache = { ...this.interface._cache, name: _role.name };
        this.cdr.detectChanges();
      });
  }

  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }
}
