import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener, OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";

import { AuthLogin, AuthSuccess, DetectAuthToken } from "../../_stores/auth/_actions/auth.actions";
import { Store } from "@ngxs/store";
import { AuthState } from "../../_stores/auth/_state/auth.state";
import { environment } from "../../../environments/environment";

@Component({
  selector: 'ui-page-auth',
  templateUrl: './ui-page-auth.component.html',
  styleUrls: ['./ui-page-auth.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiPageAuthComponent implements OnInit, OnDestroy {
  @ViewChild('password') password_input: ElementRef;
  interface: {
    loading: boolean,
    error: boolean,
    submit: boolean,
    clever: boolean,
    user: {
      email: string,
      password: string
    },
    _cache: {
      show_password: boolean,
      error_fields: string[],
      clever_auth_url: string,
      clever_error: boolean;
    }
  } = {
    loading: false,
    error: false,
    submit: false,
    clever: false,
    user: {
      email: '',
      password: ''
    },
    _cache: {
      show_password: false,
      error_fields: [],
      clever_auth_url: environment.clever.auth_url+'&client_id='+environment.clever.client_id+'&redirect_uri='+environment.clever.redirect_url+'&state='+encodeURIComponent(JSON.stringify({ type: 'login' })),
      clever_error: false,
    }
  }

  constructor(private store: Store,private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.store.select(AuthState.selectAuth).subscribe((_auth) => {
      if (!_auth.status) {
        if (this.interface.submit) {
          this.interface.submit = false;
          if(_auth.error) {
            if (_auth.error.message.includes('found')) {
              this.interface._cache.error_fields.push('email');
            }
            if (_auth.error.message.includes('password')) {
              this.interface._cache.error_fields.push('password');
            }
          }
        }
        this.cdr.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {}

  actionEnterLogin(event): void {
    this.interface.user.email = event.target.value.toLowerCase();
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'email');
    this.cdr.detectChanges();
  }
  actionEnterPassword(event): void {
    this.interface.user.password = event.target.value.replace(/[^a-zA-Z0-9!@#$%^&*()]/gi, '',);
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'password');
    this.cdr.detectChanges();
  }
  actionToggleShowPassword(): void {
    this.interface._cache.show_password = !this.interface._cache.show_password;
    if(this.interface._cache.show_password) {
      this.password_input.nativeElement.setAttribute('type', 'text');
    } else {
      this.password_input.nativeElement.setAttribute('type', 'password');
    }

  }
  actionGrantLogin(): boolean {
    let _grant = true;
    if(this.interface.user.email.length < 6) {
      _grant = false;
    }
    if(this.interface.user.password.length < 5) {
      _grant = false;
    }
    return _grant;
  }
  actionLogin(): void {
    this.interface.submit = true;
    this.store.dispatch(new AuthLogin(this.interface.user.email, this.interface.user.password));
  }

  @HostListener("window:message", ["$event"])
  onMessage(event) {
    const type = event.data?.type;
    if (event.data?.type == "login") {
      if (event.data.error) {
        this.interface._cache.clever_error = true;
      } else {
        this.store.dispatch(new AuthSuccess(event.data.token));
      }
    }
  }
  actionToggleClever(): void {
    this.interface._cache.clever_error = false;
    this.interface.clever = !this.interface.clever;
  }

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key == 'Enter' && this.actionGrantLogin()) {
      this.actionLogin();
    }
  }

}
