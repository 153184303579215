<div class="ui-block ui-block-books">
  <div class="ui-block-header">
    <div class="ui-books-filters">
      <div class="ui-books-filter-input-group">
        <div class="ui-books-filter-input">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
          </svg>
          <input  name='search' type='text' placeholder="Search books" (keyup)="actionSearchBooks($event)">
        </div>
        <div class="ui-books-filter-text-xs" *ngIf="interface._cache.searched">Found <b>{{interface.books.length}}</b> books</div>
      </div>
      <div class="ui-books-filter-input-group md:ml-auto">
        <div class='ui-books-filter-select'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
          </svg>
          <input name='series' type='text' readonly [value]="interface._cache.choose_serie  || '' | Series" placeholder="Choose book series" (click)="actionToggleChooseSeries()">
          <div class="ui-books-filter-select-list" [class.ui-books-filter-select-list-open]="interface._cache.open_choose_series">
            <div class="ui-books-filter-select-list-items">
              <ng-container *ngFor="let serie of interface.series | sort:'asc':'order'">
                <div class="ui-books-filter-select-list-item" (click)="actionSearchBooksBySerie(serie)">
                  <p>{{serie | Series}}</p>
                </div>
              </ng-container>
            </div>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 ml-auto stroke-rose-500 invisible" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" [class.clear-select-show]="interface._cache.choose_serie" (click)="actionClearChooseSerie()">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" (click)="actionToggleChooseSeries()">
            <path *ngIf="!interface._cache.open_choose_series" stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
            <path *ngIf="interface._cache.open_choose_series" stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
          </svg>
        </div>
      </div>
      <div class="ui-books-filter-input-group">
        <div class='ui-books-filter-select'>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
          </svg>
          <input  name='grades' type='text' readonly [value]="interface._cache.choose_grade?.title" placeholder="Choose grade level" (click)="actionToggleChooseGrade()">
          <div class="ui-books-filter-select-list" [class.ui-books-filter-select-list-open]="interface._cache.open_choose_grade">
            <div class="ui-books-filter-select-list-items">
              <ng-container *ngFor="let grade of interface.grades | sort:'asc':'order'">
                <div class="ui-books-filter-select-list-item" (click)="actionSearchBooksByGrade(grade)">
                  <p>{{grade.title}}</p>
                </div>
              </ng-container>
            </div>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 ml-auto stroke-rose-500 invisible" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" [class.clear-select-show]="interface._cache.choose_grade" (click)="actionClearChooseGrade()">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" (click)="actionToggleChooseGrade()">
            <path *ngIf="!interface._cache.open_choose_grade" stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
            <path *ngIf="interface._cache.open_choose_grade" stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div class="ui-block-inner ui-block-inner-col">
    <ng-container *ngIf="interface.books.length == 0">
      <ui-no-data type="dino-find-students" text="No books found..."></ui-no-data>
    </ng-container>
    <div class="ui-books">
      <ng-container *ngFor="let book of interface.books | sort:'asc':'product.series'| sort:'asc':'order'; let i=index">
        <div class="ui-book" (click)="actionOpenBook(book._id)">
          <div class="group ui-book-thumbnail">
            <img alt="{{book.title}}" [lazyLoad]="book.thumbnail+'@386'" [defaultImage]="'https://www.gstatic.com/psa/static/1.gif'" [decode]="true" src=""/>
          </div>
          <div class="ui-book-title">{{book.title}}</div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="ui-block-footer"></div>
</div>
