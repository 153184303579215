import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { LoadControlRoles } from '../../_stores/control/_actions/control.actions';
import { ControlState } from '../../_stores/control/_state/control.state';
import { ModalOpenAction, ModalOpenActionWithID } from '../../_stores/modal/_actions/modal.actions';

@Component({
  selector: 'ui-control-roles',
  templateUrl: './ui-control-roles.component.html',
  styleUrls: ['./ui-control-roles.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiControlRolesComponent implements OnInit {
  interface: any = {
    data: [],
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {
    this.store.dispatch(new LoadControlRoles());
  }

  ngOnInit(): void {
    this.initData();
  }

  initData(): void {
    this.store.select(ControlState.selectRoles).subscribe({
      next: (res) => {
        if (!res) {
          return;
        }
        this.interface.data = res;
        this.cdr.detectChanges();
      },
      error: () => {
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
    });
  }

  actionOpenAddDataModal(): void {
    this.store.dispatch(new ModalOpenAction('control_role_add'));
    this.cdr.detectChanges();
  }

  actionOpenEditDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('control_role_edit', _id));
    this.cdr.detectChanges();
  }

  actionOpenDeleteDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('control_role_delete', _id));
    this.cdr.detectChanges();
  }
}
