import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'random'
})
export class RandomPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) {}

  public transform(list: Array<any>): Array<any> {
    const newList = [...list];
    newList.sort(() => Math.random() - 0.5);
    return newList;
  }
}
