<div class="ui-block ui-block-videos">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>{{interface.block.title || interface._cache.video?.title || '&nbsp;'}}</h2>
      <p>{{interface.block.subtitle || interface._cache.video?.description || '&nbsp;'}}</p>
    </div>
  </div>
  <div class="ui-block-inner ui-block-inner-col">
    <div class="ui-videos">
      <ng-container [ngTemplateOutlet]="VIDEO_PLAYER"></ng-container>
      <ng-container [ngTemplateOutlet]="VIDEO_PLAYLIST"></ng-container>
    </div>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>

<ng-template #VIDEO_PLAYER>
 <div class="ui-video-player-container">
   <div class="ui-video-player">
     <ui-videoplayer [src]="interface._cache.video?.options.url" [thumbnail]="interface._cache.video?.thumbnail"></ui-videoplayer>
   </div>
 </div>
</ng-template>
<ng-template #VIDEO_PLAYLIST>
  <div class="ui-video-playlist-container">
    <div class="ui-video-playlist" *ngIf="interface.block.tree">
      <ng-container *ngFor="let element of interface.videos | sort:'asc':'order'; let p=index">
        <ng-container *ngIf="element.children?.length == 0 && element.videos?.length == 0" [ngTemplateOutletContext]="{material:element}" [ngTemplateOutlet]="videoItem"></ng-container>
        <ng-container *ngIf="element.children?.length > 0 || element.videos?.length > 0" [ngTemplateOutletContext]="{category:element}" [ngTemplateOutlet]="videoCategory"></ng-container>
      </ng-container>
    </div>
    <div class="ui-video-playlist" *ngIf="!interface.block.tree">
      <ng-container *ngFor="let video of interface.videos | sort:'asc':'order'; let p=index">
        <ng-container  [ngTemplateOutletContext]="{material:video}" [ngTemplateOutlet]="videoItem"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>


<ng-template #videoCategory let-category='category'>
  <details class="ui-video-playlist-item">
    <summary>
      <div class="ui-video-playlist-item-info">
        <div class="ui-video-playlist-item-action">
          <button class="ui-btn ui-btn-icon">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 action-plus" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 action-minus" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4" />
            </svg>
          </button>
        </div>
        <div class="ui-video-playlist-item-name">
          <p>{{category.title}}</p>
        </div>
      </div>
    </summary>
    <div class="ui-video-playlist-item-node" [class.ui-video-playlist-item-node-videos]="category.videos?.length > 0">
      <ng-container *ngFor="let child of category.children | sort:'asc':'order'; let p=index">
        <ng-container *ngIf="child.children?.length == 0 && child.videos.length == 0" [ngTemplateOutletContext]="{material:child}" [ngTemplateOutlet]="videoItem"></ng-container>
        <ng-container *ngIf="child.children?.length > 0 || child.videos.length > 0" [ngTemplateOutletContext]="{category:child}" [ngTemplateOutlet]="videoCategory"></ng-container>
      </ng-container>
      <ng-container *ngFor="let video of category.videos | sort:'asc':'order'; let p=index">
        <ng-container  [ngTemplateOutletContext]="{material:video}" [ngTemplateOutlet]="videoItem"></ng-container>
      </ng-container>
    </div>
  </details>
</ng-template>

<ng-template #videoItem let-item='material'>
  <div class="ui-video-playlist-item" (click)="actionOpenVideo(item)">
    <div class="ui-video-playlist-item-info">
      <div *ngIf="interface.is_selected" class="ui-form-checkbox"><input type="checkbox" [checked]="isChecked(item)" (click)="actionAddToAssign(item)"></div>
      <div class="ui-video-playlist-item-icon">
        <img  src="./assets/images/materials/video.svg" alt="{{item.title}}" loading="lazy">
      </div>
      <div class="ui-video-playlist-item-name">
        <span>{{item.title}}</span>
      </div>
      <div class="ui-video-playlist-item-action">
        <button class="ui-btn" *ngIf="item.materials == 'question'">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
               stroke="currentColor" stroke-width="1.5">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9"/>
          </svg>
          <span>Start</span>
        </button>
        <button class="ui-btn" *ngIf="item.materials == 'video'" >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
               stroke="currentColor" stroke-width="1.5">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"/>
            <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
          </svg>
          <span>Watch</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
