import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UiBooksModel} from "../../_models/ui-books.model";
import {Store} from "@ngxs/store";
import {Router} from "@angular/router";
import {BooksState} from "../../_stores/books/_state/books.state";

@Component({
  selector: 'ui-books',
  templateUrl: './ui-books.component.html',
  styleUrls: ['./ui-books.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiBooksComponent implements OnInit {
  interface: UiBooksModel = {
    books: []
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private router: Router) { }

  ngOnInit(): void {
    this.initBooks();
  }

  initBooks(): void {
    this.store.select(BooksState.selectBooksList).subscribe((_books) => {
      if(this.interface.books.length == 0) {
        this.interface.books = _books;
        this.cdr.detectChanges();
      }
    })
  }

  actionOpenBook(_id: string) {
    this.router.navigateByUrl('/books/e-book/'+_id).then();
  }

}
