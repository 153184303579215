import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiPageAssignmentBuilderComponent } from './ui-page-assignment-builder.component';
import { RouterModule } from '@angular/router';
import { UiTooltipModule } from '../ui-tooltip/ui-tooltip.module';
import { PipesModule } from '../../app.pipes.module';
import { TranslocoModule } from '@ngneat/transloco';
import { UiDatePickerModule } from '../ui-date-picker/ui-date-picker.module';
import { UiGradesModule } from '../ui-grades/ui-grades.module';
import { UiCategoriesTreeModule } from '../ui-categories-tree/ui-categories-tree.module';
import { UiAppNoDataModule } from "../ui-app-no-data/ui-app-no-data.module";

@NgModule({
  declarations: [UiPageAssignmentBuilderComponent],
  exports: [UiPageAssignmentBuilderComponent],
  imports: [
    CommonModule,
    PipesModule,
    RouterModule.forChild([
      {
        path: "",
        component: UiPageAssignmentBuilderComponent
      }
    ]),
    UiTooltipModule,
    TranslocoModule,
    UiDatePickerModule,
    UiGradesModule,
    PipesModule,
    UiCategoriesTreeModule,
    UiAppNoDataModule
  ]
})
export class UiPageAssignmentBuilderModule {}
