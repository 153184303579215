import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiDatePickerComponent } from './ui-date-picker.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [UiDatePickerComponent],
  exports: [UiDatePickerComponent],
  imports: [CommonModule, TranslocoModule],
})
export class UiDatePickerModule {}
