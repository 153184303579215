import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiQuizComponent } from "./ui-quiz.component";
import { UiTooltipModule } from "../ui-tooltip/ui-tooltip.module";
import { KatexModule } from "ng-katex";
import { PipesModule } from "../../app.pipes.module";
import { RouterModule } from "@angular/router";



@NgModule({
  declarations: [UiQuizComponent],
  exports: [UiQuizComponent],
  imports: [
    CommonModule,
    UiTooltipModule,
    KatexModule,
    PipesModule,
    RouterModule
  ]
})
export class UiQuizModule { }
