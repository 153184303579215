<div class="ui-block ui-block-white">
    <div class="ui-block-inner ui-block-inner-col my-6">
      <div class="ui-builder">
        <div class="ui-builder-preview">
          <div class="ui-assignment-preview">
            <div class="ui-assignment-preview-header">
              <div class="ui-class-assignment-header-title">
                <h4>{{interface.assignment.title || 'Assignment name'}}</h4>
                <div class="ui-class-assignment-header-title-action">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                  </svg>
                </div>
              </div>
              <div class="ui-assignment-preview-class">{{interface.assignment.class?.title || 'Assignment class'}}</div>
              <div class="ui-assignment-preview-date">You edited on {{ interface._cache.now | date}}</div>
            </div>
            <div class="ui-assignment-preview-body">
              <div class="ui-assignment-preview-info mb-4">
                <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <label>
                  <ng-container>{{interface.assignment?.dates.dueDate === null ? 'without a deadline' : interface.assignment?.dates.dueDate | agoDate:true}}</ng-container>
                  <ng-container *ngIf="(interface.assignment?.dates.dueDate | agoDate) < 0"> days past due</ng-container>
                  <ng-container *ngIf="interface.assignment?.dates.dueDate !== null && (interface.assignment?.dates.dueDate | agoDate) >= 0"> days left</ng-container>
                </label>
              </div>
              <div class="ui-assignment-preview-info">
                <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
                <p>Due:</p>
                <span>{{interface.assignment?.dates.dueDate | date}}</span>
              </div>
              <div class="ui-assignment-preview-info">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.795 21.0001H5C4.46957 21.0001 3.96086 20.7893 3.58579 20.4143C3.21071 20.0392 3 19.5305 3 19.0001V7.00006C3 6.46963 3.21071 5.96092 3.58579 5.58585C3.96086 5.21077 4.46957 5.00006 5 5.00006H17C17.5304 5.00006 18.0391 5.21077 18.4142 5.58585C18.7893 5.96092 19 6.46963 19 7.00006V11.0001" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M18 22.0001C20.2091 22.0001 22 20.2092 22 18.0001C22 15.7909 20.2091 14.0001 18 14.0001C15.7909 14.0001 14 15.7909 14 18.0001C14 20.2092 15.7909 22.0001 18 22.0001Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15 3.00006V7.00006" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M7 3.00006V7.00006" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M3 11.0001H19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M18 16.496V18L19 19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p>Scheduled:</p>
                <span>{{interface.assignment?.dates.scheduleDate | date}}</span>
              </div>
              <div class="ui-assignment-preview-info">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                </svg>
                <p>Number of materials:</p>
                <span><strong>{{interface.assignment.materials.length}}</strong></span>
              </div>
              <div class="ui-assignment-preview-info">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
                </svg>
                <p>Total progress:</p>
                <span>N/A</span>
              </div>
            </div>
            <div class="ui-assignment-preview-footer">
              <div class="ui-assignment-preview-cup">
                <img src="assets/images/shapes/trophy.png" loading="lazy" alt="">
                <strong></strong>
                <span>Done</span>
              </div>
              <div class="ui-assignment-preview-students">
                <ng-container *ngFor="let student of interface.assignment.students | slice: 0:3; let s=index">
                  <div class="ui-assignment-preview-student">
                    <img src="{{student.avatar}}" alt="" tooltip/>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="ui-builder-materials-selected">
            <div class="ui-builder-part-title">
              <div class="ui-builder-part-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                </svg>
              </div>
              <div class="ui-builder-part-name">
                <p>Assignment materials</p>
                <span>Selected materials to assignment</span>
              </div>
            </div>
            <ui-no-data *ngIf="interface.assignment.materials.length == 0" text="No materials from assignment"></ui-no-data>
            <div class="ui-materials-selected">
              <ng-container *ngFor="let material of interface.assignment.materials | sort:'asc':'order'; let c=index">
                <div class="ui-materials-selected-item">
                  <div class="ui-materials-selected-item-info">
                    <div class="ui-materials-selected-item-icon">
                      <img *ngIf="material.materials == 'question'" src="./assets/images/materials/question.svg" alt="{{material.title}}" loading="lazy">
                      <img *ngIf="material.materials == 'video' || !material.materials " src="./assets/images/materials/video.svg" alt="{{material.title}}" loading="lazy">
                      <img *ngIf="material.materials == 'worksheet'" src="./assets/images/materials/worksheet.svg" alt="{{material.title}}" loading="lazy">
                      <img *ngIf="material.materials == 'book'" src="./assets/images/materials/i_book.svg" alt="{{material.title}}" loading="lazy">
                    </div>
                    <div class="ui-materials-selected-item-name">
                      <p>{{material.title}}</p>
                      <span *ngIf="material.materials == 'question' || material.materials == 'video'" >&nbsp;</span>
                      <span *ngIf="material.materials == 'worksheet'" (click)="actionTogglePreviewWorksheet(material)">Preview</span>
                      <span *ngIf="material.materials == 'book'">number of page: <strong>{{material?.pages?.length}}</strong></span>
                    </div>
                    <div class="ui-materials-selected-item-action">
                      <button class="ui-btn ui-btn-icon ui-btn-assign" (click)="actionUnAssignMaterial(material)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="ui-builder-context">
          <div class="ui-builder-part">
            <div class="ui-builder-part-title">
              <div class="ui-builder-part-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                </svg>
              </div>
              <div class="ui-builder-part-name">
                <p>Base information</p>
                <span>Fill base assignment information</span>
              </div>
            </div>
            <div class="ui-builder-part-data">
              <div class="ui-builder-info ui-form">
                <div>
                  <div class="ui-form-input-group">
                    <div class="ui-form-text ui-form-text-xs">Assignment name</div>
                    <div class="ui-form-input">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                      </svg>
                      <input name="name" type="text" placeholder="Enter assignment name" [value]="interface.assignment.title" (keyup)="actionAssignmentName($event)">
                    </div>
                  </div>
                  <div class="ui-form-input-group" *ngIf="interface.user.role._id == '62ebc2da8e33b7bc52c853c4'">
                    <div class="ui-form-text ui-form-text-xs">Class to the assignment</div>
                    <div class="ui-form-select">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                        <path d="M12 14l9-5-9-5-9 5 9 5z" />
                        <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                      </svg>
                      <input  name="class" type="text" readonly [value]="interface._cache.class_selected?.title" placeholder="Select class" (click)="actionToggleSelectClass()">
                      <div class="ui-form-select-list" [class.ui-form-select-list-open]="interface._cache.open_select_class">
                        <div class="ui-form-select-list-items ui-select-school-list">
                          <ng-container *ngIf="interface.classes.length == 0">
                            <div class="ui-form-select-list-item">
                              <p>You have no classes</p>
                              <span>Please add a class</span>
                            </div>
                          </ng-container>
                          <ng-container *ngFor="let _class of interface.classes">
                            <div class="ui-form-select-list-item" (click)="actionSelectClass(_class)">
                              <p>{{_class.title}}</p>
                              <span>Students: <strong>{{_class.students ? _class.students.length : 0}}</strong></span>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 pointer-events-none" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" >
                        <path *ngIf="!interface._cache.open_select_class" stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                        <path *ngIf="interface._cache.open_select_class" stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
                      </svg>
                    </div>
                  </div>
                  <div class="ui-form-input-group" *ngIf="interface.user.role._id == '62ebbf45f009df0b43698376'">
                    <div class="ui-form-text ui-form-text-xs">Students</div>
                    <div class="ui-form-select">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                        <path d="M12 14l9-5-9-5-9 5 9 5z" />
                        <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                      </svg>
                      <input  name="class" type="text" readonly [value]="interface._cache.student_selected?.displayName" placeholder="Select student" (click)="actionToggleSelectStudents()">
                      <div class="ui-form-select-list" [class.ui-form-select-list-open]="interface._cache.open_select_students">
                        <div class="ui-form-select-list-items">
                          <ng-container *ngIf="interface.user.children.length == 0">
                            <div class="ui-form-select-list-item">
                              <p>You have no student</p>
                              <span>Please add a student</span>
                            </div>
                          </ng-container>
                          <ng-container *ngFor="let _child of interface.user.children">
                            <div class="ui-form-select-list-item" (click)="actionSelectStudent(_child)">
                              <div class="ui-select-item-user">
                                <div class="ui-select-item-user-avatar">
                                  <img src="{{_child.avatar}}" alt="" loading="lazy">
                                </div>
                                <div class="ui-select-item-user-info">
                                  <p>{{_child.displayName}}</p>
                                </div>
                              </div>

                            </div>
                          </ng-container>
                        </div>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 pointer-events-none" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" >
                        <path *ngIf="!interface._cache.open_select_students" stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                        <path *ngIf="interface._cache.open_select_students" stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="ui-form-input-group">
                    <div class="ui-form-text ui-form-text-xs">Instructions (optional)</div>
                    <div class="ui-form-textarea">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                      </svg>
                      <textarea rows="2" placeholder="Write instructions to assignment" (keyup)="actionEnterInstructions($event)"></textarea>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="ui-form-input-group">
                      <div class="ui-form-text ui-form-text-xs">Due</div>
                      <ui-date-picker [future]="true" (selectedDate)="actionSelectDueDate($event)"></ui-date-picker>
                    </div>
                  <div class="ui-form-input-group">
                      <div class="ui-form-text ui-form-text-xs">Scheduled</div>
                      <ui-date-picker [future]="true" (selectedDate)="actionSelectScheduledDate($event)"></ui-date-picker>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ui-builder-part">
            <div class="ui-builder-part-title">
              <div class="ui-builder-part-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                </svg>
              </div>
              <div class="ui-builder-part-name">
                <p>Materials</p>
                <span>Practical materials for the assignment being created</span>
              </div>
            </div>
            <div class="ui-builder-part-data">
              <div class="ui-builder-materials">
                <div class="ui-builder-materials-data">
                  <div class="ui-builder-materials-action">
                    <div class="ui-form-select">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                        <path d="M12 14l9-5-9-5-9 5 9 5z" />
                        <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                      </svg>
                      <input name="grades" type="text" readonly [value]="interface._cache.grade_selected?.title" placeholder="Select grade" (click)="actionToggleSelectGrade()">
                      <div class="ui-form-select-list" [class.ui-form-select-list-open]="interface._cache.open_select_grade">
                        <div class="ui-form-select-list-items ui-select-school-list">
                          <ng-container *ngFor="let grade of interface.grades | sort:'asc':'order'">
                            <div class="ui-form-select-list-item" (click)="actionSelectGrade(grade)">
                              <p>{{grade.title}}</p>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 pointer-events-none" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" >
                        <path *ngIf="!interface._cache.open_select_grade" stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                        <path *ngIf="interface._cache.open_select_grade" stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
                      </svg>
                    </div>
                  </div>
                  <div class="ui-builder-materials-list">
                    <ng-container *ngIf="!interface._cache.grade_selected">
                      <ui-no-data text="No material grade selected"></ui-no-data>
                    </ng-container>
                    <ng-container *ngIf="interface._cache.grade_selected">
                      <ui-no-data *ngIf="interface.update" text="Loading materials..."></ui-no-data>
                    </ng-container>
                    <div class="ui-builder-material-categories">
                      <ng-container *ngFor="let category of interface._cache.categories | sort:'asc':'title'">
                        <div class="ui-builder-material-category ui-builder-material-category-{{category.slug}}" [class.ui-builder-material-category-selected]="interface._cache.category_selected?._id == category._id" (click)="actionSelectMaterialCategory(category)">
                          <div class="ui-builder-material-category-info">
                            <h3>{{category.title}}</h3>
                            <ng-container [ngTemplateOutletContext]="{category:category}" [ngTemplateOutlet]="CATEGORY_IMAGE"></ng-container>
                          </div>
                        </div>

                      </ng-container>
                    </div>
                    <div class="ui-builder-material-category-inner" *ngIf="interface._cache.category_selected">
                      <div class="ui-tree-tabs">
                        <ng-container *ngFor="let tab of interface._cache.category_selected_tabs | sort:'asc':'order'; let c=index">
                          <div class="ui-tree-tab" [class.ui-tree-tab-active]="tab._id == interface._cache.category_selected_active_tab?._id" (click)="actionChangeActiveTab(c)">
                            <div class="ui-tree-tab-icon">
                              <img *ngIf="tab.slug == 'math'" src="./assets/images/shapes/open_book.png" alt="{{tab.title}}" loading="lazy">
                              <img *ngIf="tab.slug == 'ela'" src="./assets/images/shapes/pencil_ruler.png" alt="{{tab.title}}" loading="lazy">
                            </div>
                            <div class="ui-tree-tab-name">{{tab.title}}</div>
                          </div>
                        </ng-container>
                      </div>
                      <div class="ui-builder-material-category-tree">
                        <ng-container *ngFor="let practice of interface._cache.category_selected_active_tab?.children | sort:'asc':'order'; let p=index">
                          <ng-container *ngIf="practice.children.length == 0" [ngTemplateOutletContext]="{material:practice}" [ngTemplateOutlet]="practiceItem"></ng-container>
                          <ng-container *ngIf="practice.children.length > 0" [ngTemplateOutletContext]="{category:practice}" [ngTemplateOutlet]="practiceCategory"></ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="ui-builder-materials-action" *ngIf="interface._cache.category_selected">
                    <button  class="ui-btn mx-auto" (click)="actionCloseMaterialTree()">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                      <span>Close tree</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ui-builder-part">
            <div class="ui-builder-part-data">
              <button  class="ui-btn ui-btn-lg mx-auto" (click)="actionCreateAssignment()" [disabled]="!actionCheckCrateAssignment()">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                </svg>
                <span>Create assignment</span>
              </button>
              <div class="ui-builder-warning" *ngIf="interface.assignment.students.length == 0">
                <div class="ui-builder-warning-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                  </svg>
                </div>
                <div class="ui-builder-warning-text">
                  <ng-container *ngIf="interface.user.role._id == '62ebc2da8e33b7bc52c853c4'">Please add at least one student to your class in order to create an assignment</ng-container>
                  <ng-container *ngIf="interface.user.role._id == '62ebbf45f009df0b43698376'">Please select at least one student to create an assignment</ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ui-builder-preview-worksheet" *ngIf="interface._cache.open_preview_worksheet">
          <button class="ui-btn" (click)="actionTogglePreviewWorksheet()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
            <span>Close preview</span>
          </button>
          <img src="{{interface._cache.preview_worksheet?.thumbnail}}" loading="lazy" decoding="async" alt="">
        </div>
      </div>
    </div>
</div>

<ng-template #CATEGORY_IMAGE let-category='category'>
  <ng-container [ngSwitch]="category.slug">
    <img *ngSwitchCase="'video_lectures'" src="./assets/images/objects/backpack.gif" alt="{{category.title}}" loading="lazy"/>
    <img *ngSwitchCase="'practice_questions'" src="./assets/images/objects/classboard.gif" alt="{{category.title}}" loading="lazy"/>
    <img *ngSwitchCase="'worksheets'" src="./assets/images/objects/book.gif" alt="{{category.title}}" loading="lazy"/>
    <img *ngSwitchCase="'practice_drills'" src="./assets/images/objects/cup.gif" alt="{{category.title}}" loading="lazy"/>
    <img *ngSwitchCase="'official_test_explanations'" src="./assets/images/objects/cup.gif" alt="{{category.title}}" loading="lazy"/>
  </ng-container>
</ng-template>

<ng-template #practiceCategory let-category='category'>
  <details class="ui-practice-tree-item">
    <summary>
      <div class="ui-practice-tree-item-info">
        <div class="ui-practice-tree-item-icon">
          <img src="./assets/images/materials/category.svg" alt="{{category.title}}" loading="lazy">
        </div>
        <div class="ui-practice-tree-item-name">
          <p>{{category.title}}</p>
          <span>in {{interface._cache.category_selected_active_tab.title}}</span>
        </div>
        <div class="ui-practice-tree-item-action">
          <button class="ui-btn ui-btn-icon">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 action-plus" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 action-minus" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4" />
            </svg>
          </button>
        </div>
      </div>
    </summary>
    <div class="ui-practice-tree-item-node">
      <ng-container *ngFor="let practice of category.children | sort:'asc':'order'; let p=index">
        <ng-container *ngIf="practice.children?.length == 0 || practice.children == undefined" [ngTemplateOutletContext]="{material:practice}" [ngTemplateOutlet]="practiceItem"></ng-container>
        <ng-container *ngIf="practice.children?.length > 0" [ngTemplateOutletContext]="{category:practice}" [ngTemplateOutlet]="practiceCategory"></ng-container>
      </ng-container>
    </div>
  </details>
</ng-template>

<ng-template #practiceItem let-item='material'>
  <div class="ui-practice-tree-item">
    <div class="ui-practice-tree-item-info">
      <div class="ui-practice-tree-item-icon">
        <img *ngIf="item.materials == 'question'" src="./assets/images/materials/question.svg" alt="{{item.title}}" loading="lazy">
        <img *ngIf="item.materials == 'video'" src="./assets/images/materials/video.svg" alt="{{item.title}}" loading="lazy">
        <img *ngIf="item.materials == 'worksheet'" src="./assets/images/materials/worksheet.svg" alt="{{item.title}}" loading="lazy">
        <img *ngIf="item.materials == 'book'" src="./assets/images/materials/i_book.svg" alt="{{item.title}}" loading="lazy">
      </div>
      <div class="ui-practice-tree-item-name">
        <p>{{item.title}}</p>
        <span>in {{interface._cache.category_selected_active_tab.title}}
          <span *ngIf="item.materials == 'worksheet'" class="ui-badge" (click)="actionTogglePreviewWorksheet(item)">Preview</span>
        </span>
      </div>
      <div class="ui-practice-tree-item-action">
        <button *ngIf="actionCheckAssignMaterial(item)" class="ui-btn ui-btn-assign" (click)="actionUnAssignMaterial(item)">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
          </svg>
          <span>Assigned</span>
        </button>
        <button *ngIf="!actionCheckAssignMaterial(item)" class="ui-btn ui-btn-assign" (click)="actionAssignMaterial(item)">
          <span>Assign</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
