<div class="ui-block ui-block-practice-tree">
    <div class="ui-block-header">
        <div class="ui-block-header-title">
          <div class="ui-tree-tabs">
            <ng-container *ngFor="let tab of interface.tree.tabs | sort:'asc':'order'; let c=index">
              <div class="ui-tree-tab" [class.ui-tree-tab-active]="tab._id == interface._cache.active._id" (click)="actionChangeActiveTab(c)">
                <div class="ui-tree-tab-icon">
                  <img *ngIf="tab.slug == 'math'" src="./assets/images/shapes/open_book.png" alt="{{tab.title}}" loading="lazy">
                  <img *ngIf="tab.slug == 'ela'" src="./assets/images/shapes/pencil_ruler.png" alt="{{tab.title}}" loading="lazy">
                </div>
                <div class="ui-tree-tab-name">{{tab.title}}</div>
              </div>
            </ng-container>
          </div>
        </div>
    </div>
    <div class="ui-block-inner">
      <div class="ui-practice-tree">
        <ng-container *ngFor="let practice of interface.tree.practices | sort:'asc':'order'; let p=index">
          <ng-container *ngIf="practice.children.length == 0" [ngTemplateOutletContext]="{material:practice}" [ngTemplateOutlet]="practiceItem"></ng-container>
          <ng-container *ngIf="practice.children.length > 0" [ngTemplateOutletContext]="{category:practice}" [ngTemplateOutlet]="practiceCategory"></ng-container>
        </ng-container>
      </div>
    </div>
    <div class="ui-block-footer">
        <div class="ui-block-footer-action"></div>
    </div>
</div>

<ng-template #practiceCategory let-category='category'>
  <details class="ui-practice-tree-item">
    <summary>
      <div class="ui-practice-tree-item-info">
        <div class="ui-practice-tree-item-icon">
          <img src="./assets/images/materials/category.svg" alt="{{category.title}}" loading="lazy">
        </div>
        <div class="ui-practice-tree-item-name">
          <p>{{category.title}}</p>
          <span>in {{interface._cache.active.title}}</span>
        </div>
        <div class="ui-practice-tree-item-action">
          <button class="ui-btn ui-btn-icon">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 action-plus" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 action-minus" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4" />
            </svg>
          </button>
        </div>
      </div>
    </summary>
    <div class="ui-practice-tree-item-node">
      <ng-container *ngFor="let practice of category.children | sort:'asc':'order'; let p=index">
        <ng-container *ngIf="practice.children?.length == 0 || practice.children == undefined" [ngTemplateOutletContext]="{material:practice}" [ngTemplateOutlet]="practiceItem"></ng-container>
        <ng-container *ngIf="practice.children?.length > 0" [ngTemplateOutletContext]="{category:practice}" [ngTemplateOutlet]="practiceCategory"></ng-container>
      </ng-container>
    </div>
  </details>
</ng-template>

<ng-template #practiceItem let-item='material'>
  <div class="ui-practice-tree-item" [routerLink]="item._id">
      <div class="ui-practice-tree-item-info">
        <div class="ui-practice-tree-item-icon">
          <img *ngIf="item.materials == 'question'" src="./assets/images/materials/question.svg" alt="{{item.title}}" loading="lazy">
          <img *ngIf="item.materials == 'video'" src="./assets/images/materials/video.svg" alt="{{item.title}}" loading="lazy">
          <img *ngIf="item.materials == 'worksheet'" src="./assets/images/materials/worksheet.svg" alt="{{item.title}}" loading="lazy">
        </div>
        <div class="ui-practice-tree-item-name">
          <p>{{item.title}}</p>
          <span>in {{interface._cache.active.title}}</span>
        </div>
        <div class="ui-practice-tree-item-action">
          <button class="ui-btn" *ngIf="item.materials == 'question'">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9"/>
            </svg>
            <span>Start</span>
          </button>
          <button class="ui-btn" *ngIf="item.materials == 'video'" >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"/>
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
            </svg>
            <span>Watch</span>
          </button>
          <button *ngIf="item.materials == 'worksheet'" class="ui-btn" >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
            </svg>
            <span>View</span>
          </button>
        </div>
      </div>
  </div>
</ng-template>
