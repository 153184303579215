import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiPageAuthComponent } from "./ui-page-auth.component";
import { RouterModule } from "@angular/router";
import { AppTranslocoModule } from "../../app.transloco.module";
import { UiTooltipModule } from "../ui-tooltip/ui-tooltip.module";
import { PipesModule } from "../../app.pipes.module";



@NgModule({
  declarations: [UiPageAuthComponent],
  exports: [UiPageAuthComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: UiPageAuthComponent
      }
    ]),
    AppTranslocoModule,
    UiTooltipModule,
    PipesModule
  ]
})
export class UiPageAuthModule { }
