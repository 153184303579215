import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../../app.pipes.module';
import { UiGradesModule } from '../ui-grades/ui-grades.module';
import { UiPageAssignmentsComponent } from './ui-page-assignments.component';
import { UiTooltipModule } from '../ui-tooltip/ui-tooltip.module';
import { UiAppNoDataModule } from '../ui-app-no-data/ui-app-no-data.module';

import { UiAssignmentModule } from "../ui-assignment/ui-assignment.module";

@NgModule({
  declarations: [UiPageAssignmentsComponent],
  exports: [UiPageAssignmentsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: UiPageAssignmentsComponent,
        children: [
          {
            path: "builder",
            loadChildren: () =>
              import("../ui-page-assignment-builder/ui-page-assignment-builder.module").then(
                (mod) => mod.UiPageAssignmentBuilderModule
              )
          },
          {
            path: ":assignment_id",
            loadChildren: () =>
              import("../ui-page-assignment/ui-page-assignment.module").then((mod) => mod.UiPageAssignmentModule),
/*            resolve: {
              assignmentData: UiPageAssignmentResolver
            }*/
          }
        ]
      }
    ]),
    PipesModule,
    UiGradesModule,
    UiTooltipModule,
    UiAppNoDataModule,
    UiAssignmentModule
  ]
})
export class UiPageAssignmentsModule {}
